import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import {
  ACTIVE_LMS_PRODUCT,
  ACTIVE_WORKFLOW_NAME,
  LMS_PARTNER_CODE,
} from "../../constants";
import { initiateWorkFlow } from "../../services/workflow-service";
import { saveCookie, getCookie, deleteCookie } from "../../utils/cookie-helper";
import { getworkFlowInteractionOutcome } from "../../services/workflow-service";

import { toast } from "react-toastify";

import Header from "../../components/header";
import oops from "../../assets/images/oops.svg";
import notes from "../../assets/images/notes.svg";
import "./loan-rejected.css";
import { DecryptData, EncryptData } from "../../utils/application-helper";

const LoanRejected = () => {
  const navigate = useNavigate();

  const mobileNumber = DecryptData(getCookie("phone"));

  useEffect(() => {
    proceedInitiateWorkFlow();
  }, []);

  const proceedInteractionOutcome = async (responseInteraction) => {
    let bodyValues = {};
    let UUID = responseInteraction.actions[0].uuid;
    let urlParameters = {
      uuid: UUID,
    };
    bodyValues["Close_api"] = "Close API";
    const { status, error } = await getworkFlowInteractionOutcome(
      urlParameters,
      bodyValues
    );
    if (error) {
      toast.error(error);
    } else if (status == 200) {
      deleteCookie("workflow_id");
      deleteCookie("phone");
      deleteCookie("lms_token");
    }
  };

  const proceedInitiateWorkFlow = () => {
    const initiateWorkFlowParams = {
      msisdn: mobileNumber,
      partner_code: LMS_PARTNER_CODE,
      product_type: ACTIVE_LMS_PRODUCT,
      workflow_name: ACTIVE_WORKFLOW_NAME,
    };

    initiateWorkFlow(initiateWorkFlowParams).then((response) => {
      if (response.status == 200) {
        saveCookie("workflow_id", EncryptData(response.data.data.uuid));
        proceedInteractionOutcome(response.data.data);
      } else {
        toast.error(response.message);
      }
    });
  };

  const goBackHandler = () => {
    navigate("/");
  };
  return (
    <div>
      <Header />
      <div className="main-container">
        <div className="content-container">
          <div className="loan-rejected-wrapper">
            <img src={oops} alt="oops" />
            <h2>Oooops! you don’t have any pre approved loans right now</h2>
          </div>
          <div className="content-notes rejected_notes">
            <img src={notes} alt="notes" />
            <label>
              Contact us at <span> mail@yabx.com </span>to get more info
            </label>
          </div>
        </div>
        {/* buuton */}
        <div className="button-section justify-content-center">
          <div>
            <button
              type="button"
              onClick={goBackHandler}
              className="btn btn-primary"
            >
              Go back to home page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanRejected;
