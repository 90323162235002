import React, { useState, useEffect } from "react";

import imageCompression from "browser-image-compression";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { getCookie } from "../../utils/cookie-helper";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Loader from "../../components/loader";
import Error from "../../components/error";

import "./documents.css";
import {
  uploadKyc,
  getCustomerKyc,
  submitKyc,
  getKycDefinition,
} from "../../services/kyc-service";
import {
  getworkFlowInteractionOutcome,
  profileUpdate,
} from "../../services/workflow-service";
import { ACTIVE_KYC_ID } from "../../constants";
import {
  getNextActionPage,
  fetchAllWorkFlowActions,
  DecryptData,
} from "../../utils/application-helper";

import DocumentRadio from "../../components/documentRadio";
import DocumentFile from "../../components/documentFile";
import CheckMark from "../../components/checkMark";

const Document = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [infoDefinition, setInfoDefinition] = useState([]);
  const [kycDefinition, setKycDefinition] = useState([]);
  const [versionData, setVersionData] = useState(0);
  const [workFlowActionId, setWorkFlowActionId] = useState("");
  const [updatedRemarksData, setUpdatedRemarksData] = useState([]);
  const [previousValues, setPreviousValues] = useState({});
  const [isReadMore, setIsReadMore] = useState({});
  const [convertedTime, setConvertedTime] = useState();
  const [initial, setInitial] = useState();
  const [packageTitle, setPackageTitle] = useState("");
  const [errMsg, setErrMsg] = useState();
  const [submitDefinitions, setSubmitDefinitions] = useState([]);
  const [showError, setShowError] = useState();
  const [formValues, setFormValues] = useState([]);
  const [tempShow, setTempShow] = useState();
  const [tempShowView, setTempShowView] = useState();
  const [tempExt, setTempExt] = useState();
  const [kycExt, setKycExt] = useState([]);
  const [extStr, setExtStr] = useState([]);

  const mobileNumber = DecryptData(getCookie("phone"));

  useEffect(() => {
    fetchKycDefinition();
    fetchAllWorkKyc();
  }, []);

  useEffect(() => {
    var dt = new Date();
    var secs = dt.getSeconds() + 60 * (dt.getMinutes() + 60 * dt.getHours());
    setInitial(secs);
  }, []);

  const findFinalTime = () => {
    var ft = new Date();
    var finalSecs =
      ft.getSeconds() + 60 * (ft.getMinutes() + 60 * ft.getHours());
    let timeDifference = finalSecs - initial;
    secondsToHms(timeDifference);
  };

  const fetchAllWorkKyc = () => {
    fetchAllWorkFlowActions("get_documents").then((response) => {
      if (response.status === true) {
        setWorkFlowActionId(response.value);
      }
    });
  };

  const parseData = (
    responseData,
    tempShow,
    tempShowView,
    tempExt,
    formValues
  ) => {
    let newData = [];
    let remarksData = {};
    let isReadMore = {};

    const packageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "61e569cf3ff56b6727ac06aa"
    );

    packageId.children.map((element) => {
      const { fieldName, remarks, responseValue, displayType } = element;
      if (fieldName !== "time_spent_on_documents") {
        if (displayType === "CHECKMARK") {
          newData[fieldName] = (responseValue && responseValue) || false;
          formValues[fieldName] = (responseValue && responseValue) || false;
        } else {
          newData[fieldName] = (responseValue && responseValue) || "";
          formValues[fieldName] = (responseValue && responseValue) || "";
        }
        remarksData[fieldName] = remarks;
        if (element.remarks !== undefined && element.remarks.length > 45) {
          isReadMore[element.fieldName] = true;
        } else {
          isReadMore[element.fieldName] = false;
        }
      }
    });

    packageId.children.map((element) => {
      const { fieldName, type, responseValue } = element;
      if (type === "DOCUMENT") {
        if (responseValue === "") {
          tempShow[fieldName] = true;
        } else {
          tempShow[fieldName] = false;
        }
        tempShowView[fieldName] = (responseValue && true) || false;
        tempExt[fieldName] =
          (responseValue && responseValue.split(".").pop()) || "";
      }
    });

    setPreviousValues(newData);
    setFormValues(formValues);
    setUpdatedRemarksData(remarksData);
    setIsReadMore(isReadMore);
    setTempShow(tempShow);
    setTempShowView(tempShowView);
    setTempExt(tempExt);
    setLoading(false);
  };

  const fetchKycDefinition = async () => {
    const params = {
      id: ACTIVE_KYC_ID,
    };

    const { data, status, error } = await getKycDefinition(params);
    if (error) {
      console.log(error);
    } else if (status === 200 && data) {
      const responseKycDefinitionData = data.data;

      let infoDefinition = [];
      let submitDefinitions = [];
      let showError = {};
      let tempShow = [];
      let tempShowView = [];
      let tempExt = [];
      let formValues = [];
      let kycExt = [];
      let extStr = [];

      const packageId = responseKycDefinitionData.packagesDTOs.find(
        (packageDef) => packageDef.id === "61e569cf3ff56b6727ac06aa"
      );

      const packageDTOs = packageId.children;
      setPackageTitle(packageId.packageTitle);

      packageDTOs.map((personDefinition) => {
        if (personDefinition.fieldName !== "time_spent_on_documents") {
          infoDefinition.push({
            fieldId: personDefinition.id,
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
            displayType: personDefinition.displayType,
          });
          showError[personDefinition.fieldName] = false;
        }

        if (personDefinition.type === "DOCUMENT") {
          tempShow[personDefinition.fieldName] = true;
          tempShowView[personDefinition.fieldName] = false;
          tempExt[personDefinition.fieldName] = "";
          kycExt[personDefinition.fieldName] = [];
          extStr[personDefinition.fieldName] = "";
        }
      });

      packageDTOs.map((personDefinition) => {
        if (personDefinition.type === "DOCUMENT") {
          personDefinition.fileTypes.map((file) => {
            kycExt[personDefinition.fieldName].push(file.split(".").pop());
          });
        }
      });

      packageDTOs.map((personDefinition) => {
        submitDefinitions.push({
          fieldName: personDefinition.fieldName,
        });
        if (personDefinition.displayType === "CHECKMARK") {
          formValues[personDefinition.fieldName] = false;
        } else {
          formValues[personDefinition.fieldName] = "";
        }
      });

      packageDTOs.map((personDefinition) => {
        if (personDefinition.type === "DOCUMENT") {
          extStr[personDefinition.fieldName] = kycExt[
            personDefinition.fieldName
          ]
            .toString()
            .replaceAll(",", "/");
        }
      });

      setKycExt(kycExt);
      setExtStr(extStr);
      setShowError(showError);
      setKycDefinition(infoDefinition);
      setInfoDefinition(infoDefinition);
      setVersionData(responseKycDefinitionData.version);
      setSubmitDefinitions(submitDefinitions);
      fetchCustomerKyc(tempShow, tempShowView, tempExt, formValues);
    }
  };

  const fetchCustomerKyc = async (
    tempShow,
    tempShowView,
    tempExt,
    formValues
  ) => {
    const customerKycParams = {
      msisdn: mobileNumber,
      kycId: ACTIVE_KYC_ID,
    };

    const { data, status, error } = await getCustomerKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data) {
      const responseData = data.data;
      if (responseData !== undefined) {
        parseData(responseData, tempShow, tempShowView, tempExt, formValues);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setShowError({
      ...showError,
      [name]: false,
    });
    setErrMsg();
  };

  const toggleReadMore = (fieldName) => {
    setIsReadMore({ ...isReadMore, [fieldName]: !isReadMore[fieldName] });
  };

  const handleFileChange = async (value, fieldName) => {
    setLoading(true);
    setErrMsg();
    const file = value[0];
    const fileName = file.name;
    const fileExtension = file.name.split(".").pop();
    const options = {
      maxSizeMB: 0.5,
      useWebWorker: true,
    };

    let compressedFile;
    var newFile;

    if (file.size > 500000 && ["jpg", "jpeg", "png"].includes(fileExtension)) {
      compressedFile = await imageCompression(file, options);
      newFile = new File([compressedFile], fileName);
    } else {
      newFile = file;
    }
    var extension = newFile.name.split(".").pop();

    setTempExt({ ...tempExt, [fieldName]: extension });

    var formImageData = new FormData();
    formImageData.append("file", newFile);
    formImageData.append("kycId", ACTIVE_KYC_ID);
    formImageData.append("fieldId", fieldName);

    const { status, error, data } = await uploadKyc(formImageData);
    if (error) {
      console.log(error);
    } else if (status === 200 && data) {
      setFormValues({ ...formValues, [fieldName]: data.data.fileName });
      setTempShow({ ...tempShow, [fieldName]: false });
      setTempShowView({ ...tempShowView, [fieldName]: true });
      setLoading(false);
    }
  };

  const handleCheckMark = (fieldValue) => {
    if (formValues[fieldValue] === "" || formValues[fieldValue] === false) {
      setFormValues({ ...formValues, [fieldValue]: true });
    } else if (formValues[fieldValue] === true) {
      setFormValues({ ...formValues, [fieldValue]: false });
    }
  };

  const previousHandler = () => {
    navigate("/address");
  };

  const profileUpdateHandler = async () => {
    let bodyData = {
      msisdn: mobileNumber,
      profile_data: {},
    };
    submitDefinitions.map((field) => {
      const { fieldName, displayType } = field;
      if (displayType === "SLIDER") {
        bodyData["profile_data"][fieldName] = formValues[fieldName][0];
      } else {
        bodyData["profile_data"][fieldName] = formValues[fieldName];
      }
      bodyData["profile_data"]["time_spent_on_documents"] = convertedTime;
    });
    const { status, data, error } = await profileUpdate(bodyData);
    if (error) {
      toast.error(error.message);
    } else if (status === 200 && data) {
      updateHandler(bodyData);
    }
  };

  const updateHandler = async (bodyData) => {
    let { profile_data } = bodyData;
    let bodyValues = {};
    submitDefinitions.map((field) => {
      const { fieldName } = field;
      if (fieldName === "declare_additional_info") {
        bodyValues["is_additional_info_needed"] = profile_data[fieldName];
      }
      bodyValues[fieldName] = profile_data[fieldName];
    });

    let urlParameters = {
      uuid: workFlowActionId,
    };

    const { status, error } = await getworkFlowInteractionOutcome(
      urlParameters,
      bodyValues
    );
    if (error) {
      toast.error(error);
    } else if (status == 200) {
      getNextActionPage().then((response) => {
        if (response.status == true) {
          setLoading(false);
          toast.success("Info updated successfully");
          navigate(response.screen);
        }
      });
    }
  };

  const createOrUpdateKyc = async (convertedTime) => {
    setLoading(true);
    let bodyData = {
      kycId: ACTIVE_KYC_ID,
      created_by: DecryptData(getCookie("phone")),
      data: {},
      version: versionData,
    };

    submitDefinitions.map((field) => {
      const { fieldName } = field;
      bodyData["data"][fieldName] = formValues[fieldName];
    });

    bodyData["data"]["time_spent_on_documents"] = convertedTime;

    const { status, data, error } = await submitKyc(bodyData);
    if (error) {
      toast.error(error.message);
    } else if (status === 200 && data) {
      profileUpdateHandler();
    }
  };

  const nextHandler = async () => {
    let errCount = 0;
    let message = "";
    for (let i = 0; i < infoDefinition.length; i++) {
      let element = infoDefinition[i];
      const { fieldName, fieldDisplayName, mandatory, type } = element;
      if (mandatory && !formValues[fieldName]) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} required!`;
        break;
      } else if (type === "DOCUMENT") {
        if (mandatory && !kycExt[fieldName].includes(tempExt[fieldName])) {
          errCount = 1;
          setShowError({ ...showError, [fieldName]: true });
          message = `Please upload ${fieldDisplayName} in ${extStr[fieldName]}`;
          break;
        }
        if (
          updatedRemarksData[fieldName] !== undefined &&
          previousValues[fieldName] === formValues[fieldName]
        ) {
          errCount = 1;
          setShowError({ ...showError, [fieldName]: true });
          message = `Please provide another ${fieldDisplayName}`;
          break;
        }
      } else if (
        updatedRemarksData[fieldName] !== undefined &&
        previousValues[fieldName] === formValues[fieldName]
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `Please provide another ${fieldDisplayName}`;
        break;
      }
    }
    setErrMsg(message);
    if (errCount === 0) {
      findFinalTime();
    }
  };

  const secondsToHms = (time) => {
    var date = new Date(null);
    date.setSeconds(time);
    let convertedTime = date.toISOString().substr(11, 8);
    setConvertedTime(convertedTime);
    createOrUpdateKyc(convertedTime);
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <Stepper currentStep={5} />
        <div className="content-container">
          <div className="common-heading">
            <h1>{packageTitle}</h1>
          </div>

          {kycDefinition.map((formField, index) => {
            switch (true) {
              case formField.type === "RADIO" &&
                formField.displayType === undefined:
                return (
                  <div key={index}>
                    <DocumentRadio
                      fieldName={formField.fieldName}
                      display={formField.fieldDisplayName}
                      mandatory={formField.mandatory}
                      formValues={formValues}
                      showError={showError}
                      handleChange={handleChange}
                      options={formField.options}
                      fieldId={formField.fieldId}
                    />
                    {updatedRemarksData[formField.fieldName] && (
                      <p className="remarks">
                        {isReadMore[formField.fieldName]
                          ? updatedRemarksData[formField.fieldName].slice(0, 45)
                          : updatedRemarksData[formField.fieldName]}
                        {updatedRemarksData[formField.fieldName].length >
                          45 && (
                          <span
                            onClick={() => toggleReadMore(formField.fieldName)}
                          >
                            {isReadMore[formField.fieldName]
                              ? "...Read more"
                              : " Show less"}
                          </span>
                        )}
                      </p>
                    )}
                  </div>
                );

              default:
                break;
            }
          })}
          <div className="grid-wrapper">
            {kycDefinition.map((formField, index) => {
              switch (formField.type) {
                case "DOCUMENT":
                  return (
                    <div key={index}>
                      <DocumentFile
                        fieldName={formField.fieldName}
                        display={formField.fieldDisplayName}
                        mandatory={formField.mandatory}
                        formValues={formValues}
                        showError={showError}
                        handleFileChange={handleFileChange}
                        options={formField.options}
                        fieldId={formField.fieldId}
                        tempShow={tempShow}
                        tempShowView={tempShowView}
                        tempExt={tempExt}
                      />
                      {updatedRemarksData[formField.fieldName] && (
                        <p className="remarks">
                          {isReadMore[formField.fieldName]
                            ? updatedRemarksData[formField.fieldName].slice(
                                0,
                                45
                              )
                            : updatedRemarksData[formField.fieldName]}
                          {updatedRemarksData[formField.fieldName].length >
                            45 && (
                            <span
                              onClick={() =>
                                toggleReadMore(formField.fieldName)
                              }
                            >
                              {isReadMore[formField.fieldName]
                                ? "...Read more"
                                : " Show less"}
                            </span>
                          )}
                        </p>
                      )}
                    </div>
                  );

                default:
                  break;
              }
            })}
          </div>
          {kycDefinition.map((formField, index) => {
            switch (true) {
              case formField.type === "RADIO" &&
                formField.displayType === "CHECKMARK":
                return (
                  <div key={index}>
                    <CheckMark
                      fieldName={formField.fieldName}
                      display={formField.fieldDisplayName}
                      mandatory={formField.mandatory}
                      formValues={formValues}
                      showError={showError}
                      handleCheckMark={handleCheckMark}
                      options={formField.options}
                      fieldId={formField.fieldId}
                    />
                    {updatedRemarksData[formField.fieldName] && (
                      <p className="remarks">
                        {isReadMore[formField.fieldName]
                          ? updatedRemarksData[formField.fieldName].slice(0, 45)
                          : updatedRemarksData[formField.fieldName]}
                        {updatedRemarksData[formField.fieldName].length >
                          45 && (
                          <span
                            onClick={() => toggleReadMore(formField.fieldName)}
                          >
                            {isReadMore[formField.fieldName]
                              ? "...Read more"
                              : " Show less"}
                          </span>
                        )}
                      </p>
                    )}
                  </div>
                );

              default:
                break;
            }
          })}

          {errMsg && <Error message={errMsg} />}
        </div>
        <div className="button-section">
          <div>
            <button
              type="button"
              onClick={previousHandler}
              className="btn btn-outline-primary"
            >
              Previous
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={nextHandler}
              className="btn btn-primary"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Document;
