import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import "./consumer-loan-simulator.css";

import {
  getKycDefinition,
  getCustomerKyc,
  submitKyc,
} from "../../services/kyc-service";

import { getworkFlowInteractionOutcome } from "../../services/workflow-service";

import { getCookie } from "../../utils/cookie-helper";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Error from "../../components/error";
import Loader from "../../components/loader";

import { ACTIVE_KYC_ID } from "../../constants";
import { toast } from "react-toastify";
import {
  getNextActionPage,
  fetchAllWorkFlowActions,
  DecryptData,
} from "../../utils/application-helper";

import RangeSlider from "../../components/rangeSlider";
import Input from "../../components/input";
import DateInput from "../../components/dateInput";
import Select from "../../components/select";
import NumberInput from "../../components/numberInput";

const ConsumerLoanSimulator = () => {
  const [infoDefinition, setInfoDefinition] = useState([]);
  const [updatedRemarksData, setUpdatedRemarksData] = useState([]);
  const [previousValues, setPreviousValues] = useState({});
  const [isReadMore, setIsReadMore] = useState({});
  const [versionData, setVersionData] = useState(0);
  const [packageTitle, setPackageTitle] = useState("");
  const [workFlowActionId, setWorkFlowActionId] = useState("");
  const [loading, setLoading] = useState(true);
  const [productDef, setProductDef] = useState();
  const [modelDef, setModelDef] = useState();
  const [modelKeys, setModelKeys] = useState();
  const [showError, setShowError] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const [submitDefinitions, setSubmitDefinitions] = useState([]);
  const [formValues, setFormValues] = useState({});

  const navigate = useNavigate();

  const params = {
    id: ACTIVE_KYC_ID,
  };

  const mobileNumber = DecryptData(getCookie("phone"));

  useEffect(() => {
    fetchKycDefinition();
    fetchAllWorkKyc();
  }, []);

  const fetchAllWorkKyc = () => {
    fetchAllWorkFlowActions("consumer_loan_journey").then((response) => {
      if (response.status === true) {
        setWorkFlowActionId(response.value);
      }
    });
  };

  const handleChange = (e, MAX_VALUE) => {
    setErrMsg();
    let { name, value } = e.target;
    if (MAX_VALUE !== undefined) {
      let updatedValue = Number(value);
      if (updatedValue > MAX_VALUE) {
        updatedValue = MAX_VALUE;
      }
      setFormValues({ ...formValues, [name]: [updatedValue] });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    setShowError({
      ...showError,
      [name]: false,
    });
  };

  const handleSliderChange = (fieldName, value) => {
    setErrMsg();
    setFormValues({ ...formValues, [fieldName]: value });
  };

  const toggleReadMore = (fieldName) => {
    setIsReadMore({ ...isReadMore, [fieldName]: !isReadMore[fieldName] });
  };

  const fetchKycDefinition = async () => {
    const { data, status, error } = await getKycDefinition(params);
    if (error) {
      console.log(error);
    } else if (status === 200 && data) {
      const responseKycDefinitionData = data.data;

      let infoDefinition = [];

      let prodOptions = [];

      let submitDefinitions = [];

      let showError = {};

      let formValues = {};

      const packageId = responseKycDefinitionData.packagesDTOs.find(
        (packageDef) => packageDef.id === "62319f156fe66505a420c82f"
      );

      const packageDTOs = packageId.children;
      setPackageTitle(packageId.packageTitle);

      packageDTOs.map((personDefinition) => {
        if (
          personDefinition.fieldName !== "phone_options" &&
          personDefinition.fieldName !== "tv_options" &&
          personDefinition.fieldName !== "ac_options" &&
          personDefinition.fieldName !== "required_loan_amount" &&
          personDefinition.fieldName !== "product_selector"
        ) {
          infoDefinition.push({
            fieldId: personDefinition.id,
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
            displayType: personDefinition.displayType,
          });
          showError[personDefinition.fieldName] = false;
          if (
            personDefinition.type === "NUMBER" &&
            personDefinition.displayType === "SLIDER"
          ) {
            formValues[personDefinition.fieldName] = [0];
          } else {
            formValues[personDefinition.fieldName] = undefined;
          }
        }
      });

      packageDTOs.map((personDefinition) => {
        if (
          personDefinition.fieldName !== "phone_options" &&
          personDefinition.fieldName !== "tv_options" &&
          personDefinition.fieldName !== "ac_options" &&
          personDefinition.fieldName !== "required_loan_amount"
        ) {
          submitDefinitions.push({
            fieldName: personDefinition.fieldName,
            mandatory: personDefinition.mandatory,
            displayType: personDefinition.displayType,
            validation: personDefinition.validation,
          });
        }
      });

      packageDTOs.map((personDefinition) => {
        if (personDefinition.fieldName === "product_selector") {
          const multi = personDefinition.multiLookup;
          setProductDef(multi);
          prodOptions = Object.keys(multi);
        }
      });

      infoDefinition.map((element) => {
        if (element.fieldName === "select_product") {
          element.options = prodOptions;
        }
      });

      setShowError(showError);
      setInfoDefinition(infoDefinition);
      setVersionData(responseKycDefinitionData.version);
      setSubmitDefinitions(submitDefinitions);
      fetchCustomerKyc(formValues);
    }
  };

  useEffect(() => {
    parseProductData(productDef, formValues.select_product);
  }, [productDef, formValues.select_product]);

  useEffect(() => {
    parseModelData(modelDef, formValues.select_model);
  }, [modelDef, formValues.select_model]);

  const parseProductData = (productDef, productValue) => {
    if (productDef !== undefined) {
      setFormValues({
        ...formValues,
        select_model: undefined,
        selected_product_amount: undefined,
      });
      const productArray = Object.entries(productDef);
      const modelArray = [];

      productArray.map((element) => {
        if (element[0] === productValue) {
          modelArray.push(element[1]);
        }
      });
      let modelValue = modelArray[0];
      if (modelValue !== undefined) {
        setModelDef(modelValue);
        let modelKeys = Object.keys(modelValue);
        setModelKeys(modelKeys);
      }
    }
  };

  const parseModelData = (modelDef, modelValue) => {
    if (modelDef !== undefined) {
      const modelArray = Object.entries(modelDef);
      modelArray.map((element) => {
        if (element[0] === modelValue) {
          let tempArr = element[1];
          setFormValues({
            ...formValues,
            selected_product_amount: tempArr[0],
          });
        }
      });
    }
  };

  const fetchCustomerKyc = async (formValues) => {
    const customerKycParams = {
      msisdn: mobileNumber,
      kycId: ACTIVE_KYC_ID,
    };

    const { data, status, error } = await getCustomerKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data) {
      const responseData = data.data;
      if (responseData !== undefined) {
        parseCustomerData(responseData, formValues);
      }
    }
  };

  const parseCustomerData = (responseData, formValues) => {
    let remarksData = {};
    let isReadMore = {};

    const packageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "62319f156fe66505a420c82f"
    );

    packageId.children.map((element) => {
      let { displayType, responseValue, remarks, fieldName } = element;
      if (displayType === "SLIDER" && responseValue !== "") {
        formValues[fieldName][0] = responseValue;
      } else if (displayType !== "SLIDER") {
        formValues[fieldName] = responseValue;
      }
      remarksData[fieldName] = remarks;
      if (element.remarks !== undefined && element.remarks.length > 45) {
        isReadMore[element.fieldName] = true;
      } else {
        isReadMore[element.fieldName] = false;
      }
    });

    setFormValues(formValues);
    setPreviousValues(formValues);
    setUpdatedRemarksData(remarksData);
    setIsReadMore(isReadMore);
    setLoading(false);
  };

  useEffect(() => {
    calculateEMI(
      formValues.selected_product_amount,
      formValues.expected_repayment_period_months,
      formValues.expected_interest_rate
    );
  }, [
    formValues.selected_product_amount,
    formValues.expected_repayment_period_months,
    formValues.expected_interest_rate,
  ]);

  const calculateEMI = (amount, tenure, interestRate) => {
    let userAmount = Number(amount);
    let calculatedInterest = Number(interestRate) / 1200;
    let calculatedPayments = Number(tenure);
    let x = Math.pow(1 + calculatedInterest, calculatedPayments);
    let monthly = (userAmount * x * calculatedInterest) / (x - 1);
    let finalAmount = monthly.toFixed(2);
    if (isNaN(finalAmount) || !isFinite(finalAmount)) {
      setFormValues({ ...formValues, expected_emi_amount: 0 });
    } else {
      setFormValues({ ...formValues, expected_emi_amount: finalAmount });
    }
  };

  const nextHandler = () => {
    let message;
    let errCount = 0;
    for (let i = 0; i < infoDefinition.length; i++) {
      let testRegEx;
      let element = infoDefinition[i];
      const {
        fieldName,
        fieldDisplayName,
        mandatory,
        validation,
        displayType,
      } = element;
      if (validation !== undefined) {
        testRegEx = new RegExp(validation);
      }

      if (
        (mandatory && !formValues[fieldName] && displayType !== "SLIDER") ||
        (mandatory &&
          displayType === "SLIDER" &&
          formValues[fieldName][0] === 0)
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} required!`;
        break;
      } else if (
        mandatory &&
        validation !== undefined &&
        !testRegEx.test(formValues[fieldName])
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} not matching!`;
        break;
      } else if (
        mandatory &&
        fieldName === "expected_interest_rate" &&
        (formValues[fieldName] < 12 || formValues[fieldName] > 20)
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} should lie between 12% and 20%`;
      } else if (
        (updatedRemarksData[fieldName] !== undefined &&
          displayType !== "SLIDER" &&
          previousValues[fieldName] === formValues[fieldName]) ||
        (updatedRemarksData[fieldName] !== undefined &&
          displayType === "SLIDER" &&
          previousValues[fieldName][0] === formValues[fieldName][0])
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `Please provide another ${fieldDisplayName}`;
        break;
      }
    }
    setErrMsg(message);
    if (errCount === 0) {
      createOrUpdateKyc();
    }
  };

  const createOrUpdateKyc = async () => {
    setLoading(true);
    let bodyData = {
      kycId: ACTIVE_KYC_ID,
      created_by: DecryptData(getCookie("phone")),
      data: {},
      version: versionData,
    };

    submitDefinitions.map((field) => {
      const { fieldName, displayType } = field;
      if (displayType === "SLIDER") {
        bodyData["data"][fieldName] = formValues[fieldName][0];
      } else if (fieldName === "product_selector") {
        let tempValue = [
          formValues.select_product,
          formValues.select_model,
          formValues.selected_product_amount,
        ];
        bodyData["data"][fieldName] = tempValue;
      } else {
        bodyData["data"][fieldName] = formValues[fieldName];
      }
    });

    const { status, data, error } = await submitKyc(bodyData);
    if (error) {
      toast.error(error.message);
    } else if (status === 200 && data) {
      updateHandler();
    }
  };

  const updateHandler = async () => {
    let bodyValues = {};

    submitDefinitions.map((field) => {
      const { fieldName, displayType } = field;
      if (displayType === "SLIDER") {
        bodyValues[fieldName] = formValues[fieldName][0];
      } else if (fieldName === "product_selector") {
        let tempValue = [
          formValues.select_product,
          formValues.select_model,
          formValues.selected_product_amount,
        ];
        bodyValues[fieldName] = tempValue;
      } else {
        bodyValues[fieldName] = formValues[fieldName];
      }
    });

    let urlParameters = {
      uuid: workFlowActionId,
    };

    const { status, error } = await getworkFlowInteractionOutcome(
      urlParameters,
      bodyValues
    );
    if (error) {
      toast.error(error);
    } else if (status == 200) {
      getNextActionPage().then((response) => {
        if (response.status == true) {
          setLoading(false);
          toast.success("Info updated successfully");
          navigate(response.screen);
        }
      });
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <Stepper currentStep={1} />
        <div className="content-container">
          <div className="common-heading">
            <h1>{packageTitle}</h1>
          </div>
          <div className="list-section-wrapper address-edit-screen">
            <ul>
              {infoDefinition.map((formField, index) => {
                if (
                  formField.fieldName === "select_product" ||
                  formField.fieldName === "select_model" ||
                  formField.fieldName === "selected_product_amount"
                ) {
                  switch (true) {
                    case formField.type === "TEXT" &&
                      formField.displayType === undefined:
                      return (
                        <li key={index}>
                          <Input
                            fieldName={formField.fieldName}
                            display={formField.fieldDisplayName}
                            mandatory={formField.mandatory}
                            formValues={formValues}
                            showError={showError}
                            handleChange={handleChange}
                          />
                          {updatedRemarksData[formField.fieldName] && (
                            <p className="remarks">
                              {isReadMore[formField.fieldName]
                                ? updatedRemarksData[formField.fieldName].slice(
                                    0,
                                    45
                                  )
                                : updatedRemarksData[formField.fieldName]}
                              {updatedRemarksData[formField.fieldName].length >
                                45 && (
                                <span
                                  onClick={() =>
                                    toggleReadMore(formField.fieldName)
                                  }
                                >
                                  {isReadMore[formField.fieldName]
                                    ? "...Read more"
                                    : " Show less"}
                                </span>
                              )}
                            </p>
                          )}
                        </li>
                      );
                    case formField.type === "CALENDAR" &&
                      formField.displayType === undefined:
                      return (
                        <li key={index}>
                          <DateInput
                            fieldName={formField.fieldName}
                            display={formField.fieldDisplayName}
                            mandatory={formField.mandatory}
                            formValues={formValues}
                            showError={showError}
                            handleChange={handleChange}
                          />
                          {updatedRemarksData[formField.fieldName] && (
                            <p className="remarks">
                              {isReadMore[formField.fieldName]
                                ? updatedRemarksData[formField.fieldName].slice(
                                    0,
                                    45
                                  )
                                : updatedRemarksData[formField.fieldName]}
                              {updatedRemarksData[formField.fieldName].length >
                                45 && (
                                <span
                                  onClick={() =>
                                    toggleReadMore(formField.fieldName)
                                  }
                                >
                                  {isReadMore[formField.fieldName]
                                    ? "...Read more"
                                    : " Show less"}
                                </span>
                              )}
                            </p>
                          )}
                        </li>
                      );
                    case formField.type === "RADIO" &&
                      (formField.displayType === undefined ||
                        formField.displayType === "SINGLELINE"):
                      return (
                        <li key={index}>
                          <Select
                            fieldName={formField.fieldName}
                            display={formField.fieldDisplayName}
                            mandatory={formField.mandatory}
                            formValues={formValues}
                            showError={showError}
                            handleChange={handleChange}
                            options={
                              formField.fieldName === "select_model"
                                ? modelKeys
                                : formField.options
                            }
                          />
                          {updatedRemarksData[formField.fieldName] && (
                            <p className="remarks">
                              {isReadMore[formField.fieldName]
                                ? updatedRemarksData[formField.fieldName].slice(
                                    0,
                                    45
                                  )
                                : updatedRemarksData[formField.fieldName]}
                              {updatedRemarksData[formField.fieldName].length >
                                45 && (
                                <span
                                  onClick={() =>
                                    toggleReadMore(formField.fieldName)
                                  }
                                >
                                  {isReadMore[formField.fieldName]
                                    ? "...Read more"
                                    : " Show less"}
                                </span>
                              )}
                            </p>
                          )}
                        </li>
                      );
                    case formField.type === "NUMBER" &&
                      (formField.displayType === undefined ||
                        formField.displayType === "SINGLELINE"):
                      return (
                        <li key={index}>
                          <NumberInput
                            fieldName={formField.fieldName}
                            display={formField.fieldDisplayName}
                            mandatory={formField.mandatory}
                            formValues={formValues}
                            showError={showError}
                            handleChange={handleChange}
                            options={formField.options}
                            editable={formField.editable}
                          />
                          {updatedRemarksData[formField.fieldName] && (
                            <p className="remarks">
                              {isReadMore[formField.fieldName]
                                ? updatedRemarksData[formField.fieldName].slice(
                                    0,
                                    45
                                  )
                                : updatedRemarksData[formField.fieldName]}
                              {updatedRemarksData[formField.fieldName].length >
                                45 && (
                                <span
                                  onClick={() =>
                                    toggleReadMore(formField.fieldName)
                                  }
                                >
                                  {isReadMore[formField.fieldName]
                                    ? "...Read more"
                                    : " Show less"}
                                </span>
                              )}
                            </p>
                          )}
                        </li>
                      );

                    default:
                      break;
                  }
                }
              })}
              <div className="range-slider-wrapper loan-simulator-screen consumer-loan-simulator">
                {infoDefinition.map((formField, index) => {
                  switch (true) {
                    case formField.type === "NUMBER" &&
                      formField.displayType === "SLIDER":
                      return (
                        <React.Fragment key={index}>
                          <RangeSlider
                            fieldName={formField.fieldName}
                            display={formField.fieldDisplayName}
                            mandatory={formField.mandatory}
                            formValues={formValues}
                            showError={showError}
                            handleSliderChange={handleSliderChange}
                            handleChange={handleChange}
                            MIN_VALUE={0}
                            MAX_VALUE={24}
                            STEP_VALUE={1}
                          />
                          {updatedRemarksData[formField.fieldName] && (
                            <p className="remarks">
                              {isReadMore[formField.fieldName]
                                ? updatedRemarksData[formField.fieldName].slice(
                                    0,
                                    45
                                  )
                                : updatedRemarksData[formField.fieldName]}
                              {updatedRemarksData[formField.fieldName].length >
                                45 && (
                                <span
                                  onClick={() =>
                                    toggleReadMore(formField.fieldName)
                                  }
                                >
                                  {isReadMore[formField.fieldName]
                                    ? "...Read more"
                                    : " Show less"}
                                </span>
                              )}
                            </p>
                          )}
                        </React.Fragment>
                      );

                    default:
                      break;
                  }
                })}
              </div>
            </ul>
          </div>

          <div className="list-section-wrapper address-edit-screen loan-simulator-screen">
            <ul>
              {infoDefinition.map((formField) => {
                if (
                  formField.fieldName !== "select_product" &&
                  formField.fieldName !== "select_model" &&
                  formField.fieldName !== "selected_product_amount"
                ) {
                  switch (true) {
                    case formField.type === "TEXT" &&
                      formField.displayType === undefined:
                      return (
                        <li key={formField.fieldName}>
                          <Input
                            fieldName={formField.fieldName}
                            display={formField.fieldDisplayName}
                            mandatory={formField.mandatory}
                            formValues={formValues}
                            showError={showError}
                            handleChange={handleChange}
                          />
                          {updatedRemarksData[formField.fieldName] && (
                            <p className="remarks">
                              {isReadMore[formField.fieldName]
                                ? updatedRemarksData[formField.fieldName].slice(
                                    0,
                                    45
                                  )
                                : updatedRemarksData[formField.fieldName]}
                              {updatedRemarksData[formField.fieldName].length >
                                45 && (
                                <span
                                  onClick={() =>
                                    toggleReadMore(formField.fieldName)
                                  }
                                >
                                  {isReadMore[formField.fieldName]
                                    ? "...Read more"
                                    : " Show less"}
                                </span>
                              )}
                            </p>
                          )}
                        </li>
                      );
                    case formField.type === "CALENDAR" &&
                      formField.displayType === undefined:
                      return (
                        <li key={formField.fieldName}>
                          <DateInput
                            fieldName={formField.fieldName}
                            display={formField.fieldDisplayName}
                            mandatory={formField.mandatory}
                            formValues={formValues}
                            showError={showError}
                            handleChange={handleChange}
                          />
                          {updatedRemarksData[formField.fieldName] && (
                            <p className="remarks">
                              {isReadMore[formField.fieldName]
                                ? updatedRemarksData[formField.fieldName].slice(
                                    0,
                                    45
                                  )
                                : updatedRemarksData[formField.fieldName]}
                              {updatedRemarksData[formField.fieldName].length >
                                45 && (
                                <span
                                  onClick={() =>
                                    toggleReadMore(formField.fieldName)
                                  }
                                >
                                  {isReadMore[formField.fieldName]
                                    ? "...Read more"
                                    : " Show less"}
                                </span>
                              )}
                            </p>
                          )}
                        </li>
                      );
                    case formField.type === "RADIO" &&
                      (formField.displayType === undefined ||
                        formField.displayType === "SINGLELINE"):
                      return (
                        <li key={formField.fieldName}>
                          <Select
                            fieldName={formField.fieldName}
                            display={formField.fieldDisplayName}
                            mandatory={formField.mandatory}
                            formValues={formValues}
                            showError={showError}
                            handleChange={handleChange}
                            options={formField.options}
                          />
                          {updatedRemarksData[formField.fieldName] && (
                            <p className="remarks">
                              {isReadMore[formField.fieldName]
                                ? updatedRemarksData[formField.fieldName].slice(
                                    0,
                                    45
                                  )
                                : updatedRemarksData[formField.fieldName]}
                              {updatedRemarksData[formField.fieldName].length >
                                45 && (
                                <span
                                  onClick={() =>
                                    toggleReadMore(formField.fieldName)
                                  }
                                >
                                  {isReadMore[formField.fieldName]
                                    ? "...Read more"
                                    : " Show less"}
                                </span>
                              )}
                            </p>
                          )}
                        </li>
                      );
                    case formField.type === "NUMBER" &&
                      (formField.displayType === undefined ||
                        formField.displayType === "SINGLELINE"):
                      return (
                        <li key={formField.fieldName}>
                          <NumberInput
                            fieldName={formField.fieldName}
                            display={formField.fieldDisplayName}
                            mandatory={formField.mandatory}
                            formValues={formValues}
                            showError={showError}
                            handleChange={handleChange}
                            options={formField.options}
                            editable={formField.editable}
                          />
                          {updatedRemarksData[formField.fieldName] && (
                            <p className="remarks">
                              {isReadMore[formField.fieldName]
                                ? updatedRemarksData[formField.fieldName].slice(
                                    0,
                                    45
                                  )
                                : updatedRemarksData[formField.fieldName]}
                              {updatedRemarksData[formField.fieldName].length >
                                45 && (
                                <span
                                  onClick={() =>
                                    toggleReadMore(formField.fieldName)
                                  }
                                >
                                  {isReadMore[formField.fieldName]
                                    ? "...Read more"
                                    : " Show less"}
                                </span>
                              )}
                            </p>
                          )}
                        </li>
                      );

                    default:
                      break;
                  }
                }
              })}
            </ul>
          </div>
          {errMsg && <Error message={errMsg} />}
        </div>
        <div className="button-section justify-content-end">
          <div>
            <button
              type="button"
              onClick={nextHandler}
              className="btn btn-primary"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumerLoanSimulator;
