import React from "react";

const SelectGroup = ({
  fieldName,
  display,
  mandatory,
  gpError,
  handleGroupChange,
  gpValues,
  options,
  gpIndex,
}) => {
  return (
    <div>
      <label>
        {display} <span className="red">{mandatory === true ? "*" : ""}</span>
      </label>
      <select
        className={
          gpError[fieldName][gpIndex] ? "form-select border-red" : "form-select"
        }
        name={fieldName}
        onChange={(e) => handleGroupChange(gpIndex, fieldName, e.target.value)}
        value={gpValues[fieldName][gpIndex] ? gpValues[fieldName][gpIndex] : ""}
      >
        <option value="">Choose</option>
        {options &&
          options.map((item, index) => {
            return (
              <option
                key={item.value ? item.value : index}
                value={item.value ? item.value : item}
              >
                {item.display ? item.display : item}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default SelectGroup;
