import React from "react";
import "./error.css";
import alertInfo from "../../assets/images/alert-info.svg";

const Error = ({ message }) => {
  return (
    <div className="error-validation">
      <p>
        <img src={alertInfo} alt="alertInfo" />
        {message}
      </p>
    </div>
  );
};

export default Error;
