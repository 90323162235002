import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import OtpInput from "react-otp-input";

import { toast } from "react-toastify";

import "./login.css";

import logo from "../../assets/images/logo.svg";
import Error from "../../components/error";
import Loader from "../../components/loader";

import { saveCookie, getCookie } from "../../utils/cookie-helper";
import { resendOtp, verifyOtp } from "../../services/lms-service";
import {
  ACTIVE_LMS_PRODUCT,
  ACTIVE_WORKFLOW_NAME,
  LMS_PARTNER_CODE,
} from "../../constants";
import { initiateWorkFlow } from "../../services/workflow-service";
import { EncryptData, DecryptData } from "../../utils/application-helper";

const OtpVerify = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [resendTimer, setResendTimer] = useState(true);
  const [counter, setCounter] = useState(30);
  const [errorMessage, setErrorMessage] = useState("");
  const [passcode, setPasscode] = useState();

  const mobileNumber = DecryptData(getCookie("phone"));

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (timer === false) {
      setShowResend(true);
      setResendTimer(false);
    }
    return () => clearInterval(timer);
  }, [counter]);

  const onChangePasscode = (passcode) => {
    setShowError(false);

    setPasscode(passcode);
  };

  const timerHandler = () => {
    setShowResend(false);
    setResendTimer(true);
    setCounter(30);
    setPasscode();
    proceedResentOtp();
  };

  const proceedResentOtp = () => {
    const resendOtpParams = {
      msisdn: mobileNumber,
      partner_code: LMS_PARTNER_CODE,
      purpose: "customer_verification",
    };

    resendOtp(resendOtpParams).then((response) => {
      if (response.data != undefined && response.data.status == "success") {
        toast.success("OTP Resent!");
      } else {
        toast.error(response.message);
      }
    });
  };

  const proceedVerifyOtpAndInitiateWorkFlow = (e) => {
    e.preventDefault();
    if (passcode === undefined) {
      setShowError(true);
      setErrorMessage("OTP should not be empty");
    } else if (passcode.length !== 6) {
      setShowError(true);
      setErrorMessage("OTP should have six characters");
    } else {
      let customerOtp = passcode;
      const verifyOtpParams = {
        msisdn: mobileNumber,
        partner_code: LMS_PARTNER_CODE,
        purpose: "customer_verification",
        otp: customerOtp,
      };

      verifyOtp(verifyOtpParams).then((response) => {
        if (response.data != undefined && response.data.code === 2007) {
          proceedInitiateWorkFlow();
        } else {
          toast.error(response.data.message);
        }
      });
    }
  };

  const proceedInitiateWorkFlow = () => {
    setLoading(true);
    const initiateWorkFlowParams = {
      msisdn: mobileNumber,
      partner_code: LMS_PARTNER_CODE,
      product_type: ACTIVE_LMS_PRODUCT,
      workflow_name: ACTIVE_WORKFLOW_NAME,
    };

    initiateWorkFlow(initiateWorkFlowParams).then((response) => {
      if (response.status == 200) {
        saveCookie("workflow_id", EncryptData(response.data.data.uuid));
        toast.success("OTP Verified!");
        const responseAction = response.data.data.actions;
        if (responseAction.length === 0) {
          navigate("/failed");
        } else {
          switch (responseAction[0].action) {
            case "show_reject_screen":
              navigate("/loan-rejected");
              break;
            case "get_loan_amount":
              navigate("/loan-info");
              break;
            case "consumer_app_journey":
              navigate("/customer-app");
              break;
            case "loan_service_menu":
              navigate("/loan-service");
              break;
            case "choose_loan_journey":
              navigate("/home");
              break;
            case "loan_simulator":
              navigate("/personal-loan-simulator");
              break;
            case "consumer-loan-journey":
              navigate("/consumer-loan-simulator");
              break;
            case "previous_loan_details":
              navigate("/previous-loan-details");
              break;
            case "get_personal_info":
              navigate("/personal-info");
              break;
            case "get_address":
              navigate("/address");
              break;
            case "get_documents":
              navigate("/documents");
              break;
            case "show_loan_summary":
              navigate("/summary");
              break;
            case "get_mandate":
              navigate("/mandate");
              break;
            case "get_review":
              navigate("/review");
              break;
            case "get_additional_info":
              navigate("/additional-info");
              break;
            case "do_personal_discussion":
              navigate("/personal-discussion");
              break;
            case "get_business_details":
              navigate("/business-info");
              break;
            case "get_personal_income":
              navigate("/personal-income");
              break;
            case "get_lifestyle_details":
              navigate("/lifestyle-details");
              break;
            case "reject_lead":
              navigate("/loan-rejected");
              break;
            case "show_loan_agreement":
              navigate("/loan-agreement");
              break;
            default:
              break;
          }
        }
      } else {
        toast.error(response.message);
      }
    });
  };

  return loading ? (
    <Loader />
  ) : (
    <form className="auth-page-wrapper">
      <div className="auth-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="auth-wrapper">
        <h1>Welcome!</h1>
        <label>A OTP has been sent to - {mobileNumber}</label>
        <div className="otp-input otp-verify-wrapper">
          <OtpInput
            value={passcode}
            onChange={onChangePasscode}
            numInputs={6}
            separator={<span>&ensp;</span>}
            shouldAutoFocus="true"
          />
        </div>
        <div className="otp-resend">
          <p>
            Haven’t recieved code yet?
            <label>{resendTimer && `Resend in 00:${counter}`}</label>
            {showResend && <span onClick={timerHandler}>Resend</span>}
          </p>
        </div>
        {showError && <Error message={errorMessage} />}
        <button
          type="submit"
          className="btn btn-primary"
          onClick={(e) => {
            proceedVerifyOtpAndInitiateWorkFlow(e);
          }}
        >
          Verify OTP
        </button>
      </div>
    </form>
  );
};

export default OtpVerify;
