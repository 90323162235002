import React from "react";
import { Range, getTrackBackground } from "react-range";

const RangeSlider = ({
  fieldId,
  fieldName,
  display,
  mandatory,
  handleSliderChange,
  handleChange,
  formValues,
  MAX_VALUE,
  MIN_VALUE,
  STEP_VALUE,
}) => {
  return (
    <div className="at-wrapper" key={fieldId}>
      <div className="at-top">
        <label>
          {display}
          <span className="red">{mandatory === true ? "*" : ""}</span>
        </label>
        <input
          type="number"
          name={fieldName}
          value={formValues[fieldName]}
          className="form-control loan-input"
          onChange={(e) => handleChange(e, MAX_VALUE)}
          min={MIN_VALUE}
          max={MAX_VALUE}
        />
      </div>
      <div className="at-center">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            marginTop: "18px",
            marginBottom: "12px",
          }}
        >
          <Range
            values={formValues[fieldName]}
            step={STEP_VALUE}
            min={MIN_VALUE}
            max={MAX_VALUE}
            onChange={(value) => handleSliderChange(fieldName, value)}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "36px",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "8px",
                    width: "100%",
                    borderRadius: "1px",
                    background: getTrackBackground({
                      values: formValues[fieldName],
                      colors: ["#506DE2", "#E5E9FB"],
                      min: MIN_VALUE,
                      max: MAX_VALUE,
                    }),
                    alignSelf: "center",
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "24px",
                  width: "24px",
                  borderRadius: "50%",
                  border: "4px solid #506DE2",
                  backgroundColor: "#F7F7F7",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxSizing: "border-box",
                  cursor: "pointer",
                  outline: "none",
                }}
              ></div>
            )}
          />
        </div>
      </div>
      <div className="at-bottom">
        <label>₹&ensp;{MIN_VALUE}</label>
        <label>₹&ensp;{MAX_VALUE}</label>
      </div>
    </div>
  );
};

export default RangeSlider;
