import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { getNextActionPage } from "../../utils/application-helper";

import "./review.css";
import thinkingCat from "../../assets/gif/thinkingCat.gif";

const Review = () => {
  const navigate = useNavigate();
  const pageReload = () => {
    window.location.reload();
  };

  useEffect(() => {
    fetchNextAction();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      pageReload();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const fetchNextAction = () => {
    getNextActionPage().then((response) => {
      if (response.status == true) {
        navigate(response.screen);
      }
    });
  };

  return (
    <div className="status-action">
      <div className="waiting-content">
        <img src={thinkingCat} alt="clock-image" />
        <h1>
          {" "}
          Your case is under review. Kindly check after sometime.
          <button className="btn-primary" onClick={pageReload}>
            Reload
          </button>
        </h1>
      </div>
    </div>
  );
};

export default Review;
