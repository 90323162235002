import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import LoanHeader from "./LoanHeader";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Loader from "../../components/loader";

import { toast } from "react-toastify";

import { getCookie } from "../../utils/cookie-helper";

import { LMS_PARTNER_CODE } from "../../constants";
import {
  getLoanEligibility,
  loanInstrumentation,
} from "../../services/lms-service";

import { getResponseKyc } from "../../services/kyc-service";

import { ACTIVE_KYC_ID } from "../../constants";

import "./loan-servicing.css";
import { DecryptData } from "../../utils/application-helper";

const LoanService = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState();
  const [credit, setCredit] = useState();
  const [transactions, setTransactions] = useState([]);
  const [name, setName] = useState();
  const [selfie, setSelfie] = useState();
  const [totalLoan, setTotalLoan] = useState();
  const [message, setMessage] = useState();

  const mobileNumber = DecryptData(getCookie("phone"));

  useEffect(() => {
    fetchCustomerStatus();
    fetchResponseValues();
  }, []);

  const fetchResponseValues = async () => {
    const customerKycParams = {
      kycId: ACTIVE_KYC_ID,
      msisdn: mobileNumber,
    };

    const { data, status, error } = await getResponseKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data) {
      const responseData = data.data;
      if (responseData !== undefined) {
        const apiResponses = responseData.data;
        setName({
          firstName: apiResponses.first_name,
          middleName: apiResponses.middle_name,
          lastName: apiResponses.last_name,
        });
        setSelfie(apiResponses.selfie);
      }
    }
  };

  const fetchCustomerStatus = async () => {
    const customerDetails = {
      msisdn: mobileNumber,
      product_type: "Installment",
    };

    const { status, error, data } = await getLoanEligibility(customerDetails);
    if (error) {
      console.log(error);
    } else if (status === 200) {
      if (data.status === "failed") {
        navigate("/");
        toast.error(data.message);
      } else if (data.code === 4001) {
        setMessage(data.message);
      } else {
        let responseData = data.data;
        setStatus(responseData.is_eligibe);
        setCredit(responseData.available_credit_limit);
      }
      fetchLoanInstrumentation();
    }
  };

  const fetchLoanInstrumentation = async () => {
    const customerDetails = {
      msisdn: mobileNumber,
      product_type: "Installment",
      filters: {
        status: "open",
        with_payment: true,
      },
    };
    const urlParams = {
      partner_code: LMS_PARTNER_CODE,
    };
    const { status, error, data } = await loanInstrumentation(
      urlParams,
      customerDetails
    );
    if (error) {
      console.log(error);
    } else if (status === 200) {
      if (data.status === "failed") {
        navigate("/");
        toast.error("Signature expired, please try again!");
      } else {
        let responseData = data.data.open_instruments;
        setTotalLoan(responseData.length);
        parseInstrumentsData(responseData);
      }
    }
  };

  const parseInstrumentsData = (responseData) => {
    let transactionArray = [];
    responseData.map((item) => {
      item.payments.map((receipt) => {
        transactionArray.push({
          action: receipt.action,
          number: receipt.receipt_number,
          id: receipt.receipt_id,
          date: receipt.date,
          amount: receipt.amount,
        });
      });
    });

    transactionArray.sort(function (a, b) {
      return b.id - a.id;
    });

    let slicedTransactions = transactionArray.slice(0, 5);

    setTransactions(slicedTransactions);

    setLoading(false);
  };

  const nextHandler = () => {
    navigate("/existing-loans");
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <Stepper currentStep={15} />
        <div className="content-container">
          <div className="common-heading">
            <h1>Loan Servicing</h1>
          </div>
          <LoanHeader
            status={status}
            credit={credit}
            name={name}
            selfie={selfie}
          />
          <div className="list-section-wrapper loan-interactions">
            <h4>{message ? message : "Your last 5 transactions"}</h4>
            <ol>
              {transactions.length === 0 ? (
                <p className="text-center">
                  {message ? "" : "You have zero transactions to show."}
                </p>
              ) : (
                ""
              )}
              {transactions.map((item) => {
                return (
                  <li key={item.id}>
                    {item.action === "repay"
                      ? `Repaid loan of Rs.${item.amount} ${
                          item.time !== null ? `on ${item.date}` : ""
                        }.` || item.action === "disburse"
                      : `Took a loan of Rs.${item.amount} ${
                          item.time !== null ? `on ${item.date}.` : ""
                        }`}
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
        <div className="button-section">
          <div>
            <button type="button" className="btn btn-outline-primary">
              Take a new loan
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={nextHandler}
              disabled={totalLoan === 0 ? true : false}
            >
              View existing loans ({totalLoan})
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanService;
