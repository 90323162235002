// All Backend API URLs.

import { attachParams } from "./helper";

// KYC APIs
export const KYC_BASE_URL = "https://kyc.yabx.co";

export const KYC_GET_KYC_DEFINITION = `${KYC_BASE_URL}/kyc2/get`;

export const KYC_SUBMIT = `${KYC_BASE_URL}/kyc2/kycFieldResponse`;

export const KYC_GET_ALL_RESPONSES = `${KYC_BASE_URL}/kyc2/kycResponses`;

export const GET_SINGLE_KYC_RESPONSE = `${KYC_BASE_URL}/kyc2/kycResponse`;

export const KYC_UPLOAD = `${KYC_BASE_URL}/kyc2/uploadField`;

export const KYC_RESPONSE_UPDATE = `${KYC_BASE_URL}/kyc2/kycResponseUpdate`;

export const KYC_ALL_RESPONSES = `${KYC_BASE_URL}/kyc2/kycResponseBody`;

// WorkFlow APIs
export const WORKFLOW_LMS_BASE_URL = "https://cashews-tz.yabx.co/api/workflow";

export const WORKFLOW_ALL_INTERACTIONS = `${WORKFLOW_LMS_BASE_URL}/interactions`;

export const WORKFLOW_INTERACTION_OUTCOME = `${WORKFLOW_LMS_BASE_URL}/interaction_outcome`;

export const WORKFLOW_INITIATE = `https://yabxdemo-in.yabx.co/apis/v1/customers/init_workflow`;

export const PROFILE_UPDATE = `https://yabxdemo-in.yabx.co/apis/v1/customers/profile_update`;

// LMS APIs
export const LMS_BASE_URL = "https://yabxdemo-in.yabx.co";

export const GET_ACCESS_TOKEN = `${LMS_BASE_URL}/apis/v1/auth/token`;

export const LOGIN = `${LMS_BASE_URL}/apis/v1/auth/login`;

export const GENERATE_OTP = `${LMS_BASE_URL}/apis/v1/otps/generate`;

export const RESEND_OTP = `${LMS_BASE_URL}/apis/v1/otps/resend`;

export const VERIFY_OTP = `${LMS_BASE_URL}/apis/v1/otps/authenciate_otp`;

export const LOAN_OFFERS = `${LMS_BASE_URL}/apis/v1/loans/offers`;

export const LOAN_INSTRUMENT_DETAILS = `${LMS_BASE_URL}/apis/v1/loans`;

export const LOAN_REPAYMENT = `${LMS_BASE_URL}/apis/v1/loans/repayment`;

export const LOAN_ELIGIBILITY = `${LMS_BASE_URL}/apis/v1/loans/eligibility`;

export const getKycDefinitionUrl = (params = null) => {
  let url = `${KYC_GET_KYC_DEFINITION}`;
  if (params) {
    return attachParams(url, params);
  }
};

export const getAllKycResponsesUrl = (params = null) => {
  let url = `${KYC_GET_ALL_RESPONSES}`;
  if (params) {
    return attachParams(url, params);
  }
};

export const getSingleKycResponseUrl = (params = null) => {
  let url = `${GET_SINGLE_KYC_RESPONSE}`;
  if (params) {
    return attachParams(url, params);
  }
};

export const getCustomerKycUrl = (params = null) => {
  let url = `${GET_SINGLE_KYC_RESPONSE}`;
  if (params) {
    return attachParams(url, params);
  }
};

export const getResponseKycUrl = (params = null) => {
  let url = `${KYC_ALL_RESPONSES}`;
  if (params) {
    return attachParams(url, params);
  }
};

export const workFlowAllTransactionsUrl = (params = null) => {
  let url = `${WORKFLOW_ALL_INTERACTIONS}`;
  if (params) {
    return attachParams(url, params);
  }
};

export const workFlowInteractionOutcomeUrl = (params = null) => {
  let url = `${WORKFLOW_INTERACTION_OUTCOME}`;
  if (params) {
    return attachParams(url, params);
  }
};

export const getLoanOffersUrl = (params = null) => {
  let url = `${LOAN_OFFERS}`;
  if (params) {
    return attachParams(url, params);
  }
};

export const getLoanInstrumentationDetailsUrl = (params = null) => {
  let url = `${LOAN_INSTRUMENT_DETAILS}`;
  if (params) {
    return attachParams(url, params);
  }
};

export const getRepaymentUrl = (params = null) => {
  let url = `${LOAN_REPAYMENT}`;
  if (params) {
    return attachParams(url, params);
  }
};
