import React from "react";

const AuthRadio = ({
  fieldId,
  fieldName,
  display,
  mandatory,
  handleChange,
  formValues,
  options,
  fieldPage,
}) => {
  return (
    <div key={fieldId}>
      <label>
        {display} <span className="red">{mandatory === true ? "*" : ""}</span>
      </label>
      <ul className="customer-app row">
        {options &&
          options.map((item) => {
            return (
              <li
                key={item.value}
                className={fieldPage === "home" ? "col-md-12" : "col-md-6"}
              >
                <input
                  type="radio"
                  id={item.value}
                  value={item.value}
                  name={fieldName}
                  checked={item.value === formValues[fieldName]}
                  onChange={handleChange}
                />
                <label htmlFor={item.value}>{item.display}</label>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default AuthRadio;
