import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Login from "../containers/login";
import OtpVerify from "../containers/login/otpVerify";
import ResumeJourney from "../containers/login/resume-journey";
import Auth from "../containers/login/auth";
import Home from "../containers/home";
import CustomerApp from "../containers/customerApp";
import ConsumerLoanSimulator from "../containers/consumerLoanSimulator";
import LoanSimulator from "../containers/loanSimulator";
import PersonalInfo from "../containers/personalDetails";
import AddressInfo from "../containers/address";
import Document from "../containers/documents";
import LoanInfo from "../containers/loan";
import PreviousLoan from "../containers/previousLoanDetails";
import Summary from "../containers/summary";
import MandateInfo from "../containers/mandate";
import AdditionalInfo from "../containers/additionalInfo";
import BusinessInfo from "../containers/businessInfo";
import PersonalDiscussionInfo from "../containers/personalDiscussion";
import PersonalFinanceInfo from "../containers/personalFinanceInfo";
import LifeStyle from "../containers/lifeStyle";
import LoanAgreement from "../containers/loanAgreement";
import LoanRejected from "../containers/loanRejected";
import SuccessScreen from "../containers/success";
import Thankyou from "../containers/thankyou";
import Failed from "../containers/failed";
import Review from "../containers/review";
import LoanService from "../containers/loanServicing";
import ExistingLoans from "../containers/loanServicing/existingLoans";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../containers/dashboard";

// import Test from "../containers/documents/test";
// import Record from "../containers/documents/record";

const AppRoutes = () => (
  <Router>
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/otp-verify" element={<OtpVerify />} />
      <Route exact path="/:reinitiate" element={<ResumeJourney />} />
      <Route exact path="/login" element={<Auth />} />
      <Route exact path="/home" element={<PrivateRoute Component={Home} />} />
      <Route
        exact
        path="/customer-app"
        element={<PrivateRoute Component={CustomerApp} />}
      />
      <Route
        exact
        path="/consumer-loan-simulator"
        element={<PrivateRoute Component={ConsumerLoanSimulator} />}
      />
      <Route
        exact
        path="/personal-loan-simulator"
        element={<PrivateRoute Component={LoanSimulator} />}
      />
      <Route
        exact
        path="/personal-info"
        element={<PrivateRoute Component={PersonalInfo} />}
      />
      <Route
        exact
        path="/address"
        element={<PrivateRoute Component={AddressInfo} />}
      />
      <Route
        exact
        path="/documents"
        element={<PrivateRoute Component={Document} />}
      />
      <Route
        exact
        path="/loan-info"
        element={<PrivateRoute Component={LoanInfo} />}
      />
      <Route
        exact
        path="/previous-loan-details"
        element={<PrivateRoute Component={PreviousLoan} />}
      />
      <Route
        exact
        path="/summary"
        element={<PrivateRoute Component={Summary} />}
      />
      <Route
        exact
        path="/mandate"
        element={<PrivateRoute Component={MandateInfo} />}
      />
      <Route
        exact
        path="/additional-info"
        element={<PrivateRoute Component={AdditionalInfo} />}
      />
      <Route
        exact
        path="/personal-discussion"
        element={<PrivateRoute Component={PersonalDiscussionInfo} />}
      />
      <Route
        exact
        path="/business-info"
        element={<PrivateRoute Component={BusinessInfo} />}
      />
      <Route
        exact
        path="/personal-income"
        element={<PrivateRoute Component={PersonalFinanceInfo} />}
      />
      <Route
        exact
        path="/lifestyle-details"
        element={<PrivateRoute Component={LifeStyle} />}
      />
      <Route
        exact
        path="/loan-agreement"
        element={<PrivateRoute Component={LoanAgreement} />}
      />
      <Route
        exact
        path="/loan-service"
        element={<PrivateRoute Component={LoanService} />}
      />
      <Route
        exact
        path="/existing-loans"
        element={<PrivateRoute Component={ExistingLoans} />}
      />
      <Route
        exact
        path="/loan-rejected"
        element={<PrivateRoute Component={LoanRejected} />}
      />
      <Route
        exact
        path="/success"
        element={<PrivateRoute Component={SuccessScreen} />}
      />
      <Route
        exact
        path="/thankyou"
        element={<PrivateRoute Component={Thankyou} />}
      />
      <Route
        exact
        path="/failed"
        element={<PrivateRoute Component={Failed} />}
      />
      <Route
        exact
        path="/review"
        element={<PrivateRoute Component={Review} />}
      />
      <Route exact path="/dashboard" element={<Dashboard />} />

      {/* <Route exact path="/test" element={<Test />} />
      <Route exact path="/record" element={<Record />} /> */}
    </Routes>
    <ToastContainer position="top-center" autoClose={2000} />
  </Router>
);

export default AppRoutes;
