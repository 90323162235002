// WorkFlow Platform API services.

import { LMS_OS, LMS_PACKAGE_ID, LMS_PARTNER_CODE } from "../constants";
import { getRequest, postRequest, putRequest } from "../utils/http-helper";
import {
  workFlowAllTransactionsUrl,
  workFlowInteractionOutcomeUrl,
  WORKFLOW_INITIATE,
  PROFILE_UPDATE,
} from "./urls";

const lmsStandardHeaders = {
  "partner-code": LMS_PARTNER_CODE,
  os: LMS_OS,
  "package-id": LMS_PACKAGE_ID,
};

export const getworkFlowAllTransactions = async (uuidparams) => {
  let url = workFlowAllTransactionsUrl(uuidparams);
  return await getRequest({
    url: url,
    noAuth: true,
  });
};

export const getworkFlowInteractionOutcome = async (urlParameters, data) => {
  let url = workFlowInteractionOutcomeUrl(urlParameters);
  return await putRequest({
    url: url,
    noAuth: true,
    data: data,
  });
};

export const initiateWorkFlow = async (data) => {
  return await postRequest({
    url: WORKFLOW_INITIATE,
    data: data,
    headers: lmsStandardHeaders,
  });
};

export const profileUpdate = async (data) => {
  return await postRequest({
    url: PROFILE_UPDATE,
    data: data,
    headers: lmsStandardHeaders,
  });
};
