// LMS Platform API services.

import { LMS_OS, LMS_PACKAGE_ID, LMS_PARTNER_CODE } from "../constants";
import { postRequest, getRequest } from "../utils/http-helper";
import {
  GENERATE_OTP,
  GET_ACCESS_TOKEN,
  RESEND_OTP,
  VERIFY_OTP,
  LOGIN,
  LOAN_OFFERS,
  getLoanInstrumentationDetailsUrl,
  getRepaymentUrl,
  LOAN_ELIGIBILITY,
} from "./urls";

const lmsStandardHeaders = {
  "partner-code": LMS_PARTNER_CODE,
  os: LMS_OS,
  "package-id": LMS_PACKAGE_ID,
};

export const getAccessToken = async (data) => {
  return await postRequest({
    url: GET_ACCESS_TOKEN,
    data: data,
    noAuth: true,
    headers: lmsStandardHeaders,
  });
};

export const generateOtp = async (data) => {
  return await postRequest({
    url: GENERATE_OTP,
    data: data,
    headers: lmsStandardHeaders,
  });
};

export const resendOtp = async (data) => {
  return await postRequest({
    url: RESEND_OTP,
    data: data,
    headers: lmsStandardHeaders,
  });
};

export const verifyOtp = async (data) => {
  return await postRequest({
    url: VERIFY_OTP,
    data: data,
    headers: lmsStandardHeaders,
  });
};

export const login = async (data) => {
  return await postRequest({
    url: LOGIN,
    data: data,
    headers: lmsStandardHeaders,
  });
};

export const getLoanOffers = async (data) => {
  return await getRequest({
    url: LOAN_OFFERS,
    params: data,
    headers: {
      "Content-Type": "application/json",
      ...lmsStandardHeaders,
    },
  });
};

export const loanInstrumentation = async (urlParams, data) => {
  let url = getLoanInstrumentationDetailsUrl(urlParams);
  return await postRequest({
    url: url,
    data: data,
    headers: lmsStandardHeaders,
  });
};

export const loanRepayment = async (urlParams, data) => {
  let url = getRepaymentUrl(urlParams);
  return await postRequest({
    url: url,
    data: data,
    headers: lmsStandardHeaders,
  });
};

export const getLoanEligibility = async (data) => {
  return await getRequest({
    url: LOAN_ELIGIBILITY,
    params: data,
    headers: {
      "Content-Type": "application/json",
      ...lmsStandardHeaders,
    },
  });
};
