import React from "react";

import moment from "moment";

const DateInput = ({
  fieldName,
  display,
  mandatory,
  showError,
  handleChange,
  formValues,
}) => {
  return (
    <div>
      <label>
        {display} <span className="red">{mandatory === true ? "*" : ""}</span>
      </label>
      <input
        type="date"
        name={fieldName}
        className={
          showError[fieldName] ? "form-control border-red" : "form-control"
        }
        value={formValues[fieldName] ? formValues[fieldName] : ""}
        onChange={handleChange}
        max={moment().format("YYYY-MM-DD")}
      />
    </div>
  );
};

export default DateInput;
