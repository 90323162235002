import React from "react";

import { LOAN_STEPPER } from "../../constants";

import notes from "../../assets/images/tick.svg";
import "./stepper.css";

const Stepper = (props) => {
  return (
    <div>
      {/* for web */}
      <div className="steps-wrapper web-version">
        <div
          className={
            props.currentStep > LOAN_STEPPER.length / 2
              ? "auto-scroll scroll-left"
              : "auto-scroll"
          }
        >
          {LOAN_STEPPER.map((eachStep) => {
            return (
              <div
                key={eachStep.stepNumber}
                className={`steps ${
                  eachStep.stepNumber === props.currentStep
                    ? "active"
                    : eachStep.stepNumber < props.currentStep
                    ? "done"
                    : ""
                }`}
              >
                <h1>
                  <label>{eachStep.stepNumber}</label>
                  <img src={notes} alt="notes" />
                </h1>
                <span>{eachStep.name}</span>
              </div>
            );
          })}
        </div>
      </div>
      {/* for mobile */}
      <div className="steps-wrapper mobile-version">
        {LOAN_STEPPER.map((eachStep) => {
          return (
            <div
              key={eachStep.stepNumber}
              className={
                eachStep.stepNumber === props.currentStep
                  ? "mobile-stepper active-tab"
                  : "mobile-stepper"
              }
            >
              <div className="mob-steps">
                <h3>{eachStep.name}</h3>
                <span>
                  {eachStep.stepNumber}/{LOAN_STEPPER.length}
                </span>
              </div>
              <div className="stepper-progress-grey">
                <div
                  className="progress-blue"
                  style={{
                    width: `${
                      (eachStep.stepNumber / LOAN_STEPPER.length) * 100
                    }%`,
                  }}
                ></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Stepper;
