import React from "react";

const Address = ({ addressDetails, pdsDetails, addressPackageTitle }) => {
  return (
    <div className="list-section-wrapper overall-summary-screen">
      <div className="summary-title">
        <label>{addressPackageTitle}</label>
      </div>
      <ul>
        {
          <ul>
            {addressDetails.map((field) => {
              switch (field.fieldDisplayName) {
                case "Pin Code":
                  return (
                    <li key={field.fieldId}>
                      <label>{field.fieldDisplayName}</label>
                      <span>
                        {pdsDetails.pincode === "" ||
                        pdsDetails.pincode === undefined
                          ? "-"
                          : pdsDetails.pincode}
                      </span>
                    </li>
                  );
                case "District":
                  return (
                    <li key={field.fieldId}>
                      <label>{field.fieldDisplayName}</label>
                      <span>
                        {pdsDetails.district === "" ||
                        pdsDetails.district === undefined
                          ? "-"
                          : pdsDetails.district}
                      </span>
                    </li>
                  );
                case "State":
                  return (
                    <li key={field.fieldId}>
                      <label>{field.fieldDisplayName}</label>
                      <span>
                        {pdsDetails.state === "" ||
                        pdsDetails.state === undefined
                          ? "-"
                          : pdsDetails.state}
                      </span>
                    </li>
                  );

                default:
                  return (
                    <li key={field.fieldId}>
                      <label>{field.fieldDisplayName}</label>
                      <span>
                        {field.responseValue === "" ||
                        field.responseValue === undefined
                          ? "-"
                          : field.responseValue}
                      </span>
                    </li>
                  );
              }
            })}
          </ul>
        }
      </ul>
    </div>
  );
};
export default Address;
