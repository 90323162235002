import React from "react";

const FWRadio = ({
  fieldName,
  display,
  mandatory,
  showError,
  handleChange,
  formValues,
  options,
}) => {
  return (
    <div className="loan-purpose">
      <h4>
        {display}
        <span className="red">{mandatory ? "*" : ""}</span>
      </h4>
      <select
        className={
          showError[fieldName] ? "form-select border-red" : "form-select"
        }
        name={fieldName}
        onChange={handleChange}
        value={formValues[fieldName] ? formValues[fieldName] : ""}
      >
        {" "}
        <option value="">Choose</option>
        {options &&
          options.map((item) => {
            return (
              <option key={item.value} value={item.value}>
                {item.display}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default FWRadio;
