import React from "react";

const TenureRadio = ({
  fieldName,
  display,
  mandatory,
  handleChange,
  formValues,
  options,
}) => {
  return (
    <div className="at-wrapper" key={fieldName}>
      <div className="at-top">
        <label>
          {display} <span className="red">{mandatory === true ? "*" : ""}</span>
        </label>
      </div>
      <div className="at-center">
        <ul onChange={handleChange}>
          {options.map((item, index) => {
            return (
              <li key={index}>
                <input
                  type="radio"
                  className="form-check-input"
                  id={item.tenure}
                  name={fieldName}
                  value={item.tenure}
                  checked={item.tenure === Number(formValues[fieldName])}
                />
                <label className="form-check-label" htmlFor={item.tenure}>
                  Pay ₹&ensp;&nbsp;{item.emi} over {item.variantName}
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default TenureRadio;
