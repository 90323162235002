import React from "react";

import { KYC_ACCESS_TOKEN } from "../../constants";

const GroupImageThumb = ({ image, groupIndex }) => {
  if (groupIndex === "jpg" || groupIndex === "png" || groupIndex === "jpeg") {
    return (
      <img
        src={`https://kyc.yabx.co/kyc2/getDocument?fileName=${image}&Authorization=Bearer${KYC_ACCESS_TOKEN}`}
      />
    );
  } else {
    return (
      <iframe
        className="scroll"
        src={`https://kyc.yabx.co/kyc2/getDocument?fileName=${image}&Authorization=Bearer${KYC_ACCESS_TOKEN}`}
      ></iframe>
    );
  }
};

export default GroupImageThumb;
