import React from 'react';
import './header.css';
import logo from '../../assets/images/logo.svg'; 

const Header = () => {
  return (
    <div className="header-section">
      <div className="header-logo">
        <img src={logo} alt='logo' />
      </div>
    </div>
  );
};

export default Header;
