import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import "./login.css";

import logo from "../../assets/images/logo.svg";
import eye from "../../assets/images/eye.svg";
import eyeSlash from "../../assets/images/eye-slash.svg";

import Error from "../../components/error";

import { login } from "../../services/lms-service";
import { getAccessToken } from "../../services/lms-service";

import { LMS_CLIENT_SECRET, MOBILE_NUMBER_REGEX } from "../../constants";
import { saveCookie, deleteCookie } from "../../utils/cookie-helper";
import { EncryptData } from "../../utils/application-helper";

const Auth = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState({
    email: false,
    password: false,
    msisdn: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    msisdn: "",
  });

  useEffect(() => {
    fetchAccessToken();
  }, []);

  const fetchAccessToken = () => {
    const accessTokenParams = {
      client_secret: LMS_CLIENT_SECRET,
    };

    getAccessToken(accessTokenParams).then((response) => {
      if (response.data != undefined && response.data.status == "success") {
        saveCookie("lms_token", EncryptData(response.data.access_token));
      } else {
        toast.error("Failed fetching access token");
        toast.error(response.message);
      }
    });
  };

  deleteCookie("auth_token");
  deleteCookie("phone");
  deleteCookie("workflow_id");

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    setShowError(false);
    setErrorMessage("");
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (formValues.msisdn === "") {
      setShowError({ ...showError, msisdn: true });
      setErrorMessage("Customer mobile number Required");
    } else if (!MOBILE_NUMBER_REGEX.test(formValues.msisdn)) {
      setShowError(true);
      setErrorMessage("Please enter valid mobile number");
    } else if (formValues.email === "") {
      setShowError(true);
      setErrorMessage("Email Required");
    } else if (formValues.password === "") {
      setShowError(true);
      setErrorMessage("Password Required");
    } else {
      auth();
    }
  };

  const auth = async () => {
    let bodyData = {
      ...formValues,
      client_secret: LMS_CLIENT_SECRET,
    };
    const { status, data, error } = await login(bodyData);
    if (error) {
      toast.error(error.message);
    } else if (status === 200 && data) {
      if (data != undefined && data.status == "success") {
        let encryptedMobileNumber = EncryptData(formValues.msisdn);
        let params = {
          journey_type: "resume_journey",
          msisdn: encryptedMobileNumber,
        };
        let journeyUrl = new URLSearchParams(params).toString();
        var date = new Date();
        date.setTime(date.getTime() + 10 * 60 * 1000);
        saveCookie("auth_token", EncryptData(data.access_token), {
          expires: date,
        });
        toast.success("Login successful");
        saveCookie("phone", encryptedMobileNumber);
        navigate(`/${journeyUrl}`);
      } else if (data !== undefined && data.code === 4001) {
        toast.error(data.message);
      }
    }
  };

  return (
    <form className="auth-page-wrapper">
      <div className="auth-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="auth-wrapper">
        <h1>Log in</h1>
        <div className="login-wrapper">
          <div>
            <label>Mobile Number</label>
            <input
              type="text"
              className={
                showError.msisdn ? "form-control border-red" : "form-control"
              }
              value={formValues.msisdn}
              name="msisdn"
              onChange={handleChange}
              placeholder="Enter customer mobile number"
            />
          </div>
          <div>
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={formValues.email}
              name="email"
              onChange={handleChange}
              placeholder="Enter your email ID"
            />
          </div>
          <div>
            <label>Password</label>
            <div>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                value={formValues.password}
                name="password"
                onChange={handleChange}
                placeholder="Enter your password"
              />
              <span className="eye-toggle" onClick={togglePassword}>
                <img
                  src={showPassword ? eye : eyeSlash}
                  alt="eye-toggle-icon"
                />
              </span>
            </div>
          </div>
        </div>
        {errorMessage && <Error message={errorMessage} />}
        <button
          type="submit"
          className="btn btn-primary"
          onClick={(e) => submitHandler(e)}
          disabled={
            formValues.msisdn !== "" &&
            formValues.email !== "" &&
            formValues.password !== ""
              ? false
              : true
          }
        >
          Login
        </button>
      </div>
    </form>
  );
};

export default Auth;
