import React from "react";
import { KYC_ACCESS_TOKEN } from "../../constants";

const ImageThumb = ({ image }) => {
  let ext = image.split(".").pop();
  if (ext === "jpg" || ext === "png" || ext === "jpeg") {
    return (
      <img
        src={`https://kyc.yabx.co/kyc2/getDocument?fileName=${image}&Authorization=Bearer${KYC_ACCESS_TOKEN}`}
      />
    );
  } else {
    return (
      <iframe
        src={`https://kyc.yabx.co/kyc2/getDocument?fileName=${image}&Authorization=Bearer${KYC_ACCESS_TOKEN}`}
      ></iframe>
    );
  }
};

export default ImageThumb;
