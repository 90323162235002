import React from "react";
import placeholder from "../../assets/images/image_placeholder.svg";
import GroupImageThumb from "./GroupImageThumb";

const DocumentFileGroup = ({
  fieldId,
  fieldName,
  display,
  mandatory,
  gpValues,
  gpTempShow,
  gpTempShowView,
  gpTempExt,
  gpError,
  handleGroupFileChange,
  gpIndex,
}) => {
  return (
    <>
      <div className="document-view-wrapper" key={fieldId}>
        <div className="document-options">
          <h3>
            {display}
            <span className="red">{mandatory ? "*" : ""}</span>
          </h3>
          {gpTempShowView[fieldName][gpIndex] && (
            <div>
              <input
                type="file"
                id={fieldName + gpIndex}
                onChange={(e) => {
                  handleGroupFileChange(gpIndex, fieldName, e.target.files);
                }}
                capture="environment"
                hidden
              />
              <label htmlFor={fieldName + gpIndex}>Change</label>
            </div>
          )}
        </div>

        {gpTempShow[fieldName][gpIndex] && (
          <div className="image-label">
            <input
              type="file"
              id={fieldName + gpIndex}
              onChange={(e) => {
                handleGroupFileChange(gpIndex, fieldName, e.target.files);
              }}
              capture="environment"
              hidden
            />
            <label htmlFor={fieldName + gpIndex}>
              <div className="before-upload-wrapper">
                <div
                  className={
                    gpError[fieldName][gpIndex]
                      ? "before-upload-view border-red"
                      : "before-upload-view"
                  }
                >
                  <img src={placeholder} alt="placeholder" />
                  <h3>browse file</h3>
                  <p>Only .jpg and .png files. 500kb max file size.</p>
                </div>
              </div>
            </label>
          </div>
        )}

        {gpTempShowView[fieldName][gpIndex] && (
          <div
            className={
              gpError[fieldName][gpIndex]
                ? "after-upload-view border-red"
                : "after-upload-view"
            }
          >
            <div className="upload-view">
              {gpValues[fieldName][gpIndex] && (
                <GroupImageThumb
                  image={gpValues[fieldName][gpIndex]}
                  groupIndex={gpTempExt[fieldName][gpIndex]}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DocumentFileGroup;
