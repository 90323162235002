import React, { useState } from "react";

import { toast } from "react-toastify";

import PersonalFinanceInformation from "./PersonalFinanceInfo";
import PersonalFinanceInformationEdit from "./PersonalFinanceInfoEdit";

const PersonalFinanceInfo = () => {
  const [showDetails, setShowDetails] = useState(true);
  const [showEdit, setShowEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});

  const editHandler = () => {
    setShowEdit(true);
    setShowDetails(false);
  };

  const discardChangesHandler = () => {
    setShowDetails(true);
    setShowEdit(false);
  };

  const updateHandler = (formValues) => {
    setShowDetails(true);
    setShowEdit(false);
    setUpdatedValues(formValues);
    toast.success("Personal Finance info updated successfully");
  };

  return (
    <div>
      {showDetails && (
        <PersonalFinanceInformation
          editHandler={editHandler}
          updatedValues={updatedValues}
        />
      )}
      {showEdit && (
        <PersonalFinanceInformationEdit
          discardChangesHandler={discardChangesHandler}
          updateHandler={updateHandler}
        />
      )}
    </div>
  );
};

export default PersonalFinanceInfo;
