import React, { useState, useEffect } from "react";

import {
  getKycDefinition,
  getCustomerKyc,
  submitKyc,
} from "../../services/kyc-service";

import { getCookie } from "../../utils/cookie-helper";

import { getworkFlowAllTransactions } from "../../services/workflow-service";

import { ACTIVE_KYC_ID_MANDATE } from "../../constants";

import { toast } from "react-toastify";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Error from "../../components/error";
import Loader from "../../components/loader";
import "./mandate.css";

import RangeSlider from "../../components/rangeSlider";
import Input from "../../components/input";
import DateInput from "../../components/dateInput";
import Select from "../../components/select";
import NumberInput from "../../components/numberInput";
import { DecryptData } from "../../utils/application-helper";

const MandateEditDetails = (props) => {
  const [formValues, setFormValues] = useState({});
  const [showError, setShowError] = useState({});
  const [infoDefinition, setInfoDefinition] = useState([]);
  const [versionData, setVersionData] = useState(0);
  const [customerId, setCustomerId] = useState("");
  const [minimumAmount, setMinimumAmount] = useState();
  const [packageTitle, setPackageTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [submitDefinitions, setSubmitDefinitions] = useState([]);
  const [updatedRemarksData, setUpdatedRemarksData] = useState([]);
  const [previousValues, setPreviousValues] = useState({});
  const [isReadMore, setIsReadMore] = useState({});

  const params = {
    id: ACTIVE_KYC_ID_MANDATE,
  };

  const mobileNumber = DecryptData(getCookie("phone"));

  let STEP_AMOUNT = 1;
  let MIN_AMOUNT = minimumAmount;
  let MAX_AMOUNT = minimumAmount * 3;

  const handleChange = (e) => {
    setErrMsg();
    let { name, value } = e.target;
    if (minimumAmount !== undefined && name === "mandate_amount") {
      let updatedValue = Number(value);

      if (updatedValue < MIN_AMOUNT) {
        updatedValue = MIN_AMOUNT;
      }
      if (updatedValue > MAX_AMOUNT) {
        updatedValue = MAX_AMOUNT;
      }

      setFormValues({ ...formValues, [name]: [updatedValue] });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    setShowError({
      ...showError,
      [name]: false,
    });
  };

  const toggleReadMore = (fieldName) => {
    setIsReadMore({ ...isReadMore, [fieldName]: !isReadMore[fieldName] });
  };

  const submitHandler = () => {
    let message;
    let errCount = 0;
    for (let i = 0; i < submitDefinitions.length; i++) {
      let testRegEx;
      let element = submitDefinitions[i];
      const {
        fieldName,
        fieldDisplayName,
        mandatory,
        validation,
        displayType,
      } = element;
      if (validation !== undefined) {
        testRegEx = new RegExp(validation);
      }

      if (
        (mandatory && !formValues[fieldName] && displayType !== "SLIDER") ||
        (mandatory &&
          displayType === "SLIDER" &&
          formValues[fieldName][0] === 0)
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} required!`;
        break;
      } else if (
        mandatory &&
        validation !== undefined &&
        !testRegEx.test(formValues[fieldName])
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} not matching!`;
        break;
      } else if (
        (updatedRemarksData[fieldName] !== undefined &&
          displayType !== "SLIDER" &&
          previousValues[fieldName] === formValues[fieldName]) ||
        (updatedRemarksData[fieldName] !== undefined &&
          displayType === "SLIDER" &&
          previousValues[fieldName][0] === formValues[fieldName][0])
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `Please provide another ${fieldDisplayName}`;
        break;
      }
    }
    setErrMsg(message);
    if (errCount === 0) {
      createOrUpdateKyc();
    }
  };

  useEffect(() => {
    fetchKycDefinition();
  }, []);

  const fetchAllWorkFlowActions = async (formValues) => {
    const uuidparams = {
      uuid: DecryptData(getCookie("workflow_id")),
    };

    const { data, status, error } = await getworkFlowAllTransactions(
      uuidparams
    );
    if (error) {
      console.log(error);
    } else if (status == 200 && data) {
      let count = 0;
      let availablePayload = Object.entries(data.actions[0].payload);
      availablePayload.forEach((element) => {
        if (element[0] === "available_credit_limit") {
          count = count + 1;
        }
      });
      switch (count) {
        case 1:
          setMinimumAmount(+data.actions[0].payload.available_credit_limit);
          if (formValues["mandate_amount"][0] === 0) {
            formValues["mandate_amount"] = [
              +data.actions[0].payload.available_credit_limit,
            ];
          }

          break;
        case 0:
          setMinimumAmount(+data.actions[0].payload.credit_limit);
          if (formValues["mandate_amount"][0] === 0) {
            formValues["mandate_amount"] = [
              +data.actions[0].payload.credit_limit,
            ];
          }

          break;
        default:
          break;
      }
      setFormValues(formValues);
      setPreviousValues(formValues);
      setLoading(false);
    }
  };

  const fetchKycDefinition = async () => {
    const { data, status, error } = await getKycDefinition(params);
    if (error) {
      console.log(error);
    } else if (status === 200 && data) {
      const { data, status, error } = await getKycDefinition(params);
      if (error) {
        console.log(error);
      } else if (status === 200 && data) {
        const responseKycDefinitionData = data.data;

        let infoDefinition = [];

        let submitDefinitions = [];

        let showError = {};

        let formValues = {};

        const packageId = responseKycDefinitionData.packagesDTOs.find(
          (packageDef) => packageDef.id === "61e8fc0e944c74494b831168"
        );

        const packageDTOs = packageId.children;
        setPackageTitle(packageId.packageTitle);

        packageDTOs.map((personDefinition) => {
          if (personDefinition.fieldName !== "time_spent_on_mandate") {
            infoDefinition.push({
              fieldName: personDefinition.fieldName,
              fieldDisplayName: personDefinition.fieldDisplayName,
              editable: personDefinition.editable,
              mandatory: personDefinition.mandatory,
              type: personDefinition.type,
              options: personDefinition.options,
              multiQuestions: personDefinition.multiQuestions,
              validation: personDefinition.validation,
              displayType: personDefinition.displayType,
            });
            showError[personDefinition.fieldName] = false;
            if (
              personDefinition.type === "NUMBER" &&
              personDefinition.displayType === "SLIDER"
            ) {
              formValues[personDefinition.fieldName] = [0];
            } else {
              formValues[personDefinition.fieldName] = undefined;
            }
          }
        });

        packageDTOs.map((personDefinition) => {
          if (personDefinition.fieldName !== "time_spent_on_mandate") {
            submitDefinitions.push({
              fieldName: personDefinition.fieldName,
              mandatory: personDefinition.mandatory,
              displayType: personDefinition.displayType,
              validation: personDefinition.validation,
            });
          }
        });

        setShowError(showError);

        setInfoDefinition(infoDefinition);
        setVersionData(responseKycDefinitionData.version);
        setSubmitDefinitions(submitDefinitions);
        fetchCustomerKyc(formValues);
      }
    }
  };

  const fetchCustomerKyc = async (formValues) => {
    const customerKycParams = {
      msisdn: mobileNumber,
      kycId: ACTIVE_KYC_ID_MANDATE,
    };

    const { data, status, error } = await getCustomerKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data) {
      if (data.statusCode !== "404") {
        const responseData = data.data;
        if (responseData !== undefined) {
          setCustomerId(responseData.id);
          parseCustomerData(responseData, formValues);
        }
      } else {
        fetchAllWorkFlowActions(formValues);
      }
    }
  };

  const parseCustomerData = (responseData, formValues) => {
    let remarksData = {};
    let isReadMore = {};

    const packageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "61e8fc0e944c74494b831168"
    );

    packageId.children.map((element) => {
      let { displayType, responseValue, remarks, fieldName } = element;
      if (displayType === "SLIDER" && responseValue !== "") {
        formValues[fieldName][0] = responseValue;
      } else if (displayType !== "SLIDER") {
        formValues[fieldName] = responseValue;
      }
      remarksData[fieldName] = remarks;
      if (element.remarks !== undefined && element.remarks.length > 45) {
        isReadMore[element.fieldName] = true;
      } else {
        isReadMore[element.fieldName] = false;
      }
    });

    setUpdatedRemarksData(remarksData);
    fetchAllWorkFlowActions(formValues);
    setIsReadMore(isReadMore);
  };

  const createOrUpdateKyc = async () => {
    let bodyData = {
      kycId: ACTIVE_KYC_ID_MANDATE,
      id: customerId,
      created_by: DecryptData(getCookie("phone")),
      data: {},
      version: versionData,
    };

    submitDefinitions.map((field) => {
      const { fieldName, displayType } = field;
      if (displayType === "SLIDER") {
        bodyData["data"][fieldName] = formValues[fieldName][0];
      } else {
        bodyData["data"][fieldName] = formValues[fieldName];
      }
    });

    const { status, data, error } = await submitKyc(bodyData);
    if (error) {
      toast.error(error.message);
    } else if (status === 200 && data) {
      props.updateHandler(formValues);
    }
  };

  const handleSliderChange = (fieldName, value) => {
    setErrMsg();
    setFormValues({ ...formValues, [fieldName]: value });
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <Stepper currentStep={12} />
        <div className="content-container">
          <div className="common-heading">
            <h1>{packageTitle}</h1>
          </div>
          <div className="list-section-wrapper  mandate-edit-screen">
            <ul>
              {infoDefinition.map((formField, index) => {
                switch (true) {
                  case formField.type === "TEXT" &&
                    formField.displayType === undefined:
                    return (
                      <li key={index}>
                        <Input
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );
                  case formField.type === "CALENDAR" &&
                    formField.displayType === undefined:
                    return (
                      <li key={index}>
                        <DateInput
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );
                  case formField.type === "RADIO" &&
                    formField.displayType === undefined:
                    return (
                      <li key={index}>
                        <Select
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                          options={formField.options}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );
                  case formField.type === "NUMBER" &&
                    formField.displayType === undefined &&
                    formField.fieldName !== "mandate_amount":
                    return (
                      <li key={index}>
                        <NumberInput
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                          options={formField.options}
                          editable={formField.editable}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );

                  default:
                    break;
                }
              })}
            </ul>
          </div>
          <div className="range-slider-wrapper loan-simulator-screen">
            {infoDefinition.map((formField, index) => {
              switch (true) {
                case formField.type === "NUMBER" &&
                  formField.displayType === "SLIDER":
                  return (
                    <React.Fragment key={index}>
                      <RangeSlider
                        fieldName={formField.fieldName}
                        display={formField.fieldDisplayName}
                        mandatory={formField.mandatory}
                        formValues={formValues}
                        showError={showError}
                        handleSliderChange={handleSliderChange}
                        handleChange={handleChange}
                        MIN_VALUE={minimumAmount && MIN_AMOUNT}
                        MAX_VALUE={minimumAmount && MAX_AMOUNT}
                        STEP_VALUE={STEP_AMOUNT}
                      />
                      {updatedRemarksData[formField.fieldName] && (
                        <p className="remarks">
                          {isReadMore[formField.fieldName]
                            ? updatedRemarksData[formField.fieldName].slice(
                                0,
                                45
                              )
                            : updatedRemarksData[formField.fieldName]}
                          {updatedRemarksData[formField.fieldName].length >
                            45 && (
                            <span
                              onClick={() =>
                                toggleReadMore(formField.fieldName)
                              }
                            >
                              {isReadMore[formField.fieldName]
                                ? "...Read more"
                                : " Show less"}
                            </span>
                          )}
                        </p>
                      )}
                    </React.Fragment>
                  );

                default:
                  break;
              }
            })}
          </div>
          {errMsg && <Error message={errMsg} />}
        </div>
        <div className="button-section">
          <div>
            <button
              type="button"
              onClick={props.discardChangesHandler}
              className="btn btn-outline-primary"
            >
              Discard change
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={submitHandler}
              className="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MandateEditDetails;
