import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { getCookie } from "../../utils/cookie-helper";

import {
  getworkFlowAllTransactions,
  getworkFlowInteractionOutcome,
} from "../../services/workflow-service";

import { getCustomerKyc } from "../../services/kyc-service";

import { ACTIVE_KYC_ID } from "../../constants";

import { toast } from "react-toastify";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Loader from "../../components/loader";
import LoanSummary from "./loanSummary";
import PersonalDetails from "./personalDetails";
import Address from "./address";

import "./summary.css";
import { DecryptData, getNextActionPage } from "../../utils/application-helper";
import { getLoanOffers } from "../../services/lms-service";

const Summary = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [agree, setAgree] = useState(true);
  const [personalDetails, setPersonalDetails] = useState([]);
  const [addressDetails, setAddressDetails] = useState([]);
  const [pdsDetails, setPdsDetails] = useState([]);
  const [loanPurpose, setLoanPurpose] = useState("");
  const [applicationFee, setApplicationFee] = useState();
  const [workFlowActionId, setWorkFlowActionId] = useState("");
  const [requestedLoanAmount, setRequestedLoanAmount] = useState(0);
  const [tenureMonths, setTenureMonths] = useState();
  const [totalInterest, setTotalInterest] = useState();
  const [emi, setEmi] = useState();
  const [interestRate, setInterestRate] = useState();
  const [personalPackageTitle, setPersonalPackageTitle] = useState("");
  const [addressPackageTitle, setAddressPackageTitle] = useState("");

  const mobileNumber = DecryptData(getCookie("phone"));

  useEffect(() => {
    getAllResponseData();
    fetchCustomerKyc();
  }, []);

  const parseData = (responseData) => {
    let newData = [];
    let pincodeDistrictStateData = [];
    const personalPackageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "61d847683fe3296a7c7c40ca"
    );
    const addressPackageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "61e5692c3ff56b6727ac069c"
    );
    setPersonalDetails(personalPackageId.children.slice(0, 8));
    setPersonalPackageTitle(personalPackageId.packageTitle);
    setAddressPackageTitle(addressPackageId.packageTitle);
    const updatedAddressData = addressPackageId.children;
    updatedAddressData.map((responseData) => {
      if (
        responseData.fieldName === "building_name" ||
        responseData.fieldName === "door_number" ||
        responseData.fieldName === "street_area_line_1" ||
        responseData.fieldName === "street_area_line_2" ||
        responseData.fieldName === "landmark" ||
        responseData.fieldName === "pin_code" ||
        responseData.fieldName === "district" ||
        responseData.fieldName === "state"
      ) {
        newData.push(responseData);
      }
      if (responseData.fieldName === "pincode-district-state") {
        pincodeDistrictStateData.push(responseData.responseValue);
      }
    });
    setAddressDetails(newData);
    setPdsDetails(pincodeDistrictStateData[0]);
  };

  const fetchCustomerKyc = async () => {
    const customerKycParams = {
      msisdn: mobileNumber,
      kycId: ACTIVE_KYC_ID,
    };

    const { data, status, error } = await getCustomerKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data) {
      parseData(data.data);
    }
  };

  const previousHandler = () => {
    navigate("/documents");
  };

  const getAllResponseData = async () => {
    const uuidparams = {
      uuid: DecryptData(getCookie("workflow_id")),
    };

    const { data, status, error } = await getworkFlowAllTransactions(
      uuidparams
    );
    if (error) {
      console.log(error);
    } else if (status == 200 && data) {
      findWorkFlowActionId(data.actions);
      setLoanPurpose(data.actions[0].payload.loanPurpose);
      setRequestedLoanAmount(data.actions[0].payload.amount);
      let payloadRequestedAmount = data.actions[0].payload.amount;
      let payloadVariant = data.actions[0].payload.variant_id;
      getLoanInformation(payloadRequestedAmount, payloadVariant);
    }
  };

  const getLoanInformation = async (payloadRequestedAmount, payloadVariant) => {
    let bodyParams = {};

    bodyParams["msisdn"] = mobileNumber;
    bodyParams["product_type"] = "Installment";
    bodyParams["requested_amount"] = payloadRequestedAmount;
    const { status, error, data } = await getLoanOffers(bodyParams);
    if (error) {
      console.log(error);
    } else if (status == 200) {
      if (data.status === "failed") {
        toast.error(`${data.message}, Please login`);
        navigate("/");
      }
      let variant_details = data.data.variant_details;
      console.log(variant_details);
      variant_details.map((element) => {
        if (element.variant_id === payloadVariant) {
          setEmi(element.emi_amount);
          setTenureMonths(element.max_installment_duration);
          setTotalInterest(
            element.emi_amount * element.max_installment_duration -
              payloadRequestedAmount
          );
          setInterestRate(element.interest_rate);
          setApplicationFee(element.fee_breakdown[3].fee_value);
        }
      });
    }
    setLoading(false);
  };

  const findWorkFlowActionId = (responseData) => {
    const containerInfo = responseData.find(
      (info) => info.action == "show_loan_summary"
    );
    const containerId = containerInfo.uuid;
    setWorkFlowActionId(containerId);
  };

  const nextHandler = async () => {
    setLoading(true);
    let bodyValues = {};
    bodyValues["allow_burea_check"] = true;
    let urlParameters = {
      uuid: workFlowActionId,
    };
    const { status, error } = await getworkFlowInteractionOutcome(
      urlParameters,
      bodyValues
    );
    if (error) {
      toast.error(error);
    } else if (status == 200) {
      setLoading(true);
      getNextActionPage().then((response) => {
        if (response.status == true) {
          setLoading(false);
          toast.success("Info updated successfully");
          navigate(response.screen);
        }
      });
    }
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <Stepper currentStep={13} />
        <div className="content-container">
          <div className="common-heading">
            <h1>Summary</h1>
          </div>
          {/* for loan summary */}
          <LoanSummary
            loanPurpose={loanPurpose}
            requestedLoanAmount={requestedLoanAmount}
            tenureMonths={tenureMonths}
            emi={emi}
            totalInterest={totalInterest}
            interestRate={interestRate}
            applicationFee={applicationFee}
          />
          {/* personal details */}
          <PersonalDetails
            personalDetails={personalDetails}
            personalPackageTitle={personalPackageTitle}
          />
          {/* personal details ended */}
          {/* address */}
          <Address
            addressDetails={addressDetails}
            pdsDetails={pdsDetails}
            addressPackageTitle={addressPackageTitle}
          />
          {/* address_ended */}
          <div className="check-section">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="details-correct"
                onClick={() => setAgree(!agree)}
              />
              <label className="form-check-label" htmlFor="details-correct">
                Allow Bureau Check
              </label>
            </div>
          </div>
        </div>
        <div className="button-section">
          <div>
            <button
              type="button"
              onClick={previousHandler}
              className="btn btn-outline-primary"
            >
              Previous
            </button>
          </div>
          <div>
            <button
              type="button"
              disabled={agree}
              onClick={nextHandler}
              className="btn btn-primary"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
