import React, { useState, useEffect } from "react";

import {
  getKycDefinition,
  getCustomerKyc,
  submitKyc,
} from "../../services/kyc-service";

import { getCookie } from "../../utils/cookie-helper";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Error from "../../components/error";
import Loader from "../../components/loader";

import "./previous-loan-details.css";
import { ACTIVE_KYC_ID } from "../../constants";
import { toast } from "react-toastify";

import Input from "../../components/input";
import DateInput from "../../components/dateInput";
import Select from "../../components/select";
import NumberInput from "../../components/numberInput";
import FWRadio from "../../components/fwRadio";
import TextArea from "../../components/textArea";
import { DecryptData } from "../../utils/application-helper";

const PreviousLoanDetailsEdit = (props) => {
  const [formValues, setFormValues] = useState({});
  const [showError, setShowError] = useState({});
  const [showLoan, setShowLoan] = useState(false);
  const [showReason, setShowReason] = useState(false);
  const [infoDefinition, setInfoDefinition] = useState([]);
  const [updatedRemarksData, setUpdatedRemarksData] = useState([]);
  const [previousValues, setPreviousValues] = useState({});
  const [isReadMore, setIsReadMore] = useState({});
  const [versionData, setVersionData] = useState(0);
  const [packageTitle, setPackageTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitDefinitions, setSubmitDefinitions] = useState([]);
  const [errMsg, setErrMsg] = useState();
  const [pastLoan, setPastLoan] = useState([]);
  const [reason, setReason] = useState([]);

  const params = {
    id: ACTIVE_KYC_ID,
  };

  const mobileNumber = DecryptData(getCookie("phone"));

  const handleChange = (e) => {
    setErrMsg();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setShowError({
      ...showError,
      [name]: false,
    });
  };

  const toggleReadMore = (fieldName) => {
    setIsReadMore({ ...isReadMore, [fieldName]: !isReadMore[fieldName] });
  };

  useEffect(() => {
    if (formValues.past_loan_in_n_years === "true") {
      setShowLoan(true);
    } else {
      setShowLoan(false);
    }
    if (formValues.repaid_on_time === "false") {
      setShowReason(true);
    } else {
      setShowReason(false);
    }
  }, [formValues]);

  const submitHandler = () => {
    let message;
    let errCount = 0;
    for (let i = 0; i < submitDefinitions.length; i++) {
      let testRegEx;
      let element = submitDefinitions[i];
      const { fieldName, fieldDisplayName, mandatory, validation } = element;
      if (validation !== undefined) {
        testRegEx = new RegExp(validation);
      }

      if (mandatory && !formValues[fieldName]) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} required!`;
        break;
      } else if (
        mandatory &&
        validation !== undefined &&
        !testRegEx.test(formValues[fieldName])
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} not matching!`;
        break;
      } else if (
        updatedRemarksData[fieldName] !== undefined &&
        previousValues[fieldName] === formValues[fieldName]
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `Please provide another ${fieldDisplayName}`;
        break;
      }
    }
    setErrMsg(message);
    if (errCount === 0) {
      createOrUpdateKyc();
    }
  };

  useEffect(() => {
    fetchKycDefinition();
    fetchCustomerKyc();
  }, []);

  const fetchKycDefinition = async () => {
    const { data, status, error } = await getKycDefinition(params);
    if (error) {
      console.log(error);
    } else if (status === 200 && data) {
      const responseKycDefinitionData = data.data;

      let infoDefinition = [];

      let submitDefinitions = [];

      let pastLoan = [];

      let reason = [];

      let showError = {};

      const packageId = responseKycDefinitionData.packagesDTOs.find(
        (packageDef) => packageDef.id === "622328a383ee70124c825fca"
      );

      const packageDTOs = packageId.children;
      setPackageTitle(packageId.packageTitle);

      packageDTOs.map((personDefinition) => {
        if (
          personDefinition.fieldName !== "past_loan_in_n_years" &&
          personDefinition.fieldName !== "reason_for_default"
        ) {
          infoDefinition.push({
            fieldId: personDefinition.id,
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
          });
          showError[personDefinition.fieldName] = false;
        }
      });

      packageDTOs.map((personDefinition) => {
        submitDefinitions.push({
          fieldId: personDefinition.id,
          fieldName: personDefinition.fieldName,
          mandatory: personDefinition.mandatory,
          validation: personDefinition.validation,
          fieldDisplayName: personDefinition.fieldDisplayName,
        });
        if (personDefinition.fieldName === "past_loan_in_n_years") {
          pastLoan.push({
            fieldId: personDefinition.id,
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
          });
        }
        if (personDefinition.fieldName === "reason_for_default") {
          reason.push({
            fieldId: personDefinition.id,
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
          });
        }
      });

      setShowError(showError);

      setInfoDefinition(infoDefinition);
      setLoading(false);
      setVersionData(responseKycDefinitionData.version);

      setSubmitDefinitions(submitDefinitions);
      setPastLoan(pastLoan);
      setReason(reason);
    }
  };

  const createOrUpdateKyc = async () => {
    let bodyData = {
      kycId: ACTIVE_KYC_ID,
      created_by: DecryptData(getCookie("phone")),
      data: {},
      version: versionData,
    };

    let convertedData = { ...formValues };
    if (convertedData.past_loan_in_n_years === "false") {
      submitDefinitions.map((field) => {
        const { fieldName } = field;
        if (fieldName !== "past_loan_in_n_years") {
          convertedData[fieldName] = "";
        }
      });
    }
    if (convertedData.repaid_on_time === "true") {
      convertedData["reason_for_default"] = "";
    }

    submitDefinitions.map((field) => {
      const { fieldName } = field;
      bodyData["data"][fieldName] = convertedData[fieldName];
    });

    const { status, data, error } = await submitKyc(bodyData);
    if (error) {
      toast.error(error.message);
    } else if (status === 200 && data) {
      props.updateHandler(formValues);
    }
  };

  const parseCustomerData = (responseData) => {
    let newData = {};
    let remarksData = {};
    let isReadMore = {};

    const packageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "622328a383ee70124c825fca"
    );

    packageId.children.map((element) => {
      newData[element.fieldName] = element.responseValue;
      remarksData[element.fieldName] = element.remarks;
      if (element.remarks !== undefined && element.remarks.length > 45) {
        isReadMore[element.fieldName] = true;
      } else {
        isReadMore[element.fieldName] = false;
      }
    });

    setFormValues(newData);
    setPreviousValues(newData);
    setIsReadMore(isReadMore);
    setUpdatedRemarksData(remarksData);
  };

  const fetchCustomerKyc = async () => {
    const customerKycParams = {
      msisdn: mobileNumber,
      kycId: ACTIVE_KYC_ID,
    };

    const { data, status, error } = await getCustomerKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data) {
      const responseData = data.data;
      if (responseData !== undefined) {
        parseCustomerData(responseData);
      }
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <div className="content-container">
          <Stepper currentStep={2} />
          <div className="common-heading">
            <h1>{packageTitle}</h1>
          </div>

          {pastLoan.map((formField, index) => {
            switch (formField.type) {
              case "RADIO":
                return (
                  <div key={index}>
                    <FWRadio
                      fieldName={formField.fieldName}
                      display={formField.fieldDisplayName}
                      mandatory={formField.mandatory}
                      formValues={formValues}
                      showError={showError}
                      handleChange={handleChange}
                      options={formField.options}
                    />
                    {updatedRemarksData[formField.fieldName] && (
                      <p className="remarks">
                        {isReadMore[formField.fieldName]
                          ? updatedRemarksData[formField.fieldName].slice(0, 45)
                          : updatedRemarksData[formField.fieldName]}
                        {updatedRemarksData[formField.fieldName].length >
                          45 && (
                          <span
                            onClick={() => toggleReadMore(formField.fieldName)}
                          >
                            {isReadMore[formField.fieldName]
                              ? "...Read more"
                              : " Show less"}
                          </span>
                        )}
                      </p>
                    )}
                  </div>
                );

              default:
                break;
            }
          })}

          <div
            className={
              showLoan ? "list-section-wrapper" : "list-section-wrapper hide"
            }
          >
            <ul>
              {infoDefinition.map((formField, index) => {
                switch (formField.type) {
                  case "TEXT":
                    return (
                      <li key={index}>
                        <Input
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );
                  case "CALENDAR":
                    return (
                      <li key={index}>
                        <DateInput
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );
                  case "RADIO":
                    return (
                      <li key={index}>
                        <Select
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                          options={formField.options}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );
                  case "NUMBER":
                    return (
                      <li key={index}>
                        <NumberInput
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                          options={formField.options}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );

                  default:
                    break;
                }
              })}
            </ul>
            <div className="list-section-wrapper previous-textarea personal-discussion-screen">
              <ul className={showReason ? "" : "hide"}>
                {reason.map((formField, index) => {
                  switch (formField.type) {
                    case "TEXT":
                      return (
                        <li key={index}>
                          <TextArea
                            fieldName={formField.fieldName}
                            display={formField.fieldDisplayName}
                            mandatory={formField.mandatory}
                            formValues={formValues}
                            showError={showError}
                            handleChange={handleChange}
                            options={formField.options}
                          />
                          {updatedRemarksData[formField.fieldName] && (
                            <p className="remarks">
                              {isReadMore[formField.fieldName]
                                ? updatedRemarksData[formField.fieldName].slice(
                                    0,
                                    45
                                  )
                                : updatedRemarksData[formField.fieldName]}
                              {updatedRemarksData[formField.fieldName].length >
                                45 && (
                                <span
                                  onClick={() =>
                                    toggleReadMore(formField.fieldName)
                                  }
                                >
                                  {isReadMore[formField.fieldName]
                                    ? "...Read more"
                                    : " Show less"}
                                </span>
                              )}
                            </p>
                          )}
                        </li>
                      );
                  }
                })}
              </ul>
            </div>
          </div>

          {errMsg && <Error message={errMsg} />}
        </div>
        <div className="button-section">
          <div>
            <button
              type="button"
              onClick={props.discardChangesHandler}
              className="btn btn-outline-primary"
            >
              Discard change
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={submitHandler}
              className="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviousLoanDetailsEdit;
