import React, { useState, useEffect } from "react";

import {
  getKycDefinition,
  getCustomerKyc,
  submitKyc,
} from "../../services/kyc-service";

import { getCookie } from "../../utils/cookie-helper";

import { ACTIVE_KYC_ID } from "../../constants";

import { toast } from "react-toastify";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Error from "../../components/error";
import Loader from "../../components/loader";
import "./address.css";

import Input from "../../components/input";
import DateInput from "../../components/dateInput";
import Select from "../../components/select";
import NumberInput from "../../components/numberInput";
import { DecryptData } from "../../utils/application-helper";

const AddressEditDetails = (props) => {
  const [formValues, setFormValues] = useState({});
  const [showError, setShowError] = useState();
  const [districtOption, setDistrictOption] = useState([]);
  const [stateOption, setStateOption] = useState([]);
  const [infoDefinition, setInfoDefinition] = useState([]);
  const [versionData, setVersionData] = useState(0);
  const [time, setTime] = useState(0);
  const [convertedTime, setConvertedTime] = useState();
  const [pincodeDefinition, setPincodeDefinition] = useState([]);
  const [updatedRemarksData, setUpdatedRemarksData] = useState([]);
  const [previousValues, setPreviousValues] = useState({});
  const [isReadMore, setIsReadMore] = useState({});
  const [packageTitle, setPackageTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitDefinitions, setSubmitDefinitions] = useState([]);
  const [errMsg, setErrMsg] = useState();

  const params = {
    id: ACTIVE_KYC_ID,
  };

  const mobileNumber = DecryptData(getCookie("phone"));

  useEffect(() => {
    fetchKycDefinition();
    fetchCustomerKyc();
  }, []);

  useEffect(() => {
    const id = setInterval(() => setTime((oldCount) => oldCount + 1), 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    secondsToHms(time);
  }, [time]);

  const handleChange = (e) => {
    setErrMsg();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setShowError({
      ...showError,
      [name]: false,
    });
  };

  const toggleReadMore = (fieldName) => {
    setIsReadMore({ ...isReadMore, [fieldName]: !isReadMore[fieldName] });
  };

  const submitHandler = () => {
    let message;
    let errCount = 0;
    for (let i = 0; i < infoDefinition.length; i++) {
      let testRegEx;
      let element = infoDefinition[i];
      const { fieldName, fieldDisplayName, mandatory, validation } = element;
      if (validation !== undefined) {
        testRegEx = new RegExp(validation);
      }

      if (mandatory && !formValues[fieldName]) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} required!`;
        break;
      } else if (
        mandatory &&
        validation !== undefined &&
        !testRegEx.test(formValues[fieldName])
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} not matching!`;
        break;
      } else if (
        updatedRemarksData[fieldName] !== undefined &&
        previousValues[fieldName] === formValues[fieldName]
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `Please provide another ${fieldDisplayName}`;
      }
    }
    setErrMsg(message);
    if (errCount === 0) {
      createOrUpdateKyc();
    }
  };

  const fetchKycDefinition = async () => {
    const { data, status, error } = await getKycDefinition(params);
    if (error) {
      console.log(error);
    } else if (status === 200 && data) {
      const responseKycDefinitionData = data.data;

      let infoDefinition = [];

      let submitDefinitions = [];

      let pincodeDefinition = [];

      let showError = {};

      let formValues = {};

      const packageId = responseKycDefinitionData.packagesDTOs.find(
        (packageDef) => packageDef.id === "61e5692c3ff56b6727ac069c"
      );

      const packageDTOs = packageId.children;
      setPackageTitle(packageId.packageTitle);

      packageDTOs.map((personDefinition) => {
        if (
          personDefinition.fieldName !== "pincode-district-state" &&
          personDefinition.fieldName !== "time_spent_on_address" &&
          personDefinition.fieldName !== "address_confirmation" &&
          personDefinition.fieldName !== "remarks_on_address"
        ) {
          infoDefinition.push({
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
          });
          showError[personDefinition.fieldName] = false;
          formValues[personDefinition.fieldName] = undefined;
        }

        if (personDefinition.fieldName === "pincode-district-state") {
          pincodeDefinition.push(personDefinition);
        }
      });

      packageDTOs.map((personDefinition) => {
        if (
          personDefinition.fieldName !== "pin_code" &&
          personDefinition.fieldName !== "district" &&
          personDefinition.fieldName !== "state" &&
          personDefinition.fieldName !== "address_confirmation" &&
          personDefinition.fieldName !== "remarks_on_address" &&
          personDefinition.fieldName !== "time_spent_on_address"
        ) {
          submitDefinitions.push({
            fieldName: personDefinition.fieldName,
          });
        }
      });

      setShowError(showError);

      setInfoDefinition(infoDefinition);
      setPincodeDefinition(pincodeDefinition);
      setLoading(false);
      setVersionData(responseKycDefinitionData.version);

      setSubmitDefinitions(submitDefinitions);
      setFormValues(formValues);
    }
  };

  useEffect(() => {
    parsePinCodeData(
      pincodeDefinition,
      formValues.pin_code,
      formValues.district
    );
  }, [pincodeDefinition, formValues.pin_code, formValues.district]);

  const parsePinCodeData = (
    pincodeDefinition,
    pinCodeValue,
    districtTargetValue
  ) => {
    let multiLookup = [];
    let districtValues = [];
    let stateValues = [];
    pincodeDefinition.map((element) => {
      if (element.fieldName === "pincode-district-state") {
        multiLookup.push(element.multiLookup);
        if (multiLookup !== undefined) {
          let multiLookupArray = Object.entries(multiLookup[0]);
          const updatedPincode = multiLookupArray.find(
            (pincodeDef) => pincodeDef[0] === pinCodeValue
          );

          if (updatedPincode !== undefined) {
            let updatedPincodeArray = Object.entries(updatedPincode[1]);

            updatedPincodeArray.map((item) => {
              districtValues.push(item[0]);
            });

            updatedPincodeArray.map((item) => {
              switch (item[0]) {
                case districtTargetValue:
                  stateValues.push(item[1]);
                  break;

                default:
                  break;
              }
            });
          }
        }
      }
    });

    setDistrictOption(districtValues);
    setStateOption(stateValues[0]);
  };

  const createOrUpdateKyc = async () => {
    let bodyData = {
      kycId: ACTIVE_KYC_ID,
      created_by: DecryptData(getCookie("phone")),
      data: {},
      version: versionData,
    };

    submitDefinitions.map((field) => {
      const { fieldName } = field;
      bodyData["data"][fieldName] = formValues[fieldName];
      if (fieldName === "pincode-district-state") {
        let formValuesObject = {
          pincode: formValues.pin_code,
          district: formValues.district,
          state: formValues.state,
        };
        bodyData["data"][fieldName] = formValuesObject;
      }
    });

    bodyData["data"]["time_spent_on_address"] = convertedTime;
    const { status, data, error } = await submitKyc(bodyData);
    if (error) {
      toast.error(error.message);
    } else if (status === 200 && data) {
      props.updateHandler(formValues);
    }
  };

  const parseCustomerData = (responseData) => {
    let newData = {};
    let remarksData = {};
    let isReadMore = {};
    const packageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "61e5692c3ff56b6727ac069c"
    );
    packageId.children.map((element) => {
      if (
        element.fieldName !== "address_confirmation" &&
        element.fieldName !== "remarks_on_address" &&
        element.fieldName !== "pincode-district-state"
      ) {
        newData[element.fieldName] = element.responseValue;
        remarksData[element.fieldName] = element.remarks;
        if (element.remarks !== undefined && element.remarks.length > 45) {
          isReadMore[element.fieldName] = true;
        } else {
          isReadMore[element.fieldName] = false;
        }
      }

      if (element.fieldName === "time_spent_on_address") {
        if (element.responseValue !== "") {
          let hms = element.responseValue;
          const [hours, minutes, seconds] = hms.split(":");
          const totalSeconds = +hours * 60 * 60 + +minutes * 60 + +seconds;
          setTime(totalSeconds);
        }
      }
      if (element.fieldName === "pincode-district-state") {
        newData.pin_code = element.responseValue.pincode;
        newData.district = element.responseValue.district;
        newData.state = element.responseValue.state;
      }
    });

    setFormValues(newData);
    setPreviousValues(newData);
    setUpdatedRemarksData(remarksData);
    setIsReadMore(isReadMore);
  };

  const fetchCustomerKyc = async () => {
    const customerKycParams = {
      msisdn: mobileNumber,
      kycId: ACTIVE_KYC_ID,
    };

    const { data, status, error } = await getCustomerKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data) {
      const responseData = data.data;
      if (responseData !== undefined) {
        parseCustomerData(responseData);
      }
    }
  };

  const secondsToHms = (time) => {
    var date = new Date(null);
    date?.setSeconds(time);
    let convertedTime = date?.toISOString().substr(11, 8);
    setConvertedTime(convertedTime);
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <Stepper currentStep={4} />
        <div className="content-container">
          <div className="common-heading">
            <h1>{packageTitle}</h1>
          </div>
          <div className="list-section-wrapper address-edit-screen">
            <ul>
              {infoDefinition.map((formField, index) => {
                switch (formField.type) {
                  case "TEXT":
                    return (
                      <li key={index}>
                        <Input
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );
                  case "CALENDAR":
                    return (
                      <li key={index}>
                        <DateInput
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );
                  case "RADIO":
                    return (
                      <li key={index}>
                        <Select
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                          options={
                            formField.fieldName === "district"
                              ? districtOption
                              : formField.fieldName === "state"
                              ? stateOption
                              : formField.options
                          }
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );
                  case "NUMBER":
                    return (
                      <li key={index}>
                        <NumberInput
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                          options={formField.options}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );

                  default:
                    break;
                }
              })}
            </ul>
          </div>

          {errMsg && <Error message={errMsg} />}
        </div>
        <div className="button-section">
          <div>
            <button
              type="button"
              onClick={props.discardChangesHandler}
              className="btn btn-outline-primary"
            >
              Discard change
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={submitHandler}
              className="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressEditDetails;
