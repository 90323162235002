import React from "react";

import checkMark from "../../assets/images/checkMark.png";

import "./thankyou.css";

const Thankyou = () => {
  return (
    <div className="status-action">
      <div className="waiting-content success">
        <img src={checkMark} alt="tick-image" />
        <h1>
          {" "}
          Thanks your Disbursement Request is being Processed and you will get
          confirmation shortly.
        </h1>
      </div>
    </div>
  );
};

export default Thankyou;
