import React from "react";

const Select = ({
  fieldName,
  display,
  mandatory,
  showError,
  handleChange,
  formValues,
  options,
}) => {
  return (
    <div>
      <label>
        {display} <span className="red">{mandatory === true ? "*" : ""}</span>
      </label>
      <select
        className={
          showError[fieldName] ? "form-select border-red" : "form-select"
        }
        name={fieldName}
        onChange={handleChange}
        value={formValues[fieldName] ? formValues[fieldName] : ""}
      >
        <option value="">Choose</option>
        {options &&
          options.map((item, index) => {
            return (
              <option
                key={item.value ? item.value : index}
                value={item.value && item.value}
              >
                {item.display ? item.display : item}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default Select;
