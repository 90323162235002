import React from "react";

import cross from "../../assets/images/cross.png";

import "./failed.css";

const Failed = () => {
  return (
    <div className="status-action">
      <div className="waiting-content failed">
        <img src={cross} alt="cross-image" />
        <h1>
          {" "}
          Sorry we Couldnt process your request <br /> please try again in some
          time.
        </h1>
      </div>
    </div>
  );
};

export default Failed;
