import React, { useState, useEffect } from "react";

import imageCompression from "browser-image-compression";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { getCookie } from "../../utils/cookie-helper";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Loader from "../../components/loader";
import Error from "../../components/error";

import headset from "../../assets/images/headset.png";
import preview from "../../assets/images/preview.png";

import DocumentFile from "../../components/documentFile";

import {
  uploadKyc,
  getCustomerKyc,
  submitKyc,
  getKycDefinition,
} from "../../services/kyc-service";
import { getworkFlowInteractionOutcome } from "../../services/workflow-service";
import { ACTIVE_KYC_ID } from "../../constants";
import {
  getNextActionPage,
  fetchAllWorkFlowActions,
  DecryptData,
} from "../../utils/application-helper";

import "./loan-agreement.css";

import html2pdf from "html2pdf.js";

import moment from "moment";

const LoanAgreement = () => {
  const navigate = useNavigate();
  const [infoDefinition, setInfoDefinition] = useState([]);
  const [versionData, setVersionData] = useState(0);
  const [workFlowActionId, setWorkFlowActionId] = useState("");
  const [updatedRemarksData, setUpdatedRemarksData] = useState([]);
  const [previousValues, setPreviousValues] = useState({});
  const [isReadMore, setIsReadMore] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [packageTitle, setPackageTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [errMsg, setErrMsg] = useState();
  const [submitDefinitions, setSubmitDefinitions] = useState([]);
  const [showError, setShowError] = useState();
  const [formValues, setFormValues] = useState([]);
  const [tempShow, setTempShow] = useState();
  const [tempShowView, setTempShowView] = useState();
  const [tempExt, setTempExt] = useState();
  const [kycExt, setKycExt] = useState([]);
  const [extStr, setExtStr] = useState([]);

  const mobileNumber = DecryptData(getCookie("phone"));

  useEffect(() => {
    fetchKycDefinition();
    fetchAllWorkKyc();
  }, []);

  const fetchAllWorkKyc = () => {
    fetchAllWorkFlowActions("show_loan_agreement").then((response) => {
      if (response.status === true) {
        setWorkFlowActionId(response.value);
      }
    });
  };

  const fetchKycDefinition = async () => {
    const params = {
      id: ACTIVE_KYC_ID,
    };
    const { data, status, error } = await getKycDefinition(params);
    if (error) {
      console.log(error);
    } else if (status === 200 && data) {
      const responseKycDefinitionData = data.data;

      let infoDefinition = [];
      let submitDefinitions = [];
      let showError = {};
      let tempShow = [];
      let tempShowView = [];
      let tempExt = [];
      let formValues = [];
      let kycExt = [];
      let extStr = [];

      const packageId = responseKycDefinitionData.packagesDTOs.find(
        (packageDef) => packageDef.id === "623802ffe59ece01dc3671b9"
      );

      const packageDTOs = packageId.children;
      setPackageTitle(packageId.packageTitle);

      packageDTOs.map((personDefinition) => {
        if (personDefinition.fieldName !== "download_agreement") {
          infoDefinition.push({
            fieldId: personDefinition.id,
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
            displayType: personDefinition.displayType,
          });
          showError[personDefinition.fieldName] = false;
        }

        if (
          personDefinition.type === "DOCUMENT" &&
          personDefinition.fieldName !== "download_agreement"
        ) {
          tempShow[personDefinition.fieldName] = true;
          tempShowView[personDefinition.fieldName] = false;
          tempExt[personDefinition.fieldName] = "";
          kycExt[personDefinition.fieldName] = [];
          extStr[personDefinition.fieldName] = "";
        }
      });

      packageDTOs.map((personDefinition) => {
        if (
          personDefinition.type === "DOCUMENT" &&
          personDefinition.fieldName !== "download_agreement"
        ) {
          personDefinition.fileTypes.map((file) => {
            kycExt[personDefinition.fieldName].push(file.split(".").pop());
          });
        }
      });

      packageDTOs.map((personDefinition) => {
        if (personDefinition.fieldName !== "download_agreement") {
          submitDefinitions.push({
            fieldName: personDefinition.fieldName,
          });
          if (personDefinition.displayType === "CHECKMARK") {
            formValues[personDefinition.fieldName] = false;
          } else {
            formValues[personDefinition.fieldName] = "";
          }
        }
      });

      packageDTOs.map((personDefinition) => {
        if (
          personDefinition.type === "DOCUMENT" &&
          personDefinition.fieldName !== "download_agreement"
        ) {
          extStr[personDefinition.fieldName] = kycExt[
            personDefinition.fieldName
          ]
            .toString()
            .replaceAll(",", "/");
        }
      });

      setKycExt(kycExt);
      setExtStr(extStr);
      setShowError(showError);
      setInfoDefinition(infoDefinition);
      setVersionData(responseKycDefinitionData.version);
      setSubmitDefinitions(submitDefinitions);
      fetchCustomerKyc(tempShow, tempShowView, tempExt, formValues);
    }
  };

  const fetchCustomerKyc = async (
    tempShow,
    tempShowView,
    tempExt,
    formValues
  ) => {
    const customerKycParams = {
      msisdn: mobileNumber,
      kycId: ACTIVE_KYC_ID,
    };

    const { data, status, error } = await getCustomerKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data) {
      const responseData = data.data;
      if (responseData !== undefined) {
        parseData(responseData, tempShow, tempShowView, tempExt, formValues);
      }
    }
  };

  const parseData = (
    responseData,
    tempShow,
    tempShowView,
    tempExt,
    formValues
  ) => {
    let newData = [];
    let remarksData = {};
    let isReadMore = {};

    const packageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "623802ffe59ece01dc3671b9"
    );

    packageId.children.map((element) => {
      const { fieldName, remarks, responseValue, displayType } = element;
      if (fieldName !== "download_agreement") {
        if (displayType === "CHECKMARK") {
          newData[fieldName] = (responseValue && responseValue) || false;
          formValues[fieldName] = (responseValue && responseValue) || false;
        } else {
          newData[fieldName] = (responseValue && responseValue) || "";
          formValues[fieldName] = (responseValue && responseValue) || "";
        }
        remarksData[fieldName] = remarks;
        if (element.remarks !== undefined && element.remarks.length > 45) {
          isReadMore[element.fieldName] = true;
        } else {
          isReadMore[element.fieldName] = false;
        }
      }
    });

    packageId.children.map((element) => {
      const { fieldName, type, responseValue } = element;
      if (type === "DOCUMENT" && fieldName !== "download_agreement") {
        if (responseValue === "") {
          tempShow[fieldName] = true;
        } else {
          tempShow[fieldName] = false;
        }
        tempShowView[fieldName] = (responseValue && true) || false;
        tempExt[fieldName] =
          (responseValue && responseValue.split(".").pop()) || "";
      }
    });

    setPreviousValues(newData);
    setFormValues(formValues);
    setUpdatedRemarksData(remarksData);
    setIsReadMore(isReadMore);
    setTempShow(tempShow);
    setTempShowView(tempShowView);
    setTempExt(tempExt);
    setLoading(false);
  };

  const toggleReadMore = (fieldName) => {
    setIsReadMore({ ...isReadMore, [fieldName]: !isReadMore[fieldName] });
  };

  const handleFileChange = async (value, fieldName) => {
    setLoading(true);
    setErrMsg();
    const file = value[0];
    const fileName = file.name;
    const fileExtension = file.name.split(".").pop();
    const options = {
      maxSizeMB: 0.5,
      useWebWorker: true,
    };

    let compressedFile;
    var newFile;

    if (file.size > 500000 && ["jpg", "jpeg", "png"].includes(fileExtension)) {
      compressedFile = await imageCompression(file, options);
      newFile = new File([compressedFile], fileName);
    } else {
      newFile = file;
    }
    var extension = newFile.name.split(".").pop();

    setTempExt({ ...tempExt, [fieldName]: extension });

    var formImageData = new FormData();
    formImageData.append("file", newFile);
    formImageData.append("kycId", ACTIVE_KYC_ID);
    formImageData.append("fieldId", fieldName);

    const { status, error, data } = await uploadKyc(formImageData);
    if (error) {
      console.log(error);
    } else if (status === 200 && data) {
      setFormValues({ ...formValues, [fieldName]: data.data.fileName });
      setTempShow({ ...tempShow, [fieldName]: false });
      setTempShowView({ ...tempShowView, [fieldName]: true });
      setLoading(false);
    }
  };

  const updateHandler = async (bodyData) => {
    let { data } = bodyData;
    let bodyValues = {};

    submitDefinitions.map((field) => {
      const { fieldName } = field;
      bodyValues[fieldName] = data[fieldName];
    });

    let urlParameters = {
      uuid: workFlowActionId,
    };

    const { status, error } = await getworkFlowInteractionOutcome(
      urlParameters,
      bodyValues
    );
    if (error) {
      toast.error(error);
    } else if (status == 200) {
      getNextActionPage().then((response) => {
        if (response.status == true) {
          setLoading(false);
          toast.success("Info updated successfully");
          navigate(response.screen);
        }
      });
    }
  };

  const createOrUpdateKyc = async () => {
    setLoading(true);
    let bodyData = {
      kycId: ACTIVE_KYC_ID,
      created_by: DecryptData(getCookie("phone")),
      data: {},
      version: versionData,
    };

    submitDefinitions.map((field) => {
      const { fieldName } = field;
      bodyData["data"][fieldName] = formValues[fieldName];
    });

    const { status, data, error } = await submitKyc(bodyData);
    if (error) {
      toast.error(error.message);
    } else if (status === 200 && data) {
      updateHandler(bodyData);
    }
  };

  const nextHandler = async () => {
    let errCount = 0;
    let message = "";
    for (let i = 0; i < infoDefinition.length; i++) {
      let element = infoDefinition[i];
      const { fieldName, fieldDisplayName, mandatory, type } = element;
      if (mandatory && !formValues[fieldName]) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} required!`;
        break;
      } else if (type === "DOCUMENT") {
        if (mandatory && !kycExt[fieldName].includes(tempExt[fieldName])) {
          errCount = 1;
          setShowError({ ...showError, [fieldName]: true });
          message = `Please upload ${fieldDisplayName} in ${extStr[fieldName]}`;
          break;
        }
        if (
          updatedRemarksData[fieldName] !== undefined &&
          previousValues[fieldName] === formValues[fieldName]
        ) {
          errCount = 1;
          setShowError({ ...showError, [fieldName]: true });
          message = `Please provide another ${fieldDisplayName}`;
          break;
        }
      } else if (
        updatedRemarksData[fieldName] !== undefined &&
        previousValues[fieldName] === formValues[fieldName]
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `Please provide another ${fieldDisplayName}`;
        break;
      }
    }
    setErrMsg(message);
    if (errCount === 0) {
      createOrUpdateKyc();
    }
  };

  const Preview = ({ children }) => {
    return <div id="pdfStage">{children}</div>;
  };

  const PDFButton = () => {
    useEffect(() => {});
    function makePDF() {
      var element = document.getElementById("pdfStage");
      var opt = {
        margin: 0.5,
        filename: "agreement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };

      html2pdf().set(opt).from(element).save();
    }
    return (
      <div className="button-section">
        <button className="btn btn-primary download-document" onClick={makePDF}>
          Download
        </button>
      </div>
    );
  };

  var currentDate = moment(new Date()).format("DD/MM/YYYY");
  var firstName = "John";

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <Stepper currentStep={14} />
        <div className="content-container">
          <div className="common-heading">
            <h1>{packageTitle}</h1>
          </div>
          <ul>
            <li>
              Please download the agreement by clicking the download link.
            </li>
            <li>
              You can preview the document by clicking the preview link here.
            </li>
            <li>
              After downloading the document, sign in blue ink and scan / take a
              clear picture of the signed document with borders intact.
            </li>
            <li>
              Upload the picture or scan in JPEG/PNG/PDF format on the upload
              link below.
            </li>
          </ul>
          <div className="button-section assistance-section preview-download-section">
            <div>
              <button
                type="button"
                className="btn"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <img src={preview} alt="preview-icon" />
                Preview
              </button>
            </div>
          </div>
          {infoDefinition.map((formField, index) => {
            switch (formField.type) {
              case "DOCUMENT":
                return (
                  <div
                    className="document-upload-wrapper loan-agreement-wrapper"
                    key={index}
                  >
                    <DocumentFile
                      fieldName={formField.fieldName}
                      display={formField.fieldDisplayName}
                      mandatory={formField.mandatory}
                      formValues={formValues}
                      showError={showError}
                      handleFileChange={handleFileChange}
                      options={formField.options}
                      fieldId={formField.fieldId}
                      tempShow={tempShow}
                      tempShowView={tempShowView}
                      tempExt={tempExt}
                    />
                    {updatedRemarksData[formField.fieldName] && (
                      <p className="remarks">
                        {isReadMore[formField.fieldName]
                          ? updatedRemarksData[formField.fieldName].slice(0, 45)
                          : updatedRemarksData[formField.fieldName]}
                        {updatedRemarksData[formField.fieldName].length >
                          45 && (
                          <span
                            onClick={() => toggleReadMore(formField.fieldName)}
                          >
                            {isReadMore[formField.fieldName]
                              ? "...Read more"
                              : " Show less"}
                          </span>
                        )}
                      </p>
                    )}
                  </div>
                );

              default:
                break;
            }
          })}

          <div className="button-section assistance-section justify-content-end">
            <div>
              <button type="button" className="btn">
                <img src={headset} alt="headset-icon" />
                Need Assistance
              </button>
            </div>
          </div>
          {errMsg && <Error message={errMsg} />}
        </div>
        <div className="button-section">
          <div>
            <button type="button" className="btn btn-outline-primary">
              Previous
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={nextHandler}
              className="btn btn-primary"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="backdrop">
          <div className="preview-modal">
            <div className="preview-body">
              <div className="document prev-doc">
                <Preview>
                  <div className="common-heading">
                    <h1 className="text">PERSONAL LOAN AGREEMENT</h1>
                  </div>
                  <p>
                    <strong>I. THE PARTIES.</strong> This Personal Loan
                    Agreement made {currentDate} , is by and between: Borrower:{" "}
                    {firstName}. HEREINAFTER, the Borrower and Lender
                    (“Parties”) agree to the following:
                  </p>
                </Preview>
                <PDFButton />
              </div>
              <div className="button-section justify-content-end preview-document">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoanAgreement;
