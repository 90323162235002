import React, { useState } from "react";

import { toast } from "react-toastify";

import PersonalDetails from "./PersonalDetails";
import PersonalEditDetails from "./PersonalDetailsEdit";

const PersonalInfo = () => {
  const [showDetails, setShowDetails] = useState(true);
  const [showEdit, setShowEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});

  const editHandler = () => {
    setShowEdit(true);
    setShowDetails(false);
  };

  const discardChangesHandler = () => {
    setShowDetails(true);
    setShowEdit(false);
  };

  const updateHandler = (formValues, age) => {
    setShowDetails(true);
    setShowEdit(false);
    setUpdatedValues({ ...formValues, age: age });
    toast.success("Personal info updated successfully");
  };

  return (
    <div>
      {showDetails && (
        <PersonalDetails
          editHandler={editHandler}
          updatedValues={updatedValues}
        />
      )}
      {showEdit && (
        <PersonalEditDetails
          discardChangesHandler={discardChangesHandler}
          updateHandler={updateHandler}
        />
      )}
    </div>
  );
};

export default PersonalInfo;
