// Application & system level constants

export const MOBILE_NUMBER_REGEX = new RegExp("^[6-9][0-9]{9}$");

export const AUTH_KEY = "token";

export const LOAN_STEPPER = [
  {
    stepNumber: 1,
    name: "Loan Simulator",
  },
  {
    stepNumber: 2,
    name: "Previous Loan Info",
  },
  {
    stepNumber: 3,
    name: "Personal Info",
  },
  {
    stepNumber: 4,
    name: "Address",
  },
  {
    stepNumber: 5,
    name: "Documents",
  },
  {
    stepNumber: 6,
    name: "Additional Info",
  },
  {
    stepNumber: 7,
    name: "Loan Info",
  },
  {
    stepNumber: 8,
    name: "Personal Discussion",
  },
  {
    stepNumber: 9,
    name: "Personal Income",
  },
  {
    stepNumber: 10,
    name: "Business Details",
  },
  {
    stepNumber: 11,
    name: "Lifestyle Details",
  },
  {
    stepNumber: 12,
    name: "Mandate",
  },
  {
    stepNumber: 13,
    name: "Summary",
  },
  {
    stepNumber: 14,
    name: "Loan Agreement",
  },
  {
    stepNumber: 15,
    name: "Loan Servicing",
  },
];

export const ACTIVE_WORKFLOW_NAME = "Mother workflow";

export const ACTIVE_LMS_PRODUCT = "Installment";

export const LMS_PARTNER_CODE = "yabxdemo_in";
export const LMS_OS = "web_application";
export const LMS_PACKAGE_ID = "f88723dc39b92ee5";

export const LMS_CLIENT_SECRET =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXJ0bmVyX2NvZGUiOiJ5YWJ4ZGVtb19pbiIsIm9zIjoid2ViX2FwcGxpY2F0aW9uIiwicGFja2FnZV9pZCI6ImY4ODcyM2RjMzliOTJlZTUiLCJjbGllbnRfa2V5IjoiYzBhNDliMjctNGI5Yi00NjU4LTk2YTMtOGU1YzQ2YTg2YzQwIn0.asTHljqR3Gr9EgkMpkrDskbgidJw6B3KFpcz0du_PUs";

export const ACTIVE_KYC_ID = "61d849c43fe3296a7c7c40cb";

export const ACTIVE_KYC_ID_MANDATE = "61f8fee7e0807412c1c9fb0d";

export const KYC_ACCESS_TOKEN =
  "eyJhbGciOiJIUzI1NiJ9.eyJwYXJ0bmVyX2NvZGUiOiJ5YWJ4ZGVtb19pbiIsInBhY2thZ2VfaWQiOiI1MzAxMjE2NGUzNzc1ZGVhIiwib3MiOiJ3ZWJfYXBwbGljYXRpb24iLCJ1c2VyX2lkIjoxLCJleHAiOjE2NDIxNzMzNDd9.m21sKtmfA1KKyvxv-EsHe-bP6U_x93kfYxKRRVljVuc";

export const SECRET_PASS = process.env.REACT_APP_SECRET_PASS;
