import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DecryptData } from "../utils/application-helper";
import { getCookie } from "../utils/cookie-helper";

const PrivateRoute = ({ Component }) => {
  const navigate = useNavigate();
  const workflowId = DecryptData(getCookie("workflow_id"));

  useEffect(() => {
    if (!workflowId) {
      navigate("/");
      toast.error("Please login to continue");
    }
  }, []);
  return (
    <div>
      <Component />
    </div>
  );
};

export default PrivateRoute;
