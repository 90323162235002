import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Loader from "../../components/loader";

import "./address.css";

import edit from "../../assets/images/edit.svg";

import { ACTIVE_KYC_ID } from "../../constants";
import { getCookie } from "../../utils/cookie-helper";
import { getCustomerKyc } from "../../services/kyc-service";
import {
  getNextActionPage,
  fetchAllWorkFlowActions,
  DecryptData,
} from "../../utils/application-helper";
import {
  getworkFlowInteractionOutcome,
  profileUpdate,
} from "../../services/workflow-service";

const AddressDetails = ({ editHandler }) => {
  const navigate = useNavigate();
  const [agree, setAgree] = useState(true);
  const [workFlowActionId, setWorkFlowActionId] = useState("");
  const [slicedResponse, setSlicedResponse] = useState([]);
  const [pds, setPds] = useState([]);
  const [packageTitle, setPackageTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitDefinitions, setSubmitDefinitions] = useState([]);
  const [customerResponse, setCustomerResponse] = useState();

  const mobileNumber = DecryptData(getCookie("phone"));

  useEffect(() => {
    fetchCustomerKyc();
    fetchAllWorkKyc();
  }, []);

  const fetchAllWorkKyc = () => {
    fetchAllWorkFlowActions("get_address").then((response) => {
      if (response.status === true) {
        setWorkFlowActionId(response.value);
      }
    });
  };

  const previousHandler = () => {
    navigate("/personal-info");
  };

  const parseData = (responseData) => {
    let responseValueCount = 0;
    let remarksValueCount = 0;
    let newData = [];
    let pincodeDistrictStateData = [];
    let submitDefinitions = [];
    let customerResponse = {};

    const packageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "61e5692c3ff56b6727ac069c"
    );
    const updateData = packageId.children;
    setPackageTitle(packageId.packageTitle);

    updateData.map((responseData) => {
      if (
        responseData.fieldName !== "pincode-district-state" &&
        responseData.fieldName !== "time_spent_on_address" &&
        responseData.fieldName !== "address_confirmation" &&
        responseData.fieldName !== "remarks_on_address"
      ) {
        newData.push(responseData);
        customerResponse[responseData.fieldName] = responseData.responseValue;
      }

      if (responseData.fieldName === "pincode-district-state") {
        const { responseValue } = responseData;
        pincodeDistrictStateData.push(responseValue);
        customerResponse["pin_code"] = responseData.responseValue["pincode"];
        customerResponse["district"] = responseData.responseValue["district"];
        customerResponse["state"] = responseData.responseValue["state"];
      }
    });

    updateData.map((personDefinition) => {
      if (
        personDefinition.fieldName !== "pincode-district-state" &&
        personDefinition.fieldName !== "time_spent_on_address" &&
        personDefinition.fieldName !== "address_confirmation" &&
        personDefinition.fieldName !== "remarks_on_address"
      ) {
        submitDefinitions.push({
          fieldName: personDefinition.fieldName,
        });
      }
    });

    setSubmitDefinitions(submitDefinitions);

    newData.map((value) => {
      if (value.responseValue !== "") {
        responseValueCount = responseValueCount + 1;
      }
      if ("remarks" in value) {
        remarksValueCount = remarksValueCount + 1;
      }
    });
    if (responseValueCount === 0) {
      editHandler();
    } else if (remarksValueCount !== 0) {
      editHandler();
    } else {
      setSlicedResponse(newData);
      setPds(pincodeDistrictStateData[0]);
      setCustomerResponse(customerResponse);
      setLoading(false);
    }
  };

  const fetchCustomerKyc = async () => {
    const customerKycParams = {
      msisdn: mobileNumber,
      kycId: ACTIVE_KYC_ID,
    };

    const { data, status, error } = await getCustomerKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data.statusCode === "404") {
      editHandler();
    } else if (status === 200 && data) {
      parseData(data.data);
    }
  };

  const nextHandler = async () => {
    setLoading(true);
    let bodyData = {
      msisdn: mobileNumber,
      profile_data: {},
    };
    submitDefinitions.map((field) => {
      const { fieldName } = field;
      bodyData["profile_data"][fieldName] =
        customerResponse[fieldName] === "true"
          ? "Yes"
          : customerResponse[fieldName] === "false"
          ? "No"
          : customerResponse[fieldName];
    });
    const { status, data, error } = await profileUpdate(bodyData);
    if (error) {
      toast.error(error.message);
    } else if (status === 200 && data) {
      updateHandler();
    }
  };

  const updateHandler = async () => {
    let bodyValues = {};
    submitDefinitions.map((field) => {
      const { fieldName } = field;
      bodyValues[fieldName] = customerResponse[fieldName];
    });
    bodyValues["address_details_confirmed"] = true;

    let urlParameters = {
      uuid: workFlowActionId,
    };

    const { status, error } = await getworkFlowInteractionOutcome(
      urlParameters,
      bodyValues
    );
    if (error) {
      toast.error(error);
    } else if (status == 200) {
      getNextActionPage().then((response) => {
        if (response.status == true) {
          setLoading(false);
          toast.success("Info updated successfully");
          navigate(response.screen);
        }
      });
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <Stepper currentStep={4} />
        <div className="content-container">
          <div className="common-heading">
            <h1>{packageTitle}</h1>
            <button
              type="button"
              onClick={editHandler}
              className="btn btn-outline-primary"
            >
              <img src={edit} alt="edit" />
              edit
            </button>
          </div>
          <div className="list-section-wrapper wrapper-section">
            {
              <ul>
                {slicedResponse.map((field, index) => {
                  switch (field.fieldDisplayName) {
                    case "Pin Code":
                      return (
                        <li key={index}>
                          <label>{field.fieldDisplayName}</label>
                          <span>
                            {pds.pincode === "" || pds.pincode === undefined
                              ? "-"
                              : pds.pincode}
                          </span>
                        </li>
                      );
                    case "District":
                      return (
                        <li key={index}>
                          <label>{field.fieldDisplayName}</label>
                          <span>
                            {pds.district === "" || pds.district === undefined
                              ? "-"
                              : pds.district}
                          </span>
                        </li>
                      );
                    case "State":
                      return (
                        <li key={index}>
                          <label>{field.fieldDisplayName}</label>
                          <span>
                            {pds.state === "" || pds.state === undefined
                              ? "-"
                              : pds.state}
                          </span>
                        </li>
                      );

                    default:
                      return (
                        <li key={index}>
                          <label>{field.fieldDisplayName}</label>
                          <span>
                            {field.responseValue === "" ||
                            field.responseValue === undefined
                              ? "-"
                              : field.responseValue}
                          </span>
                        </li>
                      );
                  }
                })}
              </ul>
            }
          </div>
          <div className="check-section">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="details-correct"
                onClick={() => setAgree(!agree)}
              />
              <label className="form-check-label" htmlFor="details-correct">
                I confirm my address is correct
              </label>
            </div>
          </div>
        </div>
        <div className="button-section">
          <div>
            <button
              type="button"
              onClick={previousHandler}
              className="btn btn-outline-primary"
            >
              Previous
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={nextHandler}
              disabled={agree}
              className="btn btn-primary"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressDetails;
