import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Loader from "../../components/loader";

import "./mandate.css";

import edit from "../../assets/images/edit.svg";

import { ACTIVE_KYC_ID_MANDATE } from "../../constants";
import { getCookie } from "../../utils/cookie-helper";
import { getCustomerKyc } from "../../services/kyc-service";
import {
  getworkFlowAllTransactions,
  getworkFlowInteractionOutcome,
  profileUpdate,
} from "../../services/workflow-service";
import { DecryptData, getNextActionPage } from "../../utils/application-helper";
import { responseKyc } from "../../services/kyc-service";

const MandateDetails = ({ editHandler, updatedValues }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [agree, setAgree] = useState(true);
  const [workFlowActionId, setWorkFlowActionId] = useState("");
  const [updatedResponse, setUpdatedResponse] = useState([]);
  const [packageTitle, setPackageTitle] = useState("");
  const [submitDefinitions, setSubmitDefinitions] = useState([]);
  const [customerResponse, setCustomerResponse] = useState({});
  const mobileNumber = DecryptData(getCookie("phone"));

  useEffect(() => {
    fetchAllWorkFlowActions();
    fetchCustomerKyc();
  }, []);

  const previousHandler = () => {
    navigate("/summary");
  };

  const parseData = (responseData) => {
    let responseValueCount = 0;
    let newData = [];
    let submitDefinitions = [];
    let customerResponse = {};
    const packageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "61e8fc0e944c74494b831168"
    );
    let updateData = packageId.children;
    setPackageTitle(packageId.packageTitle);

    updateData.map((responseData) => {
      if (responseData.fieldName !== "time_spent_on_mandate") {
        newData.push(responseData);
        submitDefinitions.push({
          fieldName: responseData.fieldName,
          displayType: responseData.displayType,
        });
        customerResponse[responseData.fieldName] = responseData.responseValue;
      }
    });

    newData.map((value) => {
      if (value.responseValue !== "") {
        responseValueCount = responseValueCount + 1;
      }
    });
    if (responseValueCount <= 2) {
      editHandler();
    } else {
      setUpdatedResponse(newData);
      setSubmitDefinitions(submitDefinitions);
      setCustomerResponse(customerResponse);
      setLoading(false);
    }
  };

  const fetchCustomerKyc = async () => {
    const customerKycParams = {
      msisdn: mobileNumber,
      kycId: ACTIVE_KYC_ID_MANDATE,
    };

    const { data, status, error } = await getCustomerKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data.statusCode === "404") {
      editHandler();
    } else if (status === 200 && data) {
      parseData(data.data);
    }
  };

  const fetchAllWorkFlowActions = async () => {
    const uuidparams = {
      uuid: DecryptData(getCookie("workflow_id")),
    };

    const { data, status, error } = await getworkFlowAllTransactions(
      uuidparams
    );
    if (error) {
      console.log(error);
    } else if (status == 200 && data) {
      findWorkFlowActionId(data.actions);
    }
  };

  const findWorkFlowActionId = (responseData) => {
    const containerInfo = responseData.find(
      (info) => info.action == "get_mandate"
    );
    const containerId = containerInfo.uuid;
    setWorkFlowActionId(containerId);
  };

  const profileUpdateHandler = async () => {
    let bodyData = {
      msisdn: mobileNumber,
      profile_data: {},
    };
    submitDefinitions.map((field) => {
      const { fieldName } = field;
      bodyData["profile_data"][fieldName] =
        customerResponse[fieldName] === "true"
          ? "Yes"
          : customerResponse[fieldName] === "false"
          ? "No"
          : customerResponse[fieldName];
    });
    const { status, data, error } = await profileUpdate(bodyData);
    if (error) {
      toast.error(error.message);
    } else if (status === 200 && data) {
      getWorkFlowInteractionOutcomeFunction();
    }
  };

  const getWorkFlowInteractionOutcomeFunction = async () => {
    let bodyValues = {};
    submitDefinitions.map((field) => {
      const { fieldName, displayType } = field;
      if (displayType === "SLIDER") {
        bodyValues[fieldName] = updatedValues[fieldName][0];
      } else {
        bodyValues[fieldName] = updatedValues[fieldName];
      }
    });
    bodyValues["bank_details_confirmed"] = true;

    let urlParameters = {
      uuid: workFlowActionId,
    };

    const { status, error } = await getworkFlowInteractionOutcome(
      urlParameters,
      bodyValues
    );
    if (error) {
      toast.error(error);
    } else if (status == 200) {
      getNextActionPage().then((response) => {
        if (response.status == true) {
          setLoading(false);
          toast.success("Info updated successfully");
          navigate(response.screen);
        }
      });
    }
  };

  const nextHandler = async () => {
    setLoading(true);
    let bodyResponse = {};
    bodyResponse["id"] = ACTIVE_KYC_ID_MANDATE;
    bodyResponse["createdBy"] = mobileNumber;
    bodyResponse["status"] = "SUBMITTED";

    const { status, error } = await responseKyc(bodyResponse);
    if (error) {
      console.log(error);
    } else if (status == 200) {
      profileUpdateHandler();
    }
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <Stepper currentStep={12} />
        <div className="content-container">
          <div className="common-heading">
            <h1>{packageTitle}</h1>
            <button
              type="button"
              onClick={editHandler}
              className="btn btn-outline-primary"
            >
              <img src={edit} alt="edit" />
              edit
            </button>
          </div>
          <div className="list-section-wrapper  mandate-screen">
            <ul>
              {updatedResponse.map((field, index) => {
                return (
                  <li key={index}>
                    <label>{field.fieldDisplayName}</label>
                    <span>
                      {field.responseValue === "" ||
                      field.responseValue === undefined
                        ? "-"
                        : field.fieldName === "bank_account_number"
                        ? field.responseValue.slice(0, -4) + "XXXX"
                        : field.fieldName === "mandateAmount"
                        ? "₹ " + field.responseValue.toFixed(2)
                        : field.responseValue}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="check-section">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="details-correct"
                onClick={() => setAgree(!agree)}
              />
              <label className="form-check-label" htmlFor="details-correct">
                I confirm my account is correct
              </label>
            </div>
          </div>
        </div>
        <div className="button-section">
          <div>
            <button
              type="button"
              onClick={previousHandler}
              className="btn btn-outline-primary"
            >
              Previous
            </button>
          </div>
          <div>
            <button
              type="button"
              disabled={agree}
              onClick={nextHandler}
              className="btn btn-primary"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MandateDetails;
