import React from "react";
import moment from "moment";

const PersonalDetails = ({ personalDetails, personalPackageTitle }) => {
  return (
    <div className="list-section-wrapper personal-screen overall-summary-screen">
      <div className="summary-title">
        <label>{personalPackageTitle}</label>
      </div>
      <ul>
        {personalDetails.map((field) => {
          return (
            <li key={field.fieldId}>
              <label>{field.fieldDisplayName}</label>
              <span>
                {field.responseValue === "" || field.responseValue === undefined
                  ? "-"
                  : field.type === "CALENDAR"
                  ? moment.utc(field.responseValue).format("MM-DD-YYYY")
                  : field.responseValue}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PersonalDetails;
