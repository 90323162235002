import React from "react";

import "./loanInfo.css";

const LoanSummary = ({ amount, duration, tenureEMI, applicationFee }) => {
  return (
    <div className="loan-summary">
      <div className="loan-summary-notes">
        <h5>Loan Summary</h5>
        <span>
          Upto {applicationFee === undefined ? 0 : applicationFee}% application
          fee
        </span>
      </div>
      <div className="loan-summary-details">
        <ul>
          <li>
            <label>Loan Amount</label>
            <span>$ {amount}</span>
          </li>
          <li>
            <label>EMI Amount</label>
            <span>$ {tenureEMI === undefined ? 0 : tenureEMI.emi}</span>
          </li>
          <li>
            <label>Interest rate</label>
            <span>{tenureEMI === undefined ? 0 : tenureEMI.interestRate}%</span>
          </li>
          <li>
            <label>Tenure in Months</label>
            <span>{duration === undefined ? 0 : +duration}</span>
          </li>

          <li>
            <label>Total Interest</label>
            <span>
              ${" "}
              {tenureEMI === undefined
                ? 0
                : (tenureEMI.emi * duration - amount).toFixed(2)}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LoanSummary;
