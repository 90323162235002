// Create all application level helper methods.

import { getCookie } from "./cookie-helper";
import CryptoJS from "crypto-js";
import { SECRET_PASS } from "../constants";
import { getworkFlowAllTransactions } from "../services/workflow-service";

export const getNextActionPage = () => {
  const workFlowUUID = {
    uuid: DecryptData(getCookie("workflow_id")),
  };

  return getworkFlowAllTransactions(workFlowUUID).then((response) => {
    const interactionResponseData = response.data;
    if (interactionResponseData.status === "running") {
      if (interactionResponseData.actions.length > 0) {
        const interactionActions = interactionResponseData.actions;

        switch (interactionActions[0].action) {
          case "show_reject_screen":
            return { status: true, screen: "/loan-rejected" };
          case "get_loan_amount":
            return { status: true, screen: "/loan-info" };
          case "choose_loan_journey":
            return { status: true, screen: "/home" };
          case "consumer_app_journey":
            return { status: true, screen: "/customer-app" };
          case "loan_service_menu":
            return { status: true, screen: "/loan-service" };
          case "loan_simulator":
            return {
              status: true,
              screen: "/personal-loan-simulator",
            };
          case "consumer_loan_journey":
            return {
              status: true,
              screen: "/consumer-loan-simulator",
            };
          case "previous_loan_details":
            return {
              status: true,
              screen: "/previous-loan-details",
            };
          case "get_personal_info":
            return { status: true, screen: "/personal-info" };
          case "get_address":
            return { status: true, screen: "/address" };
          case "get_documents":
            return { status: true, screen: "/documents" };
          case "show_loan_summary":
            return { status: true, screen: "/summary" };
          case "get_mandate":
            return { status: true, screen: "/mandate" };
          case "get_review":
            return { status: true, screen: "/review" };
          case "get_additional_info":
            return { status: true, screen: "/additional-info" };
          case "do_personal_discussion":
            return { status: true, screen: "/personal-discussion" };
          case "get_business_details":
            return { status: true, screen: "/business-info" };
          case "get_personal_income":
            return { status: true, screen: "/personal-income" };
          case "get_lifestyle_details":
            return { status: true, screen: "/lifestyle-details" };
          case "reject_lead":
            return { status: true, screen: "/loan-rejected" };
          case "show_loan_agreement":
            return { status: true, screen: "/loan-agreement" };
          default:
            return { status: false, screen: null };
        }
      }
    } else if (
      interactionResponseData.status === "finished" &&
      interactionResponseData.actions.length === 0
    ) {
      return { status: true, screen: "/thankyou" };
    } else if (interactionResponseData.status === "failed") {
      return { status: true, screen: "/failed" };
    } else if (interactionResponseData.actions.length === 0) {
      return { status: true, screen: "/failed" };
    } else {
      return { status: false, screen: null };
    }
  });
};

export const checkMandatory = (kycDefinition, fieldType) => {
  let isMandatory = false;

  kycDefinition &&
    kycDefinition.find((field) => {
      if (field.fieldName === fieldType) {
        isMandatory = field.mandatory;
      }
    });
  return isMandatory;
};

export const fetchAllWorkFlowActions = async (action_name) => {
  const uuidparams = {
    uuid: DecryptData(getCookie("workflow_id")),
  };

  const { data, status, error } = await getworkFlowAllTransactions(uuidparams);
  if (error) {
    console.log(error);
  } else if (status == 200 && data) {
    const responseData = data.actions;
    const containerInfo = responseData.find(
      (info) => info.action == action_name
    );
    return { status: true, value: containerInfo.uuid };
  }
};

export const numberWithCommas = (x) => {
  return x.toString().split(".")[0].length > 3
    ? x
        .toString()
        .substring(0, x.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        "," +
        x.toString().substring(x.toString().split(".")[0].length - 3)
    : x.toString();
};

export const EncryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_PASS).toString();
};

export const DecryptData = (data) => {
  if (data !== "" && data !== "null" && data !== null) {
    const decrypted = CryptoJS.AES?.decrypt(data, SECRET_PASS);
    if (decrypted) {
      try {
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      } catch (e) {
        return null;
      }
    }
  }
  return "";
};
