import React from "react";

const InputGroup = ({
  fieldName,
  display,
  mandatory,
  gpError,
  handleGroupChange,
  gpValues,
  gpIndex,
}) => {
  return (
    <div>
      <label>
        {display} <span className="red">{mandatory === true ? "*" : ""}</span>
      </label>
      <input
        type="text"
        name={fieldName}
        className={
          gpError[fieldName][gpIndex]
            ? "form-control border-red"
            : "form-control"
        }
        value={gpValues[fieldName][gpIndex] ? gpValues[fieldName][gpIndex] : ""}
        onChange={(e) => handleGroupChange(gpIndex, fieldName, e.target.value)}
      />
    </div>
  );
};

export default InputGroup;
