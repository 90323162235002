import React, { useState, useEffect } from "react";

import moment from "moment";

import {
  getKycDefinition,
  getCustomerKyc,
  submitKyc,
} from "../../services/kyc-service";

import { getCookie } from "../../utils/cookie-helper";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Error from "../../components/error";
import Loader from "../../components/loader";

import "./business-info.css";
import { ACTIVE_KYC_ID } from "../../constants";
import { toast } from "react-toastify";
import Input from "../../components/input";
import DateInput from "../../components/dateInput";
import Select from "../../components/select";
import NumberInput from "../../components/numberInput";
import { DecryptData } from "../../utils/application-helper";

const BusinessInfoEdit = (props) => {
  const [formValues, setFormValues] = useState({});
  const [showError, setShowError] = useState({});
  const [infoDefinition, setInfoDefinition] = useState([]);
  const [updatedRemarksData, setUpdatedRemarksData] = useState([]);
  const [previousValues, setPreviousValues] = useState({});
  const [isReadMore, setIsReadMore] = useState({});
  const [versionData, setVersionData] = useState(0);
  const [packageTitle, setPackageTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitDefinitions, setSubmitDefinitions] = useState([]);
  const [errMsg, setErrMsg] = useState();
  const [businessInfo, setBusinessInfo] = useState([]);
  const [revenueInfo, setRevenueInfo] = useState([]);
  const [expenseInfo, setExpenseInfo] = useState([]);
  const [assetInfo, setAssetInfo] = useState([]);
  const [debtInfo, setDebtInfo] = useState([]);
  const [reserveInfo, setReserveInfo] = useState([]);

  const params = {
    id: ACTIVE_KYC_ID,
  };

  const mobileNumber = DecryptData(getCookie("phone"));

  const handleChange = (e) => {
    setErrMsg();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setShowError({
      ...showError,
      [name]: false,
    });
  };

  useEffect(() => {
    let tempEmployeeExpense =
      Number(formValues.no_of_employees) *
      Number(formValues.avg_monthly_salary_employees);
    setFormValues({
      ...formValues,
      total_employee_expenses_monthly: tempEmployeeExpense,
    });
  }, [formValues.no_of_employees, formValues.avg_monthly_salary_employees]);

  useEffect(() => {
    let tempMonthlyExpense =
      Number(formValues.avg_payout_pm_suppliers) +
      Number(formValues.avg_interest_per_month) +
      Number(formValues.avg_tax_per_month) +
      formValues.total_employee_expenses_monthly;
    setFormValues({
      ...formValues,
      total_monthly_expenses: tempMonthlyExpense,
    });
  }, [
    formValues.avg_payout_pm_suppliers,
    formValues.avg_interest_per_month,
    formValues.avg_tax_per_month,
    formValues.total_employee_expenses_monthly,
  ]);

  useEffect(() => {
    let tempTotal =
      Number(formValues.cash_in_hand) +
      Number(formValues.receivables) +
      Number(formValues.ppe_value);
    setFormValues({ ...formValues, total_assets: tempTotal });
  }, [formValues.cash_in_hand, formValues.receivables, formValues.ppe_value]);

  useEffect(() => {
    let tempDebt =
      Number(formValues.current_liabilities) +
      Number(formValues.long_term_debt_value);
    setFormValues({ ...formValues, total_debt: tempDebt });
  }, [formValues.current_liabilities, formValues.long_term_debt_value]);

  useEffect(() => {
    let tempReserves =
      Number(formValues.owners_equity) +
      Number(formValues.value_of_rs) +
      Number(formValues.total_debt);
    setFormValues({
      ...formValues,
      total_debt_reserves_surplus: tempReserves,
    });
  }, [formValues.owners_equity, formValues.value_of_rs, formValues.total_debt]);

  const submitHandler = () => {
    let message;
    let errCount = 0;
    for (let i = 0; i < infoDefinition.length; i++) {
      let testRegEx;
      let element = infoDefinition[i];
      const { fieldName, fieldDisplayName, mandatory, validation } = element;
      if (validation !== undefined) {
        testRegEx = new RegExp(validation);
      }

      if (mandatory && !formValues[fieldName]) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} required!`;
        break;
      } else if (
        mandatory &&
        validation !== undefined &&
        !testRegEx.test(formValues[fieldName])
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} not matching!`;
        break;
      } else if (
        updatedRemarksData[fieldName] !== undefined &&
        previousValues[fieldName] === formValues[fieldName]
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `Please provide another ${fieldDisplayName}`;
      } else if (mandatory && fieldName === "date_of_birth") {
        const val = moment(formValues[fieldName], "yyyy-MM-DD").year();
        if (val > 1998) {
          errCount = 1;
          setShowError({ ...showError, [fieldName]: true });
          message = "You should be 24 years & above to apply for loan";
        }
      }
    }
    setErrMsg(message);
    if (errCount === 0) {
      createOrUpdateKyc();
    }
  };

  useEffect(() => {
    fetchKycDefinition();
  }, []);

  const fetchKycDefinition = async () => {
    const { data, status, error } = await getKycDefinition(params);
    if (error) {
      console.log(error);
    } else if (status === 200 && data) {
      const responseKycDefinitionData = data.data;

      let infoDefinition = [];

      let submitDefinitions = [];

      let businessInfo = [];

      let revenueInfo = [];

      let expenseInfo = [];

      let assetInfo = [];

      let debtInfo = [];

      let reserveInfo = [];

      let showError = {};

      let formValues = {};

      const packageId = responseKycDefinitionData.packagesDTOs.find(
        (packageDef) => packageDef.id === "62020b1bc9475e2c630a597b"
      );

      const packageDTOs = packageId.children;
      setPackageTitle(packageId.packageTitle);

      packageDTOs.map((personDefinition) => {
        infoDefinition.push({
          fieldId: personDefinition.id,
          fieldName: personDefinition.fieldName,
          fieldDisplayName: personDefinition.fieldDisplayName,
          editable: personDefinition.editable,
          mandatory: personDefinition.mandatory,
          type: personDefinition.type,
          options: personDefinition.options,
          multiQuestions: personDefinition.multiQuestions,
          validation: personDefinition.validation,
        });
        showError[personDefinition.fieldName] = false;

        if (
          personDefinition.fieldName === "business_name" ||
          personDefinition.fieldName === "date_of_establishment"
        ) {
          businessInfo.push({
            fieldId: personDefinition.id,
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
          });
        }
        if (
          personDefinition.fieldName === "avg_monthly_turnover" ||
          personDefinition.fieldName === "total_monthly_business_income"
        ) {
          revenueInfo.push({
            fieldId: personDefinition.id,
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
          });
        }
        if (
          personDefinition.fieldName === "no_of_employees" ||
          personDefinition.fieldName === "avg_monthly_salary_employees" ||
          personDefinition.fieldName === "avg_payout_pm_suppliers" ||
          personDefinition.fieldName === "avg_interest_per_month" ||
          personDefinition.fieldName === "avg_tax_per_month" ||
          personDefinition.fieldName === "total_employee_expenses_monthly" ||
          personDefinition.fieldName === "total_monthly_expenses"
        ) {
          expenseInfo.push({
            fieldId: personDefinition.id,
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
          });
        }
        if (
          personDefinition.fieldName === "cash_in_hand" ||
          personDefinition.fieldName === "receivables" ||
          personDefinition.fieldName === "ppe_value" ||
          personDefinition.fieldName === "total_assets"
        ) {
          assetInfo.push({
            fieldId: personDefinition.id,
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
          });
        }
        if (
          personDefinition.fieldName === "current_liabilities" ||
          personDefinition.fieldName === "long_term_debt_value" ||
          personDefinition.fieldName === "total_debt"
        ) {
          debtInfo.push({
            fieldId: personDefinition.id,
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
          });
        }
        if (
          personDefinition.fieldName === "owners_equity" ||
          personDefinition.fieldName === "value_of_rs" ||
          personDefinition.fieldName === "total_debt_reserves_surplus"
        ) {
          reserveInfo.push({
            fieldId: personDefinition.id,
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
          });
        }
      });

      packageDTOs.map((personDefinition) => {
        submitDefinitions.push({
          fieldName: personDefinition.fieldName,
        });
      });

      setShowError(showError);
      setFormValues(formValues);

      setBusinessInfo(businessInfo);
      setRevenueInfo(revenueInfo);
      setExpenseInfo(expenseInfo);
      setAssetInfo(assetInfo);
      setDebtInfo(debtInfo);
      setReserveInfo(reserveInfo);

      setInfoDefinition(infoDefinition);
      setVersionData(responseKycDefinitionData.version);

      setSubmitDefinitions(submitDefinitions);
      fetchCustomerKyc(formValues);
    }
  };

  const createOrUpdateKyc = async () => {
    let bodyData = {
      kycId: ACTIVE_KYC_ID,
      created_by: DecryptData(getCookie("phone")),
      data: {},
      version: versionData,
    };

    submitDefinitions.map((field) => {
      const { fieldName } = field;
      bodyData["data"][fieldName] = formValues[fieldName];
    });

    const { status, data, error } = await submitKyc(bodyData);
    if (error) {
      toast.error(error.message);
    } else if (status === 200 && data) {
      props.updateHandler(formValues);
    }
  };

  const toggleReadMore = (fieldName) => {
    setIsReadMore({ ...isReadMore, [fieldName]: !isReadMore[fieldName] });
  };

  const parseCustomerData = (responseData, formValues) => {
    let remarksData = {};
    let isReadMore = {};

    const packageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "62020b1bc9475e2c630a597b"
    );

    packageId.children.map((element) => {
      let { displayType, responseValue, remarks, fieldName } = element;
      if (displayType === "SLIDER" && responseValue !== undefined) {
        formValues[fieldName][0] = responseValue;
      } else if (displayType !== "SLIDER") {
        formValues[fieldName] = responseValue;
      }
      remarksData[fieldName] = remarks;
      if (element.remarks !== undefined && element.remarks.length > 45) {
        isReadMore[element.fieldName] = true;
      } else {
        isReadMore[element.fieldName] = false;
      }
    });

    setFormValues(formValues);
    setPreviousValues(formValues);
    setUpdatedRemarksData(remarksData);
    setIsReadMore(isReadMore);
    setLoading(false);
  };

  const fetchCustomerKyc = async (formValues) => {
    const customerKycParams = {
      msisdn: mobileNumber,
      kycId: ACTIVE_KYC_ID,
    };

    const { data, status, error } = await getCustomerKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data) {
      const responseData = data.data;
      if (responseData !== undefined) {
        parseCustomerData(responseData, formValues);
      }
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <div className="content-container">
          <Stepper currentStep={10} />
          <div className="common-heading">
            <h1>{packageTitle}</h1>
          </div>

          <p className="text-center">Dynamic Values</p>
          <div className="list-section-wrapper">
            <ul className="business-header">
              {businessInfo.map((formField, index) => {
                switch (formField.type) {
                  case "TEXT":
                    return (
                      <li key={index}>
                        <Input
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );
                  case "CALENDAR":
                    return (
                      <li key={index}>
                        <DateInput
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );
                  case "RADIO":
                    return (
                      <li key={index}>
                        <Select
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                          options={formField.options}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );
                  case "NUMBER":
                    return (
                      <li key={index}>
                        <NumberInput
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                          options={formField.options}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );

                  default:
                    break;
                }
              })}
            </ul>
            <div className="row business-expenses">
              <div className="col-md-12">
                <p className="business-heading">Revenue details</p>
                <ul className="revenue-details">
                  {revenueInfo.map((formField, index) => {
                    switch (formField.type) {
                      case "TEXT":
                        return (
                          <li key={index}>
                            <Input
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "CALENDAR":
                        return (
                          <li key={index}>
                            <DateInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "RADIO":
                        return (
                          <li key={index}>
                            <Select
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "NUMBER":
                        return (
                          <li key={index}>
                            <NumberInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );

                      default:
                        break;
                    }
                  })}
                </ul>
              </div>
              <div className="col-md-12">
                <p className="business-heading">Expense details</p>
                <ul className="expense-details">
                  {expenseInfo.map((formField, index) => {
                    switch (formField.type) {
                      case "TEXT":
                        return (
                          <li key={index}>
                            <Input
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "CALENDAR":
                        return (
                          <li key={index}>
                            <DateInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "RADIO":
                        return (
                          <li key={index}>
                            <Select
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "NUMBER":
                        return (
                          <li key={index}>
                            <NumberInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );

                      default:
                        break;
                    }
                  })}
                </ul>
              </div>
            </div>
            <div className="row business-footer">
              <div className="col-md-12 right-border">
                <p className="business-heading">Asset details</p>
                <ul className="asset-details">
                  {assetInfo.map((formField, index) => {
                    switch (formField.type) {
                      case "TEXT":
                        return (
                          <li key={index}>
                            <Input
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "CALENDAR":
                        return (
                          <li key={index}>
                            <DateInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "RADIO":
                        return (
                          <li key={index}>
                            <Select
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "NUMBER":
                        return (
                          <li key={index}>
                            <NumberInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );

                      default:
                        break;
                    }
                  })}
                </ul>
              </div>
              <div className="col-md-12 right-border">
                <p className="business-heading">Debt details</p>
                <ul className="debt-details">
                  {debtInfo.map((formField, index) => {
                    switch (formField.type) {
                      case "TEXT":
                        return (
                          <li key={index}>
                            <Input
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "CALENDAR":
                        return (
                          <li key={index}>
                            <DateInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "RADIO":
                        return (
                          <li key={index}>
                            <Select
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "NUMBER":
                        return (
                          <li key={index}>
                            <NumberInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );

                      default:
                        break;
                    }
                  })}
                </ul>
              </div>
              <div className="col-md-12">
                <p className="business-heading">Reserves and Surplus</p>
                <ul className="reserve-details">
                  {reserveInfo.map((formField, index) => {
                    switch (formField.type) {
                      case "TEXT":
                        return (
                          <li key={index}>
                            <Input
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "CALENDAR":
                        return (
                          <li key={index}>
                            <DateInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "RADIO":
                        return (
                          <li key={index}>
                            <Select
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "NUMBER":
                        return (
                          <li key={index}>
                            <NumberInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );

                      default:
                        break;
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>

          {errMsg && <Error message={errMsg} />}
        </div>
        <div className="button-section">
          <div>
            <button
              type="button"
              onClick={props.discardChangesHandler}
              className="btn btn-outline-primary"
            >
              Discard change
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={submitHandler}
              className="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfoEdit;
