import React from "react";

import { KYC_ACCESS_TOKEN } from "../../constants";

import circleProfile from "../../assets/images/circleProfile.png";

const LoanHeader = ({ status, credit, name, selfie }) => {
  let selfieUrl = "";
  if (selfie !== undefined) {
    selfieUrl = `https://kyc.yabx.co/kyc2/getDocument?fileName=${selfie}&Authorization=Bearer${KYC_ACCESS_TOKEN}`;
  }

  return (
    <div className="loan-service-header list-section-wrapper row">
      <div className="profile-image col-md-2 m-auto">
        {selfie === undefined ? (
          <img src={circleProfile} alt="user" />
        ) : (
          <img src={selfieUrl} alt="user" />
        )}
      </div>
      <div className="col-md-10 loan-profile">
        <ul className="row">
          <li className="col-md-6">
            <label>Name</label>
            <span>
              {name.firstName === undefined &&
              name.middleName === undefined &&
              name.lastName === undefined
                ? "-"
                : ""}
              {name.firstName !== undefined && name.firstName}{" "}
              {name.middleName && name.middleName}{" "}
              {name.lastName && name.lastName}
            </span>
          </li>
          <li className="col-md-6">
            <label>Current loan status</label>
            <span>
              <span className={status === true ? "active" : ""}>
                {status === true ? "ACTIVE" : "INACTIVE"}
              </span>
            </span>
          </li>
          <li className="col-md-6">
            <label>Available credit limit</label>
            <span> ₹ &ensp;{credit ? credit : "0"}</span>
          </li>
          <li className="col-md-6">
            <label>Last login date</label>
            <span>-</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LoanHeader;
