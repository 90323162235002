import React from "react";

import Header from "../../components/header";
import "./success.css";
import success from "../../assets/images/success.gif";

const SuccessScreen = () => {
  return (
    <div>
      <Header />
      <div className="main-container">
        <div className="content-container">
          <div className="success-wrapper">
            <img src={success} alt="" />
            <h2>CONGRATULATIONS!!!</h2>
            <div className="success_content">
              <p>
                Your loan of <span> र 45,000</span>{" "}
              </p>
              <p>
                {" "}
                will be disbursed to your bank account in the next 24-48 hours
              </p>
            </div>
          </div>
          <div className="range_details">
            <div className="range_values">
              <label>Tenure</label>
              <span>4 Years</span>
            </div>
            <div className="range_values">
              <label>Intrest rate</label>
              <span>17.5%</span>
            </div>
            <div className="range_values">
              <label>EMI per month</label>
              <span>₹ 20000.0</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessScreen;
