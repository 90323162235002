import React, { useState, useEffect } from "react";

import imageCompression from "browser-image-compression";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { getCookie } from "../../utils/cookie-helper";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Error from "../../components/error";
import Loader from "../../components/loader";

import {
  uploadKyc,
  getCustomerKyc,
  submitKyc,
  getKycDefinition,
  getResponseKyc,
} from "../../services/kyc-service";
import { getworkFlowInteractionOutcome } from "../../services/workflow-service";
import { ACTIVE_KYC_ID } from "../../constants";
import {
  getNextActionPage,
  fetchAllWorkFlowActions,
  DecryptData,
} from "../../utils/application-helper";

import "./additionalInfo.css";

import Input from "../../components/input";
import DateInput from "../../components/dateInput";
import Select from "../../components/select";
import NumberInput from "../../components/numberInput";
import AdditionalDocumentFile from "../../components/additionalDocuments";
import InputGroup from "../../components/group/Input";
import NumberInputGroup from "../../components/group/NumberInput";
import SelectGroup from "../../components/group/Select";
import DateInputGroup from "../../components/group/DateInput";
import DocumentFileGroup from "../../components/group/DocumentFile";

const AdditionalInfo = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [infoDefinition, setInfoDefinition] = useState([]);
  const [versionData, setVersionData] = useState(0);
  const [workFlowActionId, setWorkFlowActionId] = useState("");
  const [convertedTime, setConvertedTime] = useState();
  const [initial, setInitial] = useState();
  const [houseHoldGroup, setHouseHoldGroup] = useState([]);
  const [addGroup, setAddGroup] = useState([]);
  const [previousValues, setPreviousValues] = useState({});
  const [updatedRemarksData, setUpdatedRemarksData] = useState({});
  const [isReadMore, setIsReadMore] = useState({});
  const [packageTitle, setPackageTitle] = useState("");
  const [householdGroupId, setHouseHoldGroupId] = useState("");
  const [delHandler, setDelHandler] = useState();
  const [errMsg, setErrMsg] = useState();
  const [kycExt, setKycExt] = useState([]);
  const [extStr, setExtStr] = useState([]);
  const [addShow, setAddShow] = useState();
  const [addShowView, setAddShowView] = useState();
  const [addTempExt, setAddTempExt] = useState();
  const [formValues, setFormValues] = useState({});
  const [showError, setShowError] = useState({});
  const [gpValues, setGpValues] = useState();
  const [gpTempShow, setGpTempShow] = useState();
  const [gpTempShowView, setGpTempShowView] = useState();
  const [gpTempExt, setGpTempExt] = useState();
  const [gpKycExt, setGpKycExt] = useState();
  const [gpError, setGpError] = useState();
  const [gpErrMsg, setGpErrMsg] = useState();
  const [submitGroupDefinition, setSubmitGroupDefinition] = useState([]);
  const [fieldVariable, setFieldVariable] = useState([]);
  const [gpRemarks, setGpRemarks] = useState({});
  const [gpIsReadMore, setGpIsReadMore] = useState({});
  const [previousGpValues, setPreviousGpValues] = useState({});

  const mobileNumber = DecryptData(getCookie("phone"));

  useEffect(() => {
    fetchKycDefinition();
    fetchAllWorkKyc();
  }, []);

  useEffect(() => {
    var dt = new Date();
    var secs = dt.getSeconds() + 60 * (dt.getMinutes() + 60 * dt.getHours());
    setInitial(secs);
  }, []);

  const findFinalTime = (groupValueArray) => {
    var ft = new Date();
    var finalSecs =
      ft.getSeconds() + 60 * (ft.getMinutes() + 60 * ft.getHours());
    let timeDifference = finalSecs - initial;
    secondsToHms(timeDifference, groupValueArray);
  };

  const fetchAllWorkKyc = () => {
    fetchAllWorkFlowActions("get_additional_info").then((response) => {
      if (response.status === true) {
        setWorkFlowActionId(response.value);
      }
    });
  };

  const fetchCustomerKyc = async (
    apiResponses,
    addShow,
    addShowView,
    addTempExt,
    formValues,
    groupRemarksData
  ) => {
    const customerKycParams = {
      msisdn: mobileNumber,
      kycId: ACTIVE_KYC_ID,
    };

    const { data, status, error } = await getCustomerKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data) {
      const responseData = data.data;
      if (responseData !== undefined) {
        parseData(
          responseData,
          apiResponses,
          addShow,
          addShowView,
          addTempExt,
          formValues,
          groupRemarksData
        );
      }
    }
  };

  const parseData = (
    responseData,
    apiResponses,
    addShow,
    addShowView,
    addTempExt,
    formValues,
    groupRemarksData
  ) => {
    let newData = [];
    let remarksData = {};
    let isReadMore = {};

    const packageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "61fb8b31e0807412c1c9fe2f"
    );

    const data = packageId.children;

    packageId.children.map((element) => {
      const { fieldName, remarks, responseValue, displayType } = element;
      if (fieldName !== "time_spent_on_additional_details") {
        if (displayType === "CHECKMARK") {
          newData[fieldName] = (responseValue && responseValue) || false;
          formValues[fieldName] = (responseValue && responseValue) || false;
        } else {
          newData[fieldName] = (responseValue && responseValue) || "";
          formValues[fieldName] = (responseValue && responseValue) || "";
        }
        remarksData[fieldName] = remarks;
        if (element.remarks !== undefined && element.remarks.length > 45) {
          isReadMore[element.fieldName] = true;
        } else {
          isReadMore[element.fieldName] = false;
        }
      }
    });

    packageId.children.map((element) => {
      const { fieldName, type, responseValue } = element;
      if (type === "DOCUMENT") {
        if (responseValue === "") {
          addShow[fieldName] = true;
        } else {
          addShow[fieldName] = false;
        }
        addShowView[fieldName] = (responseValue && true) || false;
        addTempExt[fieldName] =
          (responseValue && responseValue.split(".").pop()) || "";
      }
    });

    setPreviousValues(newData);
    setFormValues(formValues);
    setUpdatedRemarksData(remarksData);
    setIsReadMore(isReadMore);
    setAddShow(addShow);
    setAddShowView(addShowView);
    setAddTempExt(addTempExt);

    const apiResponseValues = apiResponses;
    const groupRemarkValues = groupRemarksData["62415efd8f191b10452ac299"];
    let houseHoldGroup = [];
    const houseHoldId = data && data.find((group) => group.isArray === true);
    const groupIdValue = houseHoldId.id;
    let groupResponseValues = apiResponseValues[groupIdValue];

    setHouseHoldGroupId(houseHoldId.id);
    const groupChildren = houseHoldId.children;

    let gpValues = {};
    let previousGpValues = {};
    let gpError = {};
    let gpTempShow = {};
    let gpTempShowView = {};
    let gpTempExt = {};
    let gpKycExt = {};
    let gpRemarks = {};
    let gpIsReadMore = {};

    let fieldVariable = [];
    let fieldVariableId = [];

    groupChildren.map((groupDef) => {
      houseHoldGroup.push({
        fieldId: groupDef.id,
        fieldName: groupDef.fieldName,
        fieldDisplayName: groupDef.fieldDisplayName,
        editable: groupDef.editable,
        mandatory: groupDef.mandatory,
        type: groupDef.type,
        options: groupDef.options,
        multiQuestions: groupDef.multiQuestions,
        validation: groupDef.validation,
        displayType: groupDef.displayType,
      });
      fieldVariable.push(groupDef.fieldName);
      fieldVariableId.push(groupDef.id);

      if (groupDef.type === "DOCUMENT") {
        gpTempShow[groupDef.fieldName] = [];
        gpTempShowView[groupDef.fieldName] = [];
        gpTempExt[groupDef.fieldName] = [];
        gpKycExt[groupDef.fieldName] = [];
      }

      gpValues[groupDef.fieldName] = [];
      previousGpValues[groupDef.fieldName] = [];
      gpError[groupDef.fieldName] = [];
      gpRemarks[groupDef.id] = [];
      gpIsReadMore[groupDef.id] = [];
    });

    console.log("gpRemarks", gpRemarks);

    groupChildren.map((groupDef) => {
      if (groupDef.type === "DOCUMENT") {
        groupDef.fileTypes.map((file) => {
          gpKycExt[groupDef.fieldName].push(file.split(".").pop());
        });
      }
    });
    setHouseHoldGroup(houseHoldGroup);

    setSubmitGroupDefinition(houseHoldGroup);

    setFieldVariable(fieldVariable);

    let arrayCount = [];

    houseHoldGroup.map((element) => {
      if (groupResponseValues !== undefined) {
        groupResponseValues.map((values) => {
          let fieldElement = element.fieldName;
          let fieldElementId = element.fieldId;
          if (fieldElement in values) {
            if (values[fieldElement] !== "") {
              gpValues[fieldElement].push(values[fieldElement]);
              previousGpValues[fieldElement].push(values[fieldElement]);
              if (element.type === "DOCUMENT") {
                gpTempShow[fieldElement].push(false);
                gpTempShowView[fieldElement].push(true);
                gpTempExt[fieldElement].push(
                  values[fieldElement].split(".").pop()
                );
              }
            } else {
              gpValues[fieldElement].push("");
              previousGpValues[fieldElement].push("");
              if (element.type === "DOCUMENT") {
                gpTempShow[fieldElement].push(true);
                gpTempShowView[fieldElement].push(false);
                gpTempExt[fieldElement].push("");
              }
            }
            gpError[fieldElement].push(false);
            gpRemarks[fieldElementId].push(undefined);
            gpIsReadMore[fieldElementId].push(false);
          }
        });
      }
    });

    if (groupResponseValues !== undefined) {
      groupResponseValues.map((values, index) => {
        arrayCount.push(index);
      });
    }

    console.log("gpRemarkValues", groupRemarkValues);

    arrayCount.map((value) => {
      fieldVariableId.map((id) => {
        if (groupRemarkValues !== undefined) {
          if (id in gpRemarks) {
            gpRemarks[id][value] = groupRemarkValues[value][id];
            let test = groupRemarkValues[value][id];
            if (test !== undefined && test["customer"].length > 45) {
              gpIsReadMore[id][value] = true;
            }
          }
        }
      });
    });

    console.log("gpIsReadMore", gpIsReadMore);

    if (arrayCount.length === 0) {
      setAddGroup([houseHoldGroup]);
      houseHoldGroup.map((element) => {
        let fieldElement = element.fieldName;
        if (fieldElement === "household_item_sl_no") {
          gpValues[fieldElement].push(1);
        } else {
          gpValues[fieldElement].push("");
        }
        gpError[fieldElement].push(false);
        if (element.type === "DOCUMENT") {
          gpTempShow[fieldElement].push(true);
          gpTempShowView[fieldElement].push(false);
          gpTempExt[fieldElement].push("");
        }
      });
    } else {
      let tempGroupArr = [];
      arrayCount.map(() => {
        tempGroupArr.push(houseHoldGroup);
      });
      setAddGroup(tempGroupArr);
    }

    if (arrayCount.length === 0 || arrayCount.length === 1) {
      setDelHandler(false);
    } else {
      setDelHandler(true);
    }

    setGpValues(gpValues);
    setPreviousGpValues(previousGpValues);
    setGpError(gpError);
    setGpTempShow(gpTempShow);
    setGpTempShowView(gpTempShowView);
    setGpTempExt(gpTempExt);
    setGpKycExt(gpKycExt);
    setGpRemarks(gpRemarks);
    setGpIsReadMore(gpIsReadMore);
    setLoading(false);
  };

  const fetchResponseValues = async (
    addShow,
    addShowView,
    addTempExt,
    formValues
  ) => {
    const customerKycParams = {
      kycId: ACTIVE_KYC_ID,
      msisdn: mobileNumber,
    };

    const { data, status, error } = await getResponseKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data) {
      const responseData = data.data;
      if (responseData !== undefined) {
        const apiResponses = responseData.data;
        const groupRemarksData = responseData.remarks;
        fetchCustomerKyc(
          apiResponses,
          addShow,
          addShowView,
          addTempExt,
          formValues,
          groupRemarksData
        );
      }
    }
  };

  const fetchKycDefinition = async () => {
    const params = {
      id: ACTIVE_KYC_ID,
    };
    const { data, status, error } = await getKycDefinition(params);
    if (error) {
      console.log(error);
    } else if (status === 200 && data) {
      const responseKycDefinitionData = data.data;

      let infoDefinition = [];
      let submitDefinitions = [];
      let showError = {};
      let addShow = [];
      let addShowView = [];
      let addTempExt = [];
      let formValues = [];
      let kycExt = [];
      let extStr = [];

      const packageId = responseKycDefinitionData.packagesDTOs.find(
        (packageDef) => packageDef.id === "61fb8b31e0807412c1c9fe2f"
      );

      const packageDTOs = packageId.children;
      setPackageTitle(packageId.packageTitle);

      packageDTOs.map((personDefinition) => {
        if (
          personDefinition.modelType !== "group" &&
          personDefinition.fieldName !== "time_spent_on_additional_details"
        ) {
          infoDefinition.push({
            fieldId: personDefinition.id,
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
            displayType: personDefinition.displayType,
            sampleImageURL: personDefinition.sampleImageURL,
          });
          showError[personDefinition.fieldName] = false;
        }
        if (personDefinition.type === "DOCUMENT") {
          addShow[personDefinition.fieldName] = true;
          addShowView[personDefinition.fieldName] = false;
          addTempExt[personDefinition.fieldName] = "";
          kycExt[personDefinition.fieldName] = [];
          extStr[personDefinition.fieldName] = "";
        }
      });

      setAddShow(addShow);
      setAddShowView(addShowView);
      setAddTempExt(addTempExt);
      setKycExt(kycExt);

      packageDTOs.map((personDefinition) => {
        if (personDefinition.type === "DOCUMENT") {
          personDefinition.fileTypes.map((file) => {
            kycExt[personDefinition.fieldName].push(file.split(".").pop());
          });
        }
      });

      packageDTOs.map((personDefinition) => {
        if (
          personDefinition.modelType !== "group" &&
          personDefinition.fieldName !== "time_spent_on_additional_details"
        ) {
          submitDefinitions.push({
            fieldName: personDefinition.fieldName,
          });
          if (personDefinition.displayType === "CHECKMARK") {
            formValues[personDefinition.fieldName] = false;
          } else {
            formValues[personDefinition.fieldName] = "";
          }
        }
      });

      packageDTOs.map((personDefinition) => {
        if (personDefinition.type === "DOCUMENT") {
          extStr[personDefinition.fieldName] = kycExt[
            personDefinition.fieldName
          ]
            .toString()
            .replaceAll(",", "/");
        }
      });

      setExtStr(extStr);

      setInfoDefinition(infoDefinition);

      setShowError(showError);
      setVersionData(responseKycDefinitionData.version);
      fetchResponseValues(addShow, addShowView, addTempExt, formValues);
    }
  };

  const addRowHandler = () => {
    let tempArr = [];
    tempArr.push(...addGroup, houseHoldGroup);
    setAddGroup(tempArr);
    let count = tempArr.length - 1;

    addStateHandler(count);
    setDelHandler(true);
  };

  const addStateHandler = (count) => {
    let tempGpValues = { ...gpValues };
    let tempGpError = { ...gpError };
    let show = { ...gpTempShow };
    let showView = { ...gpTempShowView };
    let ext = { ...gpTempExt };

    houseHoldGroup.map((element) => {
      let fieldElement = element.fieldName;

      if (fieldElement === "household_item_sl_no") {
        tempGpValues[fieldElement].push(count + 1);
      } else {
        tempGpValues[fieldElement].push("");
      }
      tempGpError[fieldElement].push(false);
      if (element.type === "DOCUMENT") {
        show[fieldElement].push(true);
        showView[fieldElement].push(false);
        ext[fieldElement].push("");
      }
    });

    setGpValues(tempGpValues);
    setGpError(tempGpError);
    setGpTempShow(show);
    setGpTempShowView(showView);
    setGpTempExt(ext);
  };

  const delRowHandler = () => {
    let newArr = [...addGroup];

    newArr.pop();

    if (newArr.length === 1) {
      setDelHandler(false);
    } else {
      setDelHandler(true);
    }

    setAddGroup(newArr);
    removeStateHandler();
  };

  const removeStateHandler = () => {
    let tempGpValues = gpValues;
    let tempGpError = gpError;
    let show = gpTempShow;
    let showView = gpTempShowView;
    let ext = gpTempExt;

    houseHoldGroup.map((element) => {
      let fieldElement = element.fieldName;

      tempGpValues[fieldElement].pop();
      tempGpError[fieldElement].pop();
      if (element.type === "DOCUMENT") {
        show[fieldElement].pop();
        showView[fieldElement].pop();
        ext[fieldElement].pop();
      }
    });

    setGpValues(tempGpValues);
    setGpError(tempGpError);
    setGpTempShow(show);
    setGpTempShowView(showView);
    setGpTempExt(ext);
  };

  const toggleReadMore = (fieldName) => {
    setIsReadMore({ ...isReadMore, [fieldName]: !isReadMore[fieldName] });
  };

  const toggleGpReadMore = (fieldId, adGroupId) => {
    let tempIsReadMore = { ...gpIsReadMore };
    tempIsReadMore[fieldId][adGroupId] = !gpIsReadMore[fieldId][adGroupId];
    setGpIsReadMore(tempIsReadMore);
  };

  const handleGroupChange = (gpIndex, fieldName, fieldValue) => {
    let temp = { ...gpValues };
    let tempErr = { ...gpError };
    tempErr[fieldName][gpIndex] = false;
    setGpError(tempErr);
    setGpErrMsg("");
    temp[fieldName][gpIndex] = fieldValue;
    setGpValues(temp);
  };

  const handleGroupFileChange = async (index, fieldName, value) => {
    setLoading(true);
    let tempErr = { ...gpError };
    tempErr[fieldName][index] = false;
    setGpError(tempErr);
    setGpErrMsg("");
    const file = value[0];
    const fileName = file.name;
    const fileExtension = file.name.split(".").pop();

    const options = {
      maxSizeMB: 0.5,
      useWebWorker: true,
    };

    let compressedFile;
    var newFile;

    if (file.size > 500000 && ["jpg", "jpeg", "png"].includes(fileExtension)) {
      compressedFile = await imageCompression(file, options);
      newFile = new File([compressedFile], fileName);
    } else {
      newFile = file;
    }

    var householdExtension = newFile.name.split(".").pop();

    let tempExt = { ...gpTempExt };
    tempExt[fieldName][index] = householdExtension;
    setGpTempExt(tempExt);

    var formhouseholdProofData = new FormData();
    formhouseholdProofData.append("file", newFile);
    formhouseholdProofData.append("kycId", ACTIVE_KYC_ID);
    formhouseholdProofData.append("fieldId", fieldName);

    const { status, error, data } = await uploadKyc(formhouseholdProofData);
    if (error) {
      console.log(error);
      toast.error(error);
      toast.error("Please re-upload again");
    } else if (status === 200 && data) {
      let responseData = data.data.fileName;

      let tempValue = { ...gpValues };
      let tempShow = { ...gpTempShow };
      let tempShowView = { ...gpTempShowView };

      tempValue[fieldName][index] = responseData;
      tempShow[fieldName][index] = false;
      tempShowView[fieldName][index] = true;

      setGpValues(tempValue);
      setGpTempShow(tempShow);
      setGpTempShowView(tempShowView);

      setLoading(false);
    }
  };

  const groupFunctionHandler = () => {
    let groupValueArray = [];

    let tempArr = {};

    let keyLength = Object.keys(gpValues).length;

    addGroup.map((item, index) => {
      for (let i = 0; i < keyLength; i++) {
        const fieldVariableName = fieldVariable[i];
        tempArr[fieldVariableName] = gpValues[fieldVariableName][index];
      }
      groupValueArray.push(tempArr);
      tempArr = {};
    });

    findFinalTime(groupValueArray);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setShowError({
      ...showError,
      [name]: false,
    });
    setErrMsg();
  };

  const handleFileChange = async (value, fieldName) => {
    setLoading(true);
    setErrMsg();
    const file = value[0];
    const fileName = file.name;
    const fileExtension = file.name.split(".").pop();
    const options = {
      maxSizeMB: 0.5,
      useWebWorker: true,
    };

    let compressedFile;
    var newFile;

    if (file.size > 500000 && ["jpg", "jpeg", "png"].includes(fileExtension)) {
      compressedFile = await imageCompression(file, options);
      newFile = new File([compressedFile], fileName);
    } else {
      newFile = file;
    }
    var extension = newFile.name.split(".").pop();

    setAddTempExt({ ...addTempExt, [fieldName]: extension });

    var formImageData = new FormData();
    formImageData.append("file", newFile);
    formImageData.append("kycId", ACTIVE_KYC_ID);
    formImageData.append("fieldId", fieldName);

    const { status, error, data } = await uploadKyc(formImageData);
    if (error) {
      console.log(error);
    } else if (status === 200 && data) {
      setFormValues({ ...formValues, [fieldName]: data.data.fileName });
      setAddShow({ ...addShow, [fieldName]: false });
      setAddShowView({ ...addShowView, [fieldName]: true });
      setLoading(false);
    }
  };

  const submitHandler = () => {
    let errCount = 0;
    let message = "";
    for (let i = 0; i < infoDefinition.length; i++) {
      let element = infoDefinition[i];
      const { fieldName, fieldDisplayName, mandatory, type } = element;
      if (mandatory && !formValues[fieldName]) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} required!`;
        break;
      } else if (type === "DOCUMENT") {
        if (mandatory && !kycExt[fieldName].includes(addTempExt[fieldName])) {
          errCount = 1;
          setShowError({ ...showError, [fieldName]: true });
          message = `Please upload ${fieldDisplayName} in ${extStr[fieldName]}`;
          break;
        }
        if (
          updatedRemarksData[fieldName] !== undefined &&
          previousValues[fieldName] === formValues[fieldName]
        ) {
          errCount = 1;
          setShowError({ ...showError, [fieldName]: true });
          message = `Please provide another ${fieldDisplayName}`;
          break;
        }
      } else if (
        updatedRemarksData[fieldName] !== undefined &&
        previousValues[fieldName] === formValues[fieldName]
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `Please provide another ${fieldDisplayName}`;
        break;
      }
    }
    setErrMsg(message);
    if (errCount === 0) {
      groupValidationHandler();
    }
  };

  const groupValidationHandler = () => {
    let groupErrorCount = 0;
    let groupMessage = "";

    for (let i = 0; i < submitGroupDefinition.length; i++) {
      let element = submitGroupDefinition[i];
      let tempError = { ...gpError };
      const { fieldName, fieldDisplayName, fieldId, mandatory, type } = element;
      let valueElement = gpValues[fieldName];
      for (let j = 0; j < valueElement.length; j++) {
        if (mandatory && valueElement[j] === "") {
          groupErrorCount = 1;
          tempError[fieldName][j] = true;
          groupMessage = `${fieldDisplayName} for item ${j + 1} required`;
          setGpError(tempError);
          setGpErrMsg(groupMessage);
          return;
        }
        if (
          gpRemarks[fieldId][j] !== undefined &&
          previousGpValues[fieldName][j] === valueElement[j]
        ) {
          tempError[fieldName][j] = true;
          groupMessage = `Please provide another ${fieldDisplayName} for item ${
            j + 1
          }`;
          setGpError(tempError);
          setGpErrMsg(groupMessage);
          return;
        }
      }
      if (type === "DOCUMENT") {
        let valueExtension = gpTempExt[fieldName];
        for (let k = 0; k < valueExtension.length; k++) {
          if (mandatory && !gpKycExt[fieldName].includes(valueExtension[k])) {
            groupErrorCount = 1;
            let showExtensionformat = gpKycExt[fieldName]
              .toString()
              .replaceAll(",", "/");
            tempError[fieldName][k] = true;
            groupMessage = `Please upload ${fieldDisplayName} for item ${
              k + 1
            } in ${showExtensionformat} format`;
            setGpError(tempError);
            setGpErrMsg(groupMessage);
            return;
          }
        }
      }
    }

    if (groupErrorCount === 0) {
      groupFunctionHandler();
    }
  };

  const createOrUpdateKyc = async (groupValueArray) => {
    setLoading(true);
    let bodyData = {
      kycId: ACTIVE_KYC_ID,
      created_by: DecryptData(getCookie("phone")),
      data: {},
      version: versionData,
    };

    infoDefinition.map((field) => {
      const { fieldName } = field;
      bodyData["data"][fieldName] = formValues[fieldName];
    });

    bodyData["data"][householdGroupId] = groupValueArray;
    bodyData["data"]["time_spent_on_additional_details"] = convertedTime;

    const { status, data, error } = await submitKyc(bodyData);
    if (error) {
      toast.error(error.message);
    } else if (status === 200 && data) {
      updateHandler(bodyData);
    }
  };

  const updateHandler = async (bodyData) => {
    let { data } = bodyData;
    let bodyValues = {};
    infoDefinition.map((field) => {
      const { fieldName } = field;
      bodyValues[fieldName] = data[fieldName];
    });

    let urlParameters = {
      uuid: workFlowActionId,
    };

    const { status, error } = await getworkFlowInteractionOutcome(
      urlParameters,
      bodyValues
    );
    if (error) {
      toast.error(error);
    } else if (status == 200) {
      getNextActionPage().then((response) => {
        if (response.status == true) {
          setLoading(false);
          toast.success("Info updated successfully");
          navigate(response.screen);
        }
      });
    }
  };

  const secondsToHms = (time, groupValueArray) => {
    var date = new Date(null);
    date.setSeconds(time);
    let convertedTime = date.toISOString().substr(11, 8);
    setConvertedTime(convertedTime);
    createOrUpdateKyc(groupValueArray);
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <Stepper currentStep={6} />
        <div className="content-container">
          <div className="common-heading">
            <h1>{packageTitle}</h1>
          </div>
          <div className="additional-info-screen">
            <h2>Employment Details</h2>
            <div className="list-section-wrapper address-edit-screen">
              <ul>
                {infoDefinition.map((formField, index) => {
                  if (
                    formField.fieldName === "employer_name" ||
                    formField.fieldName === "current_employer_joining"
                  ) {
                    switch (formField.type) {
                      case "TEXT":
                        return (
                          <li key={index}>
                            <Input
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "CALENDAR":
                        return (
                          <li key={index}>
                            <DateInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "RADIO":
                        return (
                          <li key={index}>
                            <Select
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "NUMBER":
                        return (
                          <li key={index}>
                            <NumberInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );

                      default:
                        break;
                    }
                  }
                })}
              </ul>
            </div>
            <div className="document-upload-wrapper">
              {infoDefinition.map((formField, index) => {
                if (formField.fieldName === "employment_id_photo") {
                  return (
                    <div className="additional-document-wrapper" key={index}>
                      <AdditionalDocumentFile
                        fieldName={formField.fieldName}
                        display={formField.fieldDisplayName}
                        mandatory={formField.mandatory}
                        formValues={formValues}
                        showError={showError}
                        handleFileChange={handleFileChange}
                        options={formField.options}
                        fieldId={formField.fieldId}
                        addShow={addShow}
                        addShowView={addShowView}
                        addTempExt={addTempExt}
                        sampleImageURL={formField.sampleImageURL}
                      />
                      {updatedRemarksData[formField.fieldName] && (
                        <p className="remarks">
                          {isReadMore[formField.fieldName]
                            ? updatedRemarksData[formField.fieldName].slice(
                                0,
                                45
                              )
                            : updatedRemarksData[formField.fieldName]}
                          {updatedRemarksData[formField.fieldName].length >
                            45 && (
                            <span
                              onClick={() =>
                                toggleReadMore(formField.fieldName)
                              }
                            >
                              {isReadMore[formField.fieldName]
                                ? "...Read more"
                                : " Show less"}
                            </span>
                          )}
                        </p>
                      )}
                    </div>
                  );
                }
              })}
            </div>

            <h2>Income Tax Details</h2>
            <div className="list-section-wrapper address-edit-screen">
              <ul>
                {infoDefinition.map((formField, index) => {
                  if (
                    formField.fieldName === "last_it_filed_date" ||
                    formField.fieldName === "last_tax_amount_paid"
                  ) {
                    switch (formField.type) {
                      case "TEXT":
                        return (
                          <li key={index}>
                            <Input
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "CALENDAR":
                        return (
                          <li key={index}>
                            <DateInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "RADIO":
                        return (
                          <li key={index}>
                            <Select
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "NUMBER":
                        return (
                          <li key={index}>
                            <NumberInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );

                      default:
                        break;
                    }
                  }
                })}
              </ul>
            </div>
            <div className="document-upload-wrapper">
              {infoDefinition.map((formField, index) => {
                if (formField.fieldName === "it_returns_upload") {
                  return (
                    <div className="additional-document-wrapper" key={index}>
                      <AdditionalDocumentFile
                        fieldName={formField.fieldName}
                        display={formField.fieldDisplayName}
                        mandatory={formField.mandatory}
                        formValues={formValues}
                        showError={showError}
                        handleFileChange={handleFileChange}
                        options={formField.options}
                        fieldId={formField.fieldId}
                        addShow={addShow}
                        addShowView={addShowView}
                        addTempExt={addTempExt}
                        sampleImageURL={formField.sampleImageURL}
                      />
                      {updatedRemarksData[formField.fieldName] && (
                        <p className="remarks">
                          {isReadMore[formField.fieldName]
                            ? updatedRemarksData[formField.fieldName].slice(
                                0,
                                45
                              )
                            : updatedRemarksData[formField.fieldName]}
                          {updatedRemarksData[formField.fieldName].length >
                            45 && (
                            <span
                              onClick={() =>
                                toggleReadMore(formField.fieldName)
                              }
                            >
                              {isReadMore[formField.fieldName]
                                ? "...Read more"
                                : " Show less"}
                            </span>
                          )}
                        </p>
                      )}
                    </div>
                  );
                }
              })}
            </div>

            <h2>Electricity Details</h2>
            <div className="list-section-wrapper address-edit-screen">
              <ul>
                {infoDefinition.map((formField, index) => {
                  if (
                    formField.fieldName === "electricity_vendor" ||
                    formField.fieldName === "last_month_electric_bill"
                  ) {
                    switch (formField.type) {
                      case "TEXT":
                        return (
                          <li key={index}>
                            <Input
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "CALENDAR":
                        return (
                          <li key={index}>
                            <DateInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "RADIO":
                        return (
                          <li key={index}>
                            <Select
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );
                      case "NUMBER":
                        return (
                          <li key={index}>
                            <NumberInput
                              fieldName={formField.fieldName}
                              display={formField.fieldDisplayName}
                              mandatory={formField.mandatory}
                              formValues={formValues}
                              showError={showError}
                              handleChange={handleChange}
                              options={formField.options}
                            />
                            {updatedRemarksData[formField.fieldName] && (
                              <p className="remarks">
                                {isReadMore[formField.fieldName]
                                  ? updatedRemarksData[
                                      formField.fieldName
                                    ].slice(0, 45)
                                  : updatedRemarksData[formField.fieldName]}
                                {updatedRemarksData[formField.fieldName]
                                  .length > 45 && (
                                  <span
                                    onClick={() =>
                                      toggleReadMore(formField.fieldName)
                                    }
                                  >
                                    {isReadMore[formField.fieldName]
                                      ? "...Read more"
                                      : " Show less"}
                                  </span>
                                )}
                              </p>
                            )}
                          </li>
                        );

                      default:
                        break;
                    }
                  }
                })}
              </ul>
            </div>
            <div className="document-upload-wrapper">
              {infoDefinition.map((formField, index) => {
                if (formField.fieldName === "electric_bill_proof") {
                  return (
                    <div className="additional-document-wrapper" key={index}>
                      <AdditionalDocumentFile
                        fieldName={formField.fieldName}
                        display={formField.fieldDisplayName}
                        mandatory={formField.mandatory}
                        formValues={formValues}
                        showError={showError}
                        handleFileChange={handleFileChange}
                        options={formField.options}
                        fieldId={formField.fieldId}
                        addShow={addShow}
                        addShowView={addShowView}
                        addTempExt={addTempExt}
                        sampleImageURL={formField.sampleImageURL}
                      />
                      {updatedRemarksData[formField.fieldName] && (
                        <p className="remarks">
                          {isReadMore[formField.fieldName]
                            ? updatedRemarksData[formField.fieldName].slice(
                                0,
                                45
                              )
                            : updatedRemarksData[formField.fieldName]}
                          {updatedRemarksData[formField.fieldName].length >
                            45 && (
                            <span
                              onClick={() =>
                                toggleReadMore(formField.fieldName)
                              }
                            >
                              {isReadMore[formField.fieldName]
                                ? "...Read more"
                                : " Show less"}
                            </span>
                          )}
                        </p>
                      )}
                    </div>
                  );
                }
              })}
            </div>

            <div>
              {addGroup.map((group, index) => {
                let adGroupIndex = index;
                return (
                  <div
                    className="list-section-wrapper household-screen"
                    key={adGroupIndex}
                  >
                    <div>
                      <ul>
                        {group.map((formField, index) => {
                          switch (formField.type) {
                            case "TEXT":
                              return (
                                <li key={index}>
                                  <InputGroup
                                    fieldName={formField.fieldName}
                                    display={formField.fieldDisplayName}
                                    mandatory={formField.mandatory}
                                    gpValues={gpValues}
                                    gpError={gpError}
                                    gpIndex={adGroupIndex}
                                    handleGroupChange={handleGroupChange}
                                  />

                                  {gpRemarks[formField.fieldId][
                                    adGroupIndex
                                  ] && (
                                    <p className="remarks">
                                      {gpIsReadMore[formField.fieldId][
                                        adGroupIndex
                                      ]
                                        ? gpRemarks[formField.fieldId][
                                            adGroupIndex
                                          ]["customer"].slice(0, 45)
                                        : gpRemarks[formField.fieldId][
                                            adGroupIndex
                                          ]["customer"]}
                                      {gpRemarks[formField.fieldId][
                                        adGroupIndex
                                      ]["customer"].length > 45 && (
                                        <span
                                          onClick={() =>
                                            toggleGpReadMore(
                                              formField.fieldId,
                                              adGroupIndex
                                            )
                                          }
                                        >
                                          {gpIsReadMore[formField.fieldId][
                                            adGroupIndex
                                          ]
                                            ? "...Read more"
                                            : " Show less"}
                                        </span>
                                      )}
                                    </p>
                                  )}
                                </li>
                              );
                            case "CALENDAR":
                              return (
                                <li key={index}>
                                  <DateInputGroup
                                    fieldName={formField.fieldName}
                                    display={formField.fieldDisplayName}
                                    mandatory={formField.mandatory}
                                    gpValues={gpValues}
                                    gpError={gpError}
                                    gpIndex={adGroupIndex}
                                    handleGroupChange={handleGroupChange}
                                  />
                                  {gpRemarks[formField.fieldId][
                                    adGroupIndex
                                  ] && (
                                    <p className="remarks">
                                      {gpIsReadMore[formField.fieldId][
                                        adGroupIndex
                                      ]
                                        ? gpRemarks[formField.fieldId][
                                            adGroupIndex
                                          ]["customer"].slice(0, 45)
                                        : gpRemarks[formField.fieldId][
                                            adGroupIndex
                                          ]["customer"]}
                                      {gpRemarks[formField.fieldId][
                                        adGroupIndex
                                      ]["customer"].length > 45 && (
                                        <span
                                          onClick={() =>
                                            toggleGpReadMore(
                                              formField.fieldId,
                                              adGroupIndex
                                            )
                                          }
                                        >
                                          {gpIsReadMore[formField.fieldId][
                                            adGroupIndex
                                          ]
                                            ? "...Read more"
                                            : " Show less"}
                                        </span>
                                      )}
                                    </p>
                                  )}
                                </li>
                              );
                            case "RADIO":
                              return (
                                <li key={index}>
                                  <SelectGroup
                                    fieldName={formField.fieldName}
                                    display={formField.fieldDisplayName}
                                    mandatory={formField.mandatory}
                                    gpValues={gpValues}
                                    gpError={gpError}
                                    handleGroupChange={handleGroupChange}
                                    gpIndex={adGroupIndex}
                                    options={formField.options}
                                  />
                                  {gpRemarks[formField.fieldId][
                                    adGroupIndex
                                  ] && (
                                    <p className="remarks">
                                      {gpIsReadMore[formField.fieldId][
                                        adGroupIndex
                                      ]
                                        ? gpRemarks[formField.fieldId][
                                            adGroupIndex
                                          ]["customer"].slice(0, 45)
                                        : gpRemarks[formField.fieldId][
                                            adGroupIndex
                                          ]["customer"]}
                                      {gpRemarks[formField.fieldId][
                                        adGroupIndex
                                      ]["customer"].length > 45 && (
                                        <span
                                          onClick={() =>
                                            toggleGpReadMore(
                                              formField.fieldId,
                                              adGroupIndex
                                            )
                                          }
                                        >
                                          {gpIsReadMore[formField.fieldId][
                                            adGroupIndex
                                          ]
                                            ? "...Read more"
                                            : " Show less"}
                                        </span>
                                      )}
                                    </p>
                                  )}
                                </li>
                              );
                            case "NUMBER":
                              return (
                                <li key={index}>
                                  <NumberInputGroup
                                    fieldName={formField.fieldName}
                                    display={formField.fieldDisplayName}
                                    mandatory={formField.mandatory}
                                    gpValues={gpValues}
                                    gpError={gpError}
                                    handleGroupChange={handleGroupChange}
                                    gpIndex={adGroupIndex}
                                    options={formField.options}
                                  />
                                  {gpRemarks[formField.fieldId][
                                    adGroupIndex
                                  ] && (
                                    <p className="remarks">
                                      {gpIsReadMore[formField.fieldId][
                                        adGroupIndex
                                      ]
                                        ? gpRemarks[formField.fieldId][
                                            adGroupIndex
                                          ]["customer"].slice(0, 45)
                                        : gpRemarks[formField.fieldId][
                                            adGroupIndex
                                          ]["customer"]}
                                      {gpRemarks[formField.fieldId][
                                        adGroupIndex
                                      ]["customer"].length > 45 && (
                                        <span
                                          onClick={() =>
                                            toggleGpReadMore(
                                              formField.fieldId,
                                              adGroupIndex
                                            )
                                          }
                                        >
                                          {gpIsReadMore[formField.fieldId][
                                            adGroupIndex
                                          ]
                                            ? "...Read more"
                                            : " Show less"}
                                        </span>
                                      )}
                                    </p>
                                  )}
                                </li>
                              );

                            default:
                              break;
                          }
                        })}
                      </ul>
                    </div>
                    {/* household document section */}
                    <div className="document-upload-wrapper" key={adGroupIndex}>
                      {group.map((formField, index) => {
                        switch (formField.type) {
                          case "DOCUMENT":
                            return (
                              <div
                                className="additional-document-wrapper group-wrapper"
                                key={index}
                              >
                                <DocumentFileGroup
                                  fieldName={formField.fieldName}
                                  display={formField.fieldDisplayName}
                                  mandatory={formField.mandatory}
                                  gpValues={gpValues}
                                  gpError={gpError}
                                  handleGroupFileChange={handleGroupFileChange}
                                  options={formField.options}
                                  fieldId={formField.fieldId}
                                  gpTempShow={gpTempShow}
                                  gpTempShowView={gpTempShowView}
                                  gpTempExt={gpTempExt}
                                  gpIndex={adGroupIndex}
                                />
                                {gpRemarks[formField.fieldId][adGroupIndex] && (
                                  <p className="remarks">
                                    {gpIsReadMore[formField.fieldId][
                                      adGroupIndex
                                    ]
                                      ? gpRemarks[formField.fieldId][
                                          adGroupIndex
                                        ]["customer"].slice(0, 45)
                                      : gpRemarks[formField.fieldId][
                                          adGroupIndex
                                        ]["customer"]}
                                    {gpRemarks[formField.fieldId][adGroupIndex][
                                      "customer"
                                    ].length > 45 && (
                                      <span
                                        onClick={() =>
                                          toggleGpReadMore(
                                            formField.fieldId,
                                            adGroupIndex
                                          )
                                        }
                                      >
                                        {gpIsReadMore[formField.fieldId][
                                          adGroupIndex
                                        ]
                                          ? "...Read more"
                                          : " Show less"}
                                      </span>
                                    )}
                                  </p>
                                )}
                              </div>
                            );

                          default:
                            break;
                        }
                      })}
                    </div>
                  </div>
                );
              })}

              <div className="button-section justify-content-center add-del">
                <button className="btn btn-primary" onClick={addRowHandler}>
                  Add HouseHold Item
                </button>
                {delHandler && (
                  <button className="btn btn-primary" onClick={delRowHandler}>
                    Delete HouseHold Item
                  </button>
                )}
              </div>
            </div>

            {errMsg && <Error message={errMsg} />}

            {gpErrMsg && <Error message={gpErrMsg} />}
          </div>
          <div className="button-section">
            <div>
              <button type="button" className="btn btn-outline-primary">
                Previous
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={submitHandler}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfo;
