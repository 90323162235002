import React from "react";
const TextArea = ({
  fieldName,
  display,
  mandatory,
  showError,
  handleChange,
  formValues,
}) => {
  return (
    <div>
      <label>
        {display} <span className="red">{mandatory === true ? "*" : ""}</span>
      </label>
      <textarea
        type="text"
        name={fieldName}
        rows="4"
        cols="50"
        className={
          showError[fieldName] ? "form-control border-red" : "form-control"
        }
        value={formValues[fieldName] ? formValues[fieldName] : ""}
        onChange={handleChange}
      />
    </div>
  );
};

export default TextArea;
