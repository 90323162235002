import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import moment from "moment";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Loader from "../../components/loader";

import "./business-info.css";

import edit from "../../assets/images/edit.svg";

import { getCookie } from "../../utils/cookie-helper";
import { getCustomerKyc } from "../../services/kyc-service";
import { getworkFlowInteractionOutcome } from "../../services/workflow-service";
import { ACTIVE_KYC_ID } from "../../constants";
import {
  getNextActionPage,
  fetchAllWorkFlowActions,
  DecryptData,
} from "../../utils/application-helper";
import { numberWithCommas } from "../../utils/application-helper";

const BusinessInformation = ({ editHandler }) => {
  const navigate = useNavigate();
  const [workFlowActionId, setWorkFlowActionId] = useState("");
  const [businessDetails, setBusinessDetails] = useState([]);
  const [revenueDetails, setRevenueDetails] = useState([]);
  const [expenseDetails, setExpenseDetails] = useState([]);
  const [assetDetails, setAssetDetails] = useState([]);
  const [debtDetails, setDebtDetails] = useState([]);
  const [reservesDetails, setReservesDetails] = useState([]);
  const [packageTitle, setPackageTitle] = useState("");
  const [agree, setAgree] = useState(true);
  const [loading, setLoading] = useState(true);
  const [submitDefinitions, setSubmitDefinitions] = useState([]);
  const [customerResponse, setCustomerResponse] = useState({});

  const mobileNumber = DecryptData(getCookie("phone"));

  useEffect(() => {
    fetchCustomerKyc();
    fetchAllWorkKyc();
  }, []);

  const fetchAllWorkKyc = () => {
    fetchAllWorkFlowActions("get_business_details").then((response) => {
      if (response.status === true) {
        setWorkFlowActionId(response.value);
      }
    });
  };

  const previousHandler = () => {
    navigate("/");
  };

  const parseData = (responseData) => {
    let responseValueCount = 0;
    let remarksValueCount = 0;

    let businessDetails = [];
    let revenueDetails = [];
    let expenseDetails = [];
    let assetDetails = [];
    let debtDetails = [];
    let reservesDetails = [];

    let submitDefinitions = [];
    let customerResponse = {};

    const packageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "62020b1bc9475e2c630a597b"
    );
    let newData = packageId.children;
    setPackageTitle(packageId.packageTitle);
    newData.map((personDefinition) => {
      submitDefinitions.push({
        fieldName: personDefinition.fieldName,
      });
      customerResponse[personDefinition.fieldName] =
        personDefinition.responseValue;
    });
    newData.map((value) => {
      if (value.responseValue !== "") {
        responseValueCount = responseValueCount + 1;
      }
      if ("remarks" in value) {
        remarksValueCount = remarksValueCount + 1;
      }
    });

    if (responseValueCount === 0) {
      editHandler();
    } else if (remarksValueCount !== 0) {
      editHandler();
    } else {
      newData.map((personDefinition) => {
        if (
          personDefinition.fieldName === "business_name" ||
          personDefinition.fieldName === "date_of_establishment"
        ) {
          businessDetails.push(personDefinition);
        }
        if (
          personDefinition.fieldName === "avg_monthly_turnover" ||
          personDefinition.fieldName === "total_monthly_business_income"
        ) {
          revenueDetails.push(personDefinition);
        }
        if (
          personDefinition.fieldName === "no_of_employees" ||
          personDefinition.fieldName === "avg_monthly_salary_employees" ||
          personDefinition.fieldName === "avg_payout_pm_suppliers" ||
          personDefinition.fieldName === "avg_interest_per_month" ||
          personDefinition.fieldName === "avg_tax_per_month" ||
          personDefinition.fieldName === "total_monthly_expenses" ||
          personDefinition.fieldName === "total_employee_expenses_monthly"
        ) {
          expenseDetails.push(personDefinition);
        }
        if (
          personDefinition.fieldName === "cash_in_hand" ||
          personDefinition.fieldName === "receivables" ||
          personDefinition.fieldName === "ppe_value" ||
          personDefinition.fieldName === "total_assets"
        ) {
          assetDetails.push(personDefinition);
        }
        if (
          personDefinition.fieldName === "current_liabilities" ||
          personDefinition.fieldName === "long_term_debt_value" ||
          personDefinition.fieldName === "total_debt"
        ) {
          debtDetails.push(personDefinition);
        }
        if (
          personDefinition.fieldName === "owners_equity" ||
          personDefinition.fieldName === "value_of_rs" ||
          personDefinition.fieldName === "total_debt_reserves_surplus"
        ) {
          reservesDetails.push(personDefinition);
        }
      });

      setBusinessDetails(businessDetails);
      setRevenueDetails(revenueDetails);
      setExpenseDetails(expenseDetails);
      setDebtDetails(debtDetails);
      setAssetDetails(assetDetails);
      setReservesDetails(reservesDetails);
      setSubmitDefinitions(submitDefinitions);
      setCustomerResponse(customerResponse);
      setLoading(false);
    }
  };

  const fetchCustomerKyc = async () => {
    const customerKycParams = {
      msisdn: mobileNumber,
      kycId: ACTIVE_KYC_ID,
    };

    const { data, status, error } = await getCustomerKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data.statusCode === "404") {
      editHandler();
    } else if (status === 200 && data) {
      parseData(data.data);
    }
  };

  const nextHandler = async () => {
    setLoading(true);
    let bodyValues = {};

    submitDefinitions.map((field) => {
      const { fieldName } = field;
      bodyValues[fieldName] = customerResponse[fieldName];
    });

    bodyValues["business_details_confirmed"] = true;

    let urlParameters = {
      uuid: workFlowActionId,
    };

    const { status, error } = await getworkFlowInteractionOutcome(
      urlParameters,
      bodyValues
    );
    if (error) {
      toast.error(error);
    } else if (status == 200) {
      getNextActionPage().then((response) => {
        if (response.status == true) {
          setLoading(false);
          toast.success("Info updated successfully");
          navigate(response.screen);
        }
      });
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <Stepper currentStep={10} />
        <div className="content-container">
          <div className="common-heading">
            <h1>{packageTitle}</h1>
            <button
              type="button"
              onClick={editHandler}
              className="btn btn-outline-primary"
            >
              <img src={edit} alt="edit" />
              edit
            </button>
          </div>
          <div className="list-section-wrapper wrapper-section">
            <ul className="business-header">
              {businessDetails.map((field, index) => {
                return (
                  <li key={index}>
                    <label>{field.fieldDisplayName}</label>
                    <span>
                      {field.responseValue === "" ||
                      field.responseValue === undefined
                        ? "-"
                        : field.type === "CALENDAR"
                        ? moment.utc(field.responseValue).format("MM-DD-YYYY")
                        : field.fieldName === "no_of_employees"
                        ? field.responseValue
                        : field.fieldName === "business_name"
                        ? field.responseValue
                        : `Rs.${numberWithCommas(field.responseValue)}`}
                    </span>
                  </li>
                );
              })}
            </ul>
            <p className="business-heading">Revenue details</p>
            <ul className="revenue-details">
              {revenueDetails.map((field, index) => {
                return (
                  <li key={index}>
                    <label>{field.fieldDisplayName}</label>
                    <span>
                      {field.responseValue === "" ||
                      field.responseValue === undefined
                        ? "-"
                        : field.type === "CALENDAR"
                        ? moment.utc(field.responseValue).format("MM-DD-YYYY")
                        : field.fieldName === "no_of_employees"
                        ? field.responseValue
                        : field.fieldName === "business_name"
                        ? field.responseValue
                        : `Rs.${numberWithCommas(field.responseValue)}`}
                    </span>
                  </li>
                );
              })}
            </ul>
            <p className="business-heading">Expense details</p>
            <ul className="expense-details">
              {expenseDetails.map((field, index) => {
                return (
                  <li key={index}>
                    <label>{field.fieldDisplayName}</label>
                    <span>
                      {field.responseValue === "" ||
                      field.responseValue === undefined
                        ? "-"
                        : field.type === "CALENDAR"
                        ? moment.utc(field.responseValue).format("MM-DD-YYYY")
                        : field.fieldName === "no_of_employees"
                        ? field.responseValue
                        : field.fieldName === "business_name"
                        ? field.responseValue
                        : `Rs.${numberWithCommas(field.responseValue)}`}
                    </span>
                  </li>
                );
              })}
            </ul>
            <p className="business-heading">Asset details</p>
            <ul className="asset-details">
              {assetDetails.map((field, index) => {
                return (
                  <li key={index}>
                    <label>{field.fieldDisplayName}</label>
                    <span>
                      {field.responseValue === "" ||
                      field.responseValue === undefined
                        ? "-"
                        : field.type === "CALENDAR"
                        ? moment.utc(field.responseValue).format("MM-DD-YYYY")
                        : field.fieldName === "no_of_employees"
                        ? field.responseValue
                        : field.fieldName === "business_name"
                        ? field.responseValue
                        : `Rs.${numberWithCommas(field.responseValue)}`}
                    </span>
                  </li>
                );
              })}
            </ul>
            <p className="business-heading">Debt details</p>
            <ul className="debt-details">
              {debtDetails.map((field, index) => {
                return (
                  <li key={index}>
                    <label>{field.fieldDisplayName}</label>
                    <span>
                      {field.responseValue === "" ||
                      field.responseValue === undefined
                        ? "-"
                        : field.type === "CALENDAR"
                        ? moment.utc(field.responseValue).format("MM-DD-YYYY")
                        : field.fieldName === "no_of_employees"
                        ? field.responseValue
                        : field.fieldName === "business_name"
                        ? field.responseValue
                        : `Rs.${numberWithCommas(field.responseValue)}`}
                    </span>
                  </li>
                );
              })}
            </ul>
            <p className="business-heading">Reserves and Surplus</p>
            <ul className="business-value">
              {reservesDetails.map((field, index) => {
                return (
                  <li key={index}>
                    <label>{field.fieldDisplayName}</label>
                    <span>
                      {field.responseValue === "" ||
                      field.responseValue === undefined
                        ? "-"
                        : field.type === "CALENDAR"
                        ? moment.utc(field.responseValue).format("MM-DD-YYYY")
                        : field.fieldName === "no_of_employees"
                        ? field.responseValue
                        : field.fieldName === "business_name"
                        ? field.responseValue
                        : `Rs.${numberWithCommas(field.responseValue)}`}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="check-section">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="details-correct"
                onClick={() => setAgree(!agree)}
              />
              <label className="form-check-label" htmlFor="details-correct">
                I confirm my business details are correct
              </label>
            </div>
          </div>
        </div>

        <div className="button-section">
          <div>
            <button
              type="button"
              onClick={previousHandler}
              className="btn btn-outline-primary"
            >
              Previous
            </button>
          </div>
          <div>
            <button
              type="button"
              disabled={agree}
              onClick={nextHandler}
              className="btn btn-primary"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessInformation;
