import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import {
  getKycDefinition,
  getCustomerKyc,
  submitKyc,
} from "../../services/kyc-service";

import { getworkFlowInteractionOutcome } from "../../services/workflow-service";

import { getCookie } from "../../utils/cookie-helper";

import Loader from "../../components/loader";
import Error from "../../components/error";

import AuthRadio from "../../components/authRadio";

import { ACTIVE_KYC_ID } from "../../constants";
import { toast } from "react-toastify";
import {
  getNextActionPage,
  fetchAllWorkFlowActions,
  DecryptData,
} from "../../utils/application-helper";

import "./customer-app.css";

const CustomerApp = () => {
  const [loading, setLoading] = useState(true);
  const [workFlowActionId, setWorkFlowActionId] = useState("");
  const [infoDefinition, setInfoDefinition] = useState([]);
  const [updatedRemarksData, setUpdatedRemarksData] = useState([]);
  const [previousValues, setPreviousValues] = useState({});
  const [versionData, setVersionData] = useState(0);
  const [errMsg, setErrMsg] = useState();
  const [submitDefinitions, setSubmitDefinitions] = useState([]);
  const [isReadMore, setIsReadMore] = useState({});
  const [showError, setShowError] = useState();
  const [formValues, setFormValues] = useState({});

  const navigate = useNavigate();
  const params = {
    id: ACTIVE_KYC_ID,
  };

  const mobileNumber = DecryptData(getCookie("phone"));

  useEffect(() => {
    fetchKycDefinition();
    fetchAllWorkKyc();
  }, []);

  const fetchAllWorkKyc = () => {
    fetchAllWorkFlowActions("consumer_app_journey").then((response) => {
      if (response.status === true) {
        setWorkFlowActionId(response.value);
      }
    });
  };

  const fetchKycDefinition = async () => {
    const { data, status, error } = await getKycDefinition(params);
    if (error) {
      console.log(error);
    } else if (status === 200 && data) {
      const responseKycDefinitionData = data.data;

      let infoDefinition = [];

      let submitDefinitions = [];

      let showError = {};

      let formValues = {};

      const packageId = responseKycDefinitionData.packagesDTOs.find(
        (packageDef) => packageDef.id === "623c14b496036905dccfbf79"
      );

      const packageDTOs = packageId.children;

      packageDTOs.map((personDefinition) => {
        infoDefinition.push({
          fieldId: personDefinition.id,
          fieldName: personDefinition.fieldName,
          fieldDisplayName: personDefinition.fieldDisplayName,
          editable: personDefinition.editable,
          mandatory: personDefinition.mandatory,
          type: personDefinition.type,
          options: personDefinition.options,
          multiQuestions: personDefinition.multiQuestions,
          validation: personDefinition.validation,
        });
        showError[personDefinition.fieldName] = false;
        formValues[personDefinition.fieldName] = undefined;
      });

      packageDTOs.map((personDefinition) => {
        submitDefinitions.push({
          fieldName: personDefinition.fieldName,
        });
      });

      setShowError(showError);
      setInfoDefinition(infoDefinition);
      setVersionData(responseKycDefinitionData.version);
      setSubmitDefinitions(submitDefinitions);
      fetchCustomerKyc(formValues);
    }
  };

  const fetchCustomerKyc = async (formValues) => {
    const customerKycParams = {
      msisdn: mobileNumber,
      kycId: ACTIVE_KYC_ID,
    };

    const { data, status, error } = await getCustomerKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data) {
      if (data.statusCode === "404") {
        setFormValues(formValues);
        setLoading(false);
        return;
      } else {
        const responseData = data.data;
        if (responseData !== undefined) {
          parseCustomerData(responseData, formValues);
        }
      }
    }
  };

  const parseCustomerData = (responseData, formValues) => {
    let remarksData = {};
    let isReadMore = {};

    const packageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "623c14b496036905dccfbf79"
    );

    packageId.children.map((element) => {
      if (element.responseValue !== undefined) {
        formValues[element.fieldName] = element.responseValue;
      } else {
        formValues[element.fieldName] = undefined;
      }

      remarksData[element.fieldName] = element.remarks;
      if (element.remarks !== undefined && element.remarks.length > 45) {
        isReadMore[element.fieldName] = true;
      } else {
        isReadMore[element.fieldName] = false;
      }
    });

    setFormValues(formValues);
    setPreviousValues(formValues);
    setUpdatedRemarksData(remarksData);
    setIsReadMore(isReadMore);
    setLoading(false);
  };

  const handleChange = (e) => {
    setErrMsg();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setShowError({
      ...showError,
      [name]: false,
    });
  };

  const toggleReadMore = (fieldName) => {
    setIsReadMore({ ...isReadMore, [fieldName]: !isReadMore[fieldName] });
  };

  const nextHandler = () => {
    let message;
    let errCount = 0;
    for (let i = 0; i < infoDefinition.length; i++) {
      let testRegEx;
      let element = infoDefinition[i];
      const { fieldName, fieldDisplayName, mandatory, validation } = element;
      if (validation !== undefined) {
        testRegEx = new RegExp(validation);
      }

      if (mandatory && !formValues[fieldName]) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} required!`;
        break;
      } else if (
        mandatory &&
        validation !== undefined &&
        !testRegEx.test(formValues[fieldName])
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} not matching!`;
        break;
      } else if (
        updatedRemarksData[fieldName] !== undefined &&
        previousValues[fieldName] === formValues[fieldName]
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `Please provide another ${fieldDisplayName}`;
      }
    }
    setErrMsg(message);
    if (errCount === 0) {
      createOrUpdateKyc();
    }
  };

  const createOrUpdateKyc = async () => {
    let bodyData = {
      kycId: ACTIVE_KYC_ID,
      created_by: DecryptData(getCookie("phone")),
      data: {},
      version: versionData,
    };
    submitDefinitions.map((field) => {
      const { fieldName } = field;
      bodyData["data"][fieldName] = formValues[fieldName];
    });

    const { status, data, error } = await submitKyc(bodyData);
    if (error) {
      toast.error(error.message);
    } else if (status === 200 && data) {
      updateHandler();
      setLoading(true);
    }
  };

  const updateHandler = async () => {
    let bodyValues = {};
    submitDefinitions.map((field) => {
      const { fieldName } = field;
      bodyValues[fieldName] = formValues[fieldName];
    });

    let urlParameters = {
      uuid: workFlowActionId,
    };

    const { status, error } = await getworkFlowInteractionOutcome(
      urlParameters,
      bodyValues
    );
    if (error) {
      toast.error(error);
    } else if (status == 200) {
      getNextActionPage().then((response) => {
        if (response.status == true) {
          setLoading(false);
          toast.success("Info updated successfully");
          navigate(response.screen);
        }
      });
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="auth-page-wrapper">
      <div className="auth-wrapper">
        <h1>Welcome to YABX</h1>

        {infoDefinition.map((formField, index) => {
          switch (formField.type) {
            case "RADIO":
              return (
                <div key={index}>
                  <AuthRadio
                    fieldName={formField.fieldName}
                    display={formField.fieldDisplayName}
                    mandatory={formField.mandatory}
                    formValues={formValues}
                    showError={showError}
                    handleChange={handleChange}
                    options={formField.options}
                    fieldId={formField.fieldId}
                  />
                  {updatedRemarksData[formField.fieldName] && (
                    <p className="remarks">
                      {isReadMore[formField.fieldName]
                        ? updatedRemarksData[formField.fieldName].slice(0, 45)
                        : updatedRemarksData[formField.fieldName]}
                      {updatedRemarksData[formField.fieldName].length > 45 && (
                        <span
                          onClick={() => toggleReadMore(formField.fieldName)}
                        >
                          {isReadMore[formField.fieldName]
                            ? "...Read more"
                            : " Show less"}
                        </span>
                      )}
                    </p>
                  )}
                </div>
              );

            default:
              break;
          }
        })}

        {errMsg && <Error message={errMsg} />}

        <button type="button" className="btn btn-primary" onClick={nextHandler}>
          Next
        </button>
      </div>
    </div>
  );
};

export default CustomerApp;
