import { getRequest, postRequest } from "../utils/http-helper";
import { KYC_SUBMIT, KYC_UPLOAD, KYC_RESPONSE_UPDATE } from "./urls";
import {
  getKycDefinitionUrl,
  getAllKycResponsesUrl,
  getSingleKycResponseUrl,
  getCustomerKycUrl,
  getResponseKycUrl,
} from "./urls";

export const getKycDefinition = async (params) => {
  let url = getKycDefinitionUrl(params);
  return await getRequest({
    url: url,
    data: {},
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const submitKyc = async (data) => {
  return await postRequest({
    url: KYC_SUBMIT,
    data: data,
  });
};

export const responseKyc = async (data) => {
  return await postRequest({
    url: KYC_RESPONSE_UPDATE,
    data: data,
  });
};

export const getAllKycResponses = async (urlParams) => {
  let url = getAllKycResponsesUrl(urlParams);
  return await getRequest({
    url: url,
    data: {},
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getSingleKycResponse = async (urlParams) => {
  let url = getSingleKycResponseUrl(urlParams);
  return await getRequest({
    url: url,
    data: {},
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getCustomerKyc = async (urlParams) => {
  let url = getCustomerKycUrl(urlParams);
  return await getRequest({
    url: url,
    data: {},
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getResponseKyc = async (urlParams) => {
  let url = getResponseKycUrl(urlParams);
  return await getRequest({
    url: url,
    data: {},
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const uploadKyc = async (data) => {
  return await postRequest({
    url: KYC_UPLOAD,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
