import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import logo from "../../assets/images/logo.svg";
import resume from "../../assets/gif/resume.gif";

import { toast } from "react-toastify";

import { saveCookie, getCookie } from "../../utils/cookie-helper";
import {
  ACTIVE_LMS_PRODUCT,
  ACTIVE_WORKFLOW_NAME,
  LMS_PARTNER_CODE,
} from "../../constants";
import { initiateWorkFlow } from "../../services/workflow-service";
import "./login.css";
import { DecryptData, EncryptData } from "../../utils/application-helper";

const ResumeJourney = () => {
  const navigate = useNavigate();

  var decryptedNumber = DecryptData(getCookie("phone"));

  useEffect(() => {
    if (decryptedNumber !== undefined) {
      authenticationHandler();
    }
  }, []);

  const authenticationHandler = () => {
    if (DecryptData(getCookie("auth_token"))) {
      proceedInitiateWorkFlow();
    } else {
      toast.warning("Authentication required!");
      navigate("/login");
    }
  };

  const proceedInitiateWorkFlow = () => {
    const initiateWorkFlowParams = {
      msisdn: decryptedNumber,
      partner_code: LMS_PARTNER_CODE,
      product_type: ACTIVE_LMS_PRODUCT,
      workflow_name: ACTIVE_WORKFLOW_NAME,
    };

    initiateWorkFlow(initiateWorkFlowParams).then((response) => {
      if (response.status == 200) {
        saveCookie("workflow_id", EncryptData(response.data.data.uuid));
        saveCookie("phone", decryptedNumber);
        const responseAction = response.data.data.actions;
        if (responseAction.length === 0) {
          navigate("/failed");
        } else {
          switch (responseAction[0].action) {
            case "show_reject_screen":
              navigate("/loan-rejected");
              break;
            case "get_loan_amount":
              navigate("/loan-info");
              break;
            case "consumer_app_journey":
              navigate("/customer-app");
              break;
            case "loan_service_menu":
              navigate("/loan-service");
              break;
            case "choose_loan_journey":
              navigate("/home");
              break;
            case "loan_simulator":
              navigate("/personal-loan-simulator");
              break;
            case "consumer-loan-journey":
              navigate("/consumer-loan-simulator");
              break;
            case "previous_loan_details":
              navigate("/previous-loan-details");
              break;
            case "get_personal_info":
              navigate("/personal-info");
              break;
            case "get_address":
              navigate("/address");
              break;
            case "get_documents":
              navigate("/documents");
              break;
            case "show_loan_summary":
              navigate("/summary");
              break;
            case "get_mandate":
              navigate("/mandate");
              break;
            case "get_review":
              navigate("/review");
              break;
            case "get_additional_info":
              navigate("/additional-info");
              break;
            case "do_personal_discussion":
              navigate("/personal-discussion");
              break;
            case "get_business_details":
              navigate("/business-info");
              break;
            case "get_personal_income":
              navigate("/personal-income");
              break;
            case "get_lifestyle_details":
              navigate("/lifestyle-details");
              break;
            case "reject_lead":
              navigate("/loan-rejected");
              break;
            case "show_loan_agreement":
              navigate("/loan-agreement");
              break;
            default:
              break;
          }
        }
      } else {
        toast.error(response.message);
      }
    });
  };

  return (
    <div className="auth-page-wrapper">
      <div className="auth-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="auth-wrapper">
        <img className="mb-3" src={resume} alt="loader" />
        <p>Redirecting to the page you left recently...</p>
      </div>
    </div>
  );
};

export default ResumeJourney;
