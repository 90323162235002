import React from "react";

const NumberInputGroup = ({
  fieldName,
  display,
  mandatory,
  gpError,
  handleGroupChange,
  gpValues,
  gpIndex,
}) => {
  return (
    <div>
      <label>
        {display} <span className="red">{mandatory === true ? "*" : ""}</span>
      </label>
      <input
        type="number"
        name={fieldName}
        className={
          gpError[fieldName][gpIndex]
            ? "form-control border-red"
            : "form-control"
        }
        value={gpValues[fieldName][gpIndex] ? gpValues[fieldName][gpIndex] : ""}
        onChange={(e) => handleGroupChange(gpIndex, fieldName, e.target.value)}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        readOnly={fieldName === "household_item_sl_no" && true}
      />
    </div>
  );
};

export default NumberInputGroup;
