import React from "react";

const DocumentRadio = ({
  fieldId,
  fieldName,
  display,
  mandatory,
  handleChange,
  formValues,
  options,
}) => {
  return (
    <div className="identity_section" key={fieldId}>
      <h4>
        {display} <span className="red">{mandatory ? "*" : ""}</span>
      </h4>
      <ul>
        {options.map((item) => {
          return (
            <li key={item.value}>
              <input
                type="radio"
                className="form-check-input"
                id={item.value}
                name={fieldName}
                value={item.value}
                onChange={handleChange}
                checked={formValues[fieldName] === item.value}
              />
              <label className="form-check-label" htmlFor={item.value}>
                {" "}
                {item.display}{" "}
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DocumentRadio;
