import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import "./login.css";

import logo from "../../assets/images/logo.svg";
import Error from "../../components/error";

import { saveCookie, deleteCookie } from "../../utils/cookie-helper";
import { EncryptData } from "../../utils/application-helper";
import {
  LMS_CLIENT_SECRET,
  LMS_PARTNER_CODE,
  MOBILE_NUMBER_REGEX,
} from "../../constants";
import { generateOtp, getAccessToken } from "../../services/lms-service";

const Login = () => {
  const navigate = useNavigate();

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const textInput = useRef(null);

  useEffect(() => {
    fetchAccessToken();
    textInput.current.focus();
    deleteCookie("auth_token");
  }, []);

  const fetchAccessToken = () => {
    const accessTokenParams = {
      client_secret: LMS_CLIENT_SECRET,
    };

    getAccessToken(accessTokenParams).then((response) => {
      if (response.data != undefined && response.data.status == "success") {
        saveCookie("lms_token", EncryptData(response.data.access_token));
      } else {
        toast.error("Failed fetching access token");
        toast.error(response.message);
      }
    });
    deleteCookie("phone");
    deleteCookie("workflow_id");
  };

  const handleChange = (e) => {
    setShowError(false);
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    setMobileNumber(e.target.value);
  };

  const validateMobileNumber = () => {
    if (mobileNumber === "") {
      setErrorMessage("Mobile number can not be empty");
      setShowError(true);
      return false;
    } else if (!MOBILE_NUMBER_REGEX.test(mobileNumber)) {
      setErrorMessage("Please enter valid mobile number");
      setShowError(true);
      return false;
    } else {
      setShowError(false);
      return true;
    }
  };

  const sendOtp = (event) => {
    event.preventDefault();
    if (validateMobileNumber()) {
      const otpParams = {
        msisdn: mobileNumber,
        partner_code: LMS_PARTNER_CODE,
        purpose: "customer_verification",
      };

      generateOtp(otpParams).then((response) => {
        if (response.data != undefined && response.data.status == "success") {
          saveCookie("phone", EncryptData(mobileNumber));
          toast.success("OTP Sent!");
          navigate("/otp-verify");
        } else {
          toast.error(response.message);
        }
      });
    }
  };

  return (
    <form className="auth-page-wrapper">
      <div className="auth-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="auth-wrapper">
        <h1>Welcome to YABX</h1>
        <label>Please enter your mobile number</label>
        <div className="otp-input otp-mobile-input">
          <input
            type="text"
            ref={textInput}
            className="form-control mobile-input"
            onChange={handleChange}
            name="numbers"
            id="numbers"
            // min="0"
            // max="9999999999"
            pattern="[0-9]*"
            maxLength="10"
          />
        </div>
        {showError && <Error message={errorMessage} />}
        <button
          type="submit"
          className="btn btn-primary"
          onClick={(event) => {
            sendOtp(event);
          }}
        >
          Send OTP
        </button>
      </div>
    </form>
  );
};

export default Login;
