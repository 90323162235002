import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import {
  getworkFlowAllTransactions,
  getworkFlowInteractionOutcome,
} from "../../services/workflow-service";

import {
  getKycDefinition,
  getCustomerKyc,
  submitKyc,
} from "../../services/kyc-service";

import { getCookie } from "../../utils/cookie-helper";

import "./loanInfo.css";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Loader from "../../components/loader";
import Error from "../../components/error";
import RangeSlider from "../../components/rangeSlider";
import TenureRadio from "../../components/tenureRadio";
import Input from "../../components/input";
import DateInput from "../../components/dateInput";
import Select from "../../components/select";
import NumberInput from "../../components/numberInput";

import notes from "../../assets/images/notes.svg";
import LoanSummary from "./LoanSummary";
import { toast } from "react-toastify";

import { ACTIVE_KYC_ID } from "../../constants";

import { DecryptData, getNextActionPage } from "../../utils/application-helper";
import { getLoanOffers } from "../../services/lms-service";

const LoanInfo = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [workFlowParams, setWorkFlowParams] = useState({});
  const [workFlowActionId, setWorkFlowActionId] = useState("");
  const [responseTenure, setResponseTenure] = useState([]);
  const [emi, setEmi] = useState([]);
  const [tenureEMI, setTenureEMI] = useState({});
  const [variantTenure, setVariantTenure] = useState([]);
  const [productInterestRate, setProductInterestRate] = useState();
  const [productId, setProductId] = useState(0);
  const [applicationFee, setApplicationFee] = useState(0);
  const [productVariantName, setProductVariantName] = useState("");
  const [availableCredit, setAvailableCredit] = useState();
  const [infoDefinition, setInfoDefinition] = useState([]);
  const [packageTitle, setPackageTitle] = useState("");
  const [versionData, setVersionData] = useState(0);
  const [updatedRemarksData, setUpdatedRemarksData] = useState([]);
  const [previousValues, setPreviousValues] = useState({});
  const [isReadMore, setIsReadMore] = useState({});
  const [showError, setShowError] = useState({});
  const [submitDefinitions, setSubmitDefinitions] = useState([]);
  const [loanDefinitions, setLoanDefinitions] = useState([]);
  const [errMsg, setErrMsg] = useState();
  const [formValues, setFormValues] = useState({});

  const mobileNumber = DecryptData(getCookie("phone"));

  if (formValues.applied_loan_amount !== undefined) {
    var loanAmount = formValues.applied_loan_amount[0];
  }

  useEffect(() => {
    parseTenureEMI(emi, formValues.applicable_tenure);
  }, [emi, formValues.applicable_tenure]);

  useEffect(() => {
    parseVariantType(variantTenure, formValues.applicable_tenure);
  }, [formValues.applicable_tenure, variantTenure]);

  useEffect(() => {
    if (formValues.applied_loan_amount !== undefined) {
      setShowError({
        loan: false,
        loanAmount: "",
      });
      let loanAmount = formValues.applied_loan_amount[0];
      let insuranceAmount = (loanAmount * 0.01).toFixed(2);
      setFormValues({ ...formValues, insurance_amount: insuranceAmount });
    }
  }, [formValues.applied_loan_amount]);

  const parseTenureEMI = (emi, duration) => {
    const newEMI = emi.find((emiInfo) => emiInfo.tenure === Number(duration));
    setTenureEMI(newEMI);
  };

  const parseVariantType = (variantTenure, duration) => {
    const selectedVariantId = variantTenure.find(
      (variant) => variant.durationNumber === Number(duration)
    );
    if (selectedVariantId !== undefined) {
      setProductId(selectedVariantId.productId);
      setProductVariantName(selectedVariantId.durationName);
      setProductInterestRate(selectedVariantId.interest_rate);
      setApplicationFee(selectedVariantId.applicationFee);
    }
  };

  useEffect(() => {
    getAllResponseData();
  }, []);

  useEffect(() => {
    calculateEMI(responseTenure);
  }, [loanAmount, responseTenure]);

  const params = {
    id: ACTIVE_KYC_ID,
  };

  const fetchKycDefinition = async () => {
    const { data, status, error } = await getKycDefinition(params);
    if (error) {
      console.log(error);
    } else if (status === 200 && data) {
      const responseKycDefinitionData = data.data;

      let infoDefinition = [];

      let submitDefinitions = [];

      let loanDefinitions = [];

      let showError = {};

      let formValues = {};

      const packageId = responseKycDefinitionData.packagesDTOs.find(
        (packageDef) => packageDef.id === "623be00496036905dccfbebb"
      );

      const packageDTOs = packageId.children;
      setPackageTitle(packageId.packageTitle);

      packageDTOs.map((personDefinition) => {
        if (
          personDefinition.fieldName === "applied_loan_amount" ||
          personDefinition.fieldName === "applicable_tenure"
        ) {
          loanDefinitions.push({
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
            displayType: personDefinition.displayType,
          });
        }

        if (
          personDefinition.fieldName !== "applied_loan_amount" &&
          personDefinition.fieldName !== "applicable_tenure" &&
          personDefinition.fieldName !== "applied_interest" &&
          personDefinition.fieldName !== "calculated_emi"
        ) {
          infoDefinition.push({
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            editable: personDefinition.editable,
            mandatory: personDefinition.mandatory,
            type: personDefinition.type,
            options: personDefinition.options,
            multiQuestions: personDefinition.multiQuestions,
            validation: personDefinition.validation,
            displayType: personDefinition.displayType,
          });
        }
      });

      packageDTOs.map((personDefinition) => {
        if (
          personDefinition.fieldName !== "applied_interest" &&
          personDefinition.fieldName !== "calculated_emi"
        ) {
          submitDefinitions.push({
            fieldName: personDefinition.fieldName,
            fieldDisplayName: personDefinition.fieldDisplayName,
            mandatory: personDefinition.mandatory,
            displayType: personDefinition.displayType,
            validation: personDefinition.validation,
          });
          showError[personDefinition.fieldName] = false;
          if (
            personDefinition.type === "NUMBER" &&
            personDefinition.displayType === "SLIDER"
          ) {
            formValues[personDefinition.fieldName] = [0];
          } else {
            formValues[personDefinition.fieldName] = undefined;
          }
        }
      });

      setShowError(showError);
      setFormValues(formValues);
      setLoanDefinitions(loanDefinitions);

      setInfoDefinition(infoDefinition);
      setVersionData(responseKycDefinitionData.version);
      setSubmitDefinitions(submitDefinitions);
      fetchCustomerKyc(formValues);
    }
  };

  const fetchCustomerKyc = async (formValues) => {
    const customerKycParams = {
      msisdn: mobileNumber,
      kycId: ACTIVE_KYC_ID,
    };

    const { data, status, error } = await getCustomerKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data) {
      const responseData = data.data;
      if (responseData !== undefined) {
        parseCustomerData(responseData, formValues);
      }
    }
  };

  const parseCustomerData = (responseData, formValues) => {
    let remarksData = {};
    let isReadMore = {};

    const packageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "623be00496036905dccfbebb"
    );

    packageId.children.map((element) => {
      let { displayType, responseValue, remarks, fieldName } = element;
      if (displayType === "SLIDER" && responseValue !== '') {
        formValues[fieldName][0] = responseValue;
      } else if (displayType !== "SLIDER") {
        formValues[fieldName] = responseValue;
      }
      remarksData[fieldName] = remarks;
      if (element.remarks !== undefined && element.remarks.length > 45) {
        isReadMore[element.fieldName] = true;
      } else {
        isReadMore[element.fieldName] = false;
      }
    });

    setFormValues(formValues);
    setPreviousValues(formValues);
    setUpdatedRemarksData(remarksData);
    setIsReadMore(isReadMore);
    setLoading(false);
  };

  const parseInfo = (responseData) => {
    const containerInfo = responseData.find(
      (info) => info.action == "get_loan_amount"
    );
    if (containerInfo !== undefined) {
      const containerId = containerInfo.uuid;
      setWorkFlowActionId(containerId);
      const containerParams = JSON.parse(containerInfo.params);
      setWorkFlowParams(containerParams);
    }
  };

  const getAllResponseData = async () => {
    const workFlowUUID = {
      uuid: DecryptData(getCookie("workflow_id")),
    };

    const { data, status, error } = await getworkFlowAllTransactions(
      workFlowUUID
    );
    if (error) {
      console.log(error);
    } else if (status == 200 && data) {
      let count = 0;
      parseInfo(data.actions);

      let availablePayload = Object.entries(data.actions[0].payload);

      availablePayload.forEach((element) => {
        if (element[0] === "available_credit_limit") {
          count = count + 1;
        }
      });

      switch (count) {
        case 1:
          setAvailableCredit(+data.actions[0].payload.available_credit_limit);
          break;
        case 0:
          setAvailableCredit(+data.actions[0].payload.credit_limit);
          break;
        default:
          break;
      }
    }
    getLoanInformation();
  };

  const getLoanInformation = async () => {
    let bodyParams = {};

    bodyParams["msisdn"] = mobileNumber;
    bodyParams["product_type"] = "Installment";
    bodyParams["requested_amount"] = 20;

    const { status, error, data } = await getLoanOffers(bodyParams);
    if (error) {
      console.log(error);
    } else if (status == 200) {
      if (data.status === "failed") {
        navigate("/");
        toast.error(data.message);
      } else if (data.status === "success" && data.code === 4002) {
        navigate("/");
        toast.warning(`${data.message}, Please try again later`);
      } else {
        if (data.data.variant_details !== undefined) {
          parseLoanDetails(data.data.variant_details);
        }
      }
    }
  };

  const parseLoanDetails = (response) => {
    let newTenure = [];
    let objectTenure = [];
    response.map((element) => {
      newTenure.push({
        duration: element.max_installment_duration,
        interestRate: parseFloat(element.interest_rate),
        variantName: element.variant_name,
      });

      objectTenure.push({
        durationName: element.variant_name,
        durationNumber: element.max_installment_duration,
        productId: element.variant_id,
        interestRate: parseFloat(element.interest_rate),
        applicationFee: element.fee_breakdown[3].fee_value,
      });
    });

    setResponseTenure(newTenure);
    setVariantTenure(objectTenure);
    fetchKycDefinition();
  };

  const calculateEMI = (newTenure) => {
    let emiCalculated = [];
    newTenure.map((element) => {
      let userAmount = Number(formValues.applied_loan_amount);
      let calculatedInterest = Number(element.interestRate) / 1200;
      let calculatedPayments = Number(element.duration);
      let x = Math.pow(1 + calculatedInterest, calculatedPayments);
      let monthly = (userAmount * x * calculatedInterest) / (x - 1);
      let finalAmount = monthly.toFixed(2);
      emiCalculated.push({
        tenure: element.duration,
        emi: +finalAmount,
        interestRate: element.interestRate,
        variantName: element.variantName,
      });
    });

    setEmi(emiCalculated);
  };

  let STEP_AMOUNT = 1;
  let MAX_AMOUNT = availableCredit;

  const nextHandler = async () => {
    let message;
    let errCount = 0;
    for (let i = 0; i < submitDefinitions.length; i++) {
      let testRegEx;
      let element = submitDefinitions[i];
      const {
        fieldName,
        fieldDisplayName,
        mandatory,
        validation,
        displayType,
      } = element;
      if (validation !== undefined) {
        testRegEx = new RegExp(validation);
      }

      if (
        (mandatory && !formValues[fieldName] && displayType !== "SLIDER") ||
        (mandatory &&
          displayType === "SLIDER" &&
          formValues[fieldName][0] === 0)
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} required!`;
        break;
      } else if (
        mandatory &&
        validation !== undefined &&
        !testRegEx.test(formValues[fieldName])
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `${fieldDisplayName} not matching!`;
        break;
      } else if (
        (updatedRemarksData[fieldName] !== undefined &&
          displayType !== "SLIDER" &&
          previousValues[fieldName] === formValues[fieldName]) ||
        (updatedRemarksData[fieldName] !== undefined &&
          displayType === "SLIDER" &&
          previousValues[fieldName][0] === formValues[fieldName][0])
      ) {
        errCount = 1;
        setShowError({ ...showError, [fieldName]: true });
        message = `Please provide another ${fieldDisplayName}`;
        break;
      }
    }
    setErrMsg(message);
    if (errCount === 0) {
      createOrUpdateKyc();
    }
  };

  const createOrUpdateKyc = async () => {
    setLoading(true);
    let bodyData = {
      kycId: ACTIVE_KYC_ID,
      created_by: DecryptData(getCookie("phone")),
      data: {},
      version: versionData,
    };

    submitDefinitions.map((field) => {
      const { fieldName, displayType } = field;
      if (displayType === "SLIDER") {
        bodyData["data"][fieldName] = formValues[fieldName][0];
      } else {
        bodyData["data"][fieldName] = formValues[fieldName];
      }
    });

    const { status, data, error } = await submitKyc(bodyData);
    if (error) {
      toast.error(error.message);
    } else if (status === 200 && data) {
      updateHandler();
    }
  };

  const updateHandler = async () => {
    let bodyValues = workFlowParams;
    submitDefinitions.map((field) => {
      const { fieldName, displayType } = field;
      if (displayType === "SLIDER") {
        bodyValues[fieldName] = formValues[fieldName][0];
      } else {
        bodyValues[fieldName] = formValues[fieldName];
      }
    });
    bodyValues["amount"] = formValues.applied_loan_amount[0];
    bodyValues["tenure"] = formValues.applicable_tenure;
    bodyValues["duration_name"] = productVariantName;
    bodyValues["variant_id"] = productId;
    bodyValues["product_type"] = "Installment";
    bodyValues["application_fee"] = applicationFee;
    bodyValues["interest_rate"] = tenureEMI.interestRate;
    bodyValues["loanPurpose"] = formValues.final_loan_purpose;
    bodyValues["emi_amount"] = tenureEMI.emi;
    bodyValues["total_interest"] = Number(
      (
        tenureEMI.emi * formValues.applicable_tenure -
        formValues.applied_loan_amount[0]
      ).toFixed(2)
    );
    bodyValues["approval_action"] = "credit_approval";
    bodyValues["approvers"] = "developer@yabx.co";
    bodyValues["available_credit"] = availableCredit;

    let urlParameters = {
      uuid: workFlowActionId,
    };

    const { status, error } = await getworkFlowInteractionOutcome(
      urlParameters,
      bodyValues
    );
    if (error) {
      toast.error(error);
    } else if (status == 200) {
      getNextActionPage().then((response) => {
        if (response.status == true) {
          setLoading(false);
          toast.success("Info updated successfully");
          navigate(response.screen);
        }
      });
    }
  };

  const handleChange = (e, MAX_VALUE) => {
    setErrMsg();
    let { name, value } = e.target;
    if (MAX_VALUE !== undefined) {
      let updatedValue = Number(value);
      if (updatedValue > MAX_VALUE) {
        updatedValue = MAX_VALUE;
      }
      setFormValues({ ...formValues, [name]: [updatedValue] });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    setShowError({
      ...showError,
      [name]: false,
    });
  };

  const toggleReadMore = (fieldName) => {
    setIsReadMore({ ...isReadMore, [fieldName]: !isReadMore[fieldName] });
  };

  const handleSliderChange = (fieldName, value) => {
    setErrMsg();
    setFormValues({ ...formValues, [fieldName]: value });
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <Stepper currentStep={7} />
        <div className="content-container">
          <div className="common-heading">
            <h1>{packageTitle}</h1>
          </div>
          <div className="content-notes">
            <img src={notes} alt="notes" />
            <label>Pre-approved limit : ₹ &ensp;{availableCredit}</label>
          </div>
          <div className="range-slider-wrapper loan-info-wrapper">
            {loanDefinitions.map((formField, index) => {
              switch (true) {
                case formField.type === "NUMBER" &&
                  formField.displayType === "SLIDER":
                  return (
                    <React.Fragment key={index}>
                      <RangeSlider
                        fieldName={formField.fieldName}
                        display={formField.fieldDisplayName}
                        mandatory={formField.mandatory}
                        formValues={formValues}
                        showError={showError}
                        handleSliderChange={handleSliderChange}
                        handleChange={handleChange}
                        MIN_VALUE={0}
                        MAX_VALUE={MAX_AMOUNT}
                        STEP_VALUE={STEP_AMOUNT}
                      />
                      {updatedRemarksData[formField.fieldName] && (
                        <p className="remarks">
                          {isReadMore[formField.fieldName]
                            ? updatedRemarksData[formField.fieldName].slice(
                                0,
                                45
                              )
                            : updatedRemarksData[formField.fieldName]}
                          {updatedRemarksData[formField.fieldName].length >
                            45 && (
                            <span
                              onClick={() =>
                                toggleReadMore(formField.fieldName)
                              }
                            >
                              {isReadMore[formField.fieldName]
                                ? "...Read more"
                                : " Show less"}
                            </span>
                          )}
                        </p>
                      )}
                    </React.Fragment>
                  );
                case formField.type === "RADIO" &&
                  formField.displayType === undefined:
                  return (
                    <React.Fragment key={index}>
                      <TenureRadio
                        fieldName={formField.fieldName}
                        display={formField.fieldDisplayName}
                        mandatory={formField.mandatory}
                        formValues={formValues}
                        showError={showError}
                        handleChange={handleChange}
                        options={emi}
                      />
                      {updatedRemarksData[formField.fieldName] && (
                        <p className="remarks">
                          {isReadMore[formField.fieldName]
                            ? updatedRemarksData[formField.fieldName].slice(
                                0,
                                45
                              )
                            : updatedRemarksData[formField.fieldName]}
                          {updatedRemarksData[formField.fieldName].length >
                            45 && (
                            <span
                              onClick={() =>
                                toggleReadMore(formField.fieldName)
                              }
                            >
                              {isReadMore[formField.fieldName]
                                ? "...Read more"
                                : " Show less"}
                            </span>
                          )}
                        </p>
                      )}
                    </React.Fragment>
                  );

                default:
                  break;
              }
            })}
          </div>

          <div className="list-section-wrapper address-edit-screen loan-section-wrapper">
            <ul>
              {infoDefinition.map((formField, index) => {
                switch (true) {
                  case formField.type === "TEXT" &&
                    formField.displayType === undefined:
                    return (
                      <li key={index}>
                        <Input
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );
                  case formField.type === "CALENDAR" &&
                    formField.displayType === undefined:
                    return (
                      <li key={index}>
                        <DateInput
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );
                  case formField.type === "RADIO" &&
                    formField.displayType === undefined:
                    return (
                      <li key={index}>
                        <Select
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                          options={formField.options}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );
                  case formField.type === "NUMBER" &&
                    formField.displayType === undefined:
                    return (
                      <li key={index}>
                        <NumberInput
                          fieldName={formField.fieldName}
                          display={formField.fieldDisplayName}
                          mandatory={formField.mandatory}
                          formValues={formValues}
                          showError={showError}
                          handleChange={handleChange}
                          options={formField.options}
                          editable={formField.editable}
                        />
                        {updatedRemarksData[formField.fieldName] && (
                          <p className="remarks">
                            {isReadMore[formField.fieldName]
                              ? updatedRemarksData[formField.fieldName].slice(
                                  0,
                                  45
                                )
                              : updatedRemarksData[formField.fieldName]}
                            {updatedRemarksData[formField.fieldName].length >
                              45 && (
                              <span
                                onClick={() =>
                                  toggleReadMore(formField.fieldName)
                                }
                              >
                                {isReadMore[formField.fieldName]
                                  ? "...Read more"
                                  : " Show less"}
                              </span>
                            )}
                          </p>
                        )}
                      </li>
                    );

                  default:
                    break;
                }
              })}
            </ul>
          </div>
          <LoanSummary
            amount={formValues.applied_loan_amount}
            duration={formValues.applicable_tenure}
            tenureEMI={tenureEMI}
            interestRate={productInterestRate}
            applicationFee={applicationFee}
          />

          {errMsg && <Error message={errMsg} />}
        </div>

        <div className="button-section justify-content-end">
          <div>
            <button
              type="button"
              onClick={nextHandler}
              className="btn btn-primary"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanInfo;
