import React, { useState } from "react";

import { toast } from "react-toastify";

import PersonalDiscussion from "./PersonalDiscussion";
import PersonalDiscussionEdit from "./PersonalDiscussionEdit";

const PersonalDiscussionInfo = () => {
  const [showDetails, setShowDetails] = useState(true);
  const [showEdit, setShowEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});

  const editHandler = () => {
    setShowEdit(true);
    setShowDetails(false);
  };

  const discardChangesHandler = () => {
    setShowDetails(true);
    setShowEdit(false);
  };

  const updateHandler = (formValues) => {
    setUpdatedValues(formValues);
    setShowDetails(true);
    setShowEdit(false);

    toast.success("Personal discussion info updated successfully");
  };

  return (
    <div>
      {showDetails && (
        <PersonalDiscussion
          editHandler={editHandler}
          updatedValues={updatedValues}
        />
      )}
      {showEdit && (
        <PersonalDiscussionEdit
          discardChangesHandler={discardChangesHandler}
          updateHandler={updateHandler}
        />
      )}
    </div>
  );
};

export default PersonalDiscussionInfo;
