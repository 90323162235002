import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import LoanHeader from "./LoanHeader";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Loader from "../../components/loader";

import information from "../../assets/images/information.png";

import { toast } from "react-toastify";

import { getCookie } from "../../utils/cookie-helper";

import { LMS_PARTNER_CODE } from "../../constants";
import {
  getLoanEligibility,
  loanInstrumentation,
  loanRepayment,
} from "../../services/lms-service";

import { DecryptData, numberWithCommas } from "../../utils/application-helper";

import { getResponseKyc } from "../../services/kyc-service";

import { ACTIVE_KYC_ID } from "../../constants";

import "./loan-servicing.css";
import moment from "moment";

const ExistingLoans = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState();
  const [credit, setCredit] = useState();
  const [showRepay, setShowRepay] = useState([]);
  const [showDetail, setShowDetail] = useState([]);
  const [amount, setAmount] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [productType, setProductType] = useState();
  const [instrumentId, setInstrumentId] = useState([]);
  const [toggleTab, setToggleTab] = useState([]);
  const [pending, setPending] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [paymentType, setPaymentType] = useState([]);
  const [name, setName] = useState();
  const [selfie, setSelfie] = useState();
  const [message, setMessage] = useState();

  const mobileNumber = DecryptData(getCookie("phone"));

  const handleRepay = (index) => {
    setShowRepay({
      ...showRepay,
      [index]: true,
    });
  };

  const closeRepay = (index) => {
    setShowRepay({
      ...showRepay,
      [index]: false,
    });
  };

  const handleDetail = (index) => {
    setShowDetail({
      ...showDetail,
      [index]: !showDetail[index],
    });
  };

  const pendingHandler = (index) => {
    setPending({
      ...pending,
      [index]: !pending[index],
    });
  };

  const completedHandler = (index) => {
    setCompleted({
      ...completed,
      [index]: !completed[index],
    });
  };

  const toggleHandler = (index, number) => {
    setToggleTab({
      ...toggleTab,
      [index]: number,
    });
  };

  useEffect(() => {
    fetchCustomerStatus();
    fetchResponseValues();
  }, []);

  const fetchResponseValues = async () => {
    const customerKycParams = {
      kycId: ACTIVE_KYC_ID,
      msisdn: mobileNumber,
    };

    const { data, status, error } = await getResponseKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data) {
      const responseData = data.data;
      if (responseData !== undefined) {
        const apiResponses = responseData.data;
        setName({
          firstName: apiResponses.first_name,
          middleName: apiResponses.middle_name,
          lastName: apiResponses.last_name,
        });
        setSelfie(apiResponses.selfie);
      }
    }
  };

  const fetchCustomerStatus = async () => {
    const customerDetails = {
      msisdn: mobileNumber,
      product_type: "Installment",
    };

    const { status, error, data } = await getLoanEligibility(customerDetails);
    if (error) {
      console.log(error);
    } else if (status === 200) {
      if (data.status === "failed") {
        navigate("/");
        toast.error("Signature expired, please try again!");
      } else if (data.code === 4001) {
        setMessage(data.message);
        setLoading(false);
      } else {
        let responseData = data.data;
        setStatus(responseData.is_eligibe);
        setCredit(responseData.available_credit_limit);
        fetchLoanInstrumentation();
      }
    }
  };

  const fetchLoanInstrumentation = async () => {
    const customerDetails = {
      msisdn: mobileNumber,
      product_type: "Installment",
      filters: {
        status: "open",
        with_payment: true,
      },
    };
    const urlParams = {
      partner_code: LMS_PARTNER_CODE,
    };
    const { status, error, data } = await loanInstrumentation(
      urlParams,
      customerDetails
    );
    if (error) {
      console.log(error);
    } else if (status === 200) {
      if (data.status === "failed") {
        navigate("/");
        toast.error("Signature expired, please try again!");
      } else {
        setProductType(data.data.product_type);
        let responseData = data.data.open_instruments;
        setInstruments(responseData);
        repayFunction(responseData);
        setLoading(false);
      }
    }
  };

  const repayFunction = (responseData) => {
    let repayArray = [];
    let amountArray = [];
    let instrumentArray = [];
    let detailArray = [];
    let toggleArray = [];
    let pendingArray = [];
    let completedArray = [];
    let paymentArray = [];
    responseData.map((item) => {
      repayArray.push(false);
      amountArray.push("");
      instrumentArray.push(item.id);
      detailArray.push(false);
      toggleArray.push(1);
      pendingArray.push(false);
      completedArray.push(false);
      paymentArray.push("");
    });
    setShowRepay(repayArray);
    setAmount(amountArray);
    setInstrumentId(instrumentArray);
    setShowDetail(detailArray);
    setToggleTab(toggleArray);
    setPaymentType(paymentArray);
  };

  const handleAmountChange = (index, amountValue) => {
    setAmount({ ...amount, [index]: +amountValue });
  };

  const handlePaymentChange = (index, payment) => {
    setPaymentType({ ...paymentType, [index]: payment });
  };

  const repayment = async (index) => {
    const bodyParams = {};
    const urlParams = {
      partner_code: LMS_PARTNER_CODE,
    };
    bodyParams["msisdn"] = mobileNumber;
    bodyParams["product_type"] = productType;
    bodyParams["amount"] = amount[index];
    bodyParams["instrument_id"] = instrumentId[index];
    const { status, error, data } = await loanRepayment(urlParams, bodyParams);
    if (error) {
      console.log(error);
    } else if (status === 200) {
      console.log(data.data);
      setAmount({ ...amount, [index]: "" });
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <Stepper currentStep={14} />
        <div className="content-container">
          <div className="common-heading">
            <h1>Loan Servicing</h1>
          </div>
          <LoanHeader
            status={status}
            credit={credit}
            name={name}
            selfie={selfie}
          />
          <div className="existing-loans-wrapper">
            {message && (
              <div>
                <h4 className="text-center">{message}</h4>
                <div className="button-section back-home justify-content-center">
                  <button className="btn btn-primary">Back home</button>
                </div>
              </div>
            )}
            {instruments.map((element, index) => {
              return (
                <div className="loan-column" key={element.id}>
                  <h3>Loan {index + 1}</h3>
                  <div className="loan-headers list-section-wrapper">
                    <ul>
                      <li>
                        <label>Product Type</label>
                        <span>{productType}</span>
                      </li>
                      <li>
                        <label>Amount</label>
                        <span>
                          ₹ &ensp; {numberWithCommas(element.loan_amount)}
                        </span>
                      </li>
                      <li>
                        <label>Date</label>
                        <span>
                          {moment(element.loan_date).format("MMM Do,  YYYY")}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="loan-body list-section-wrapper">
                    {showDetail[index] && (
                      <div>
                        {instruments && (
                          <div className="details-column">
                            <ul>
                              <li>
                                <label>ID</label>
                                <span>{instruments[index].id}</span>
                              </li>
                              <li>
                                <label>Interest Rate</label>
                                <span>
                                  {instruments[index].interest_rate * 100}%
                                </span>
                              </li>
                              <li>
                                <label>EMI amount</label>
                                <span>
                                  ₹&ensp;{" "}
                                  {numberWithCommas(
                                    instruments[index].emi_amount
                                  )}
                                </span>
                              </li>
                              <li>
                                <label className="tool">
                                  Amount borrowed{" "}
                                  <img src={information} alt="information" />
                                  <p className="tooltext">
                                    Amount borrowed, not including fees,
                                    charges, interest
                                  </p>
                                </label>
                                <span>
                                  ₹ &ensp;{" "}
                                  {numberWithCommas(
                                    instruments[index].loan_amount
                                  )}
                                </span>
                              </li>
                              <li>
                                <label>Loan disbursed on</label>
                                <span>
                                  {moment(instruments[index].loan_date).format(
                                    "MMM Do,  YYYY"
                                  )}
                                </span>
                              </li>
                              <li>
                                <label className="tool">
                                  Original loan end date{" "}
                                  <img src={information} alt="information" />
                                  <p className="tooltext">
                                    Original loan end date as per EMI schedule
                                  </p>
                                </label>
                                <span>
                                  {" "}
                                  {moment(
                                    instruments[index].loan_due_date
                                  ).format("MMM Do,  YYYY")}
                                </span>
                              </li>
                              <li>
                                <label>Loan Overdue</label>
                                <span>
                                  {" "}
                                  {instruments[index].is_loan_overdue
                                    ? "Yes"
                                    : "No"}
                                </span>
                              </li>
                              <li>
                                <label className="tool">
                                  Repayment due month{" "}
                                  <img src={information} alt="information" />
                                  <p className="tooltext">
                                    Date of month when repayment is due
                                  </p>
                                </label>
                                <span> {instruments[index].due_day}</span>
                              </li>
                              <li>
                                <label className="tool">
                                  No. of missed payments{" "}
                                  <img src={information} alt="information" />
                                  <p className="tooltext">
                                    Number of missed payments till date
                                  </p>
                                </label>
                                <span> {instruments[index].emi_due_count}</span>
                              </li>
                              <li>
                                <label className="tool">
                                  Payment made on time{" "}
                                  <img src={information} alt="information" />
                                  <p className="tooltext">
                                    Number of payments made on time till date
                                  </p>
                                </label>
                                <span>
                                  {" "}
                                  {instruments[index].emi_paid_count}
                                </span>
                              </li>
                              <li>
                                <label className="tool">
                                  Sum of previous dues{" "}
                                  <img src={information} alt="information" />
                                  <p className="tooltext">
                                    Sum of previous dues unpaid such as
                                    principal and interest arrears
                                  </p>
                                </label>
                                <span>
                                  ₹&ensp;{" "}
                                  {numberWithCommas(
                                    instruments[index].total_emi_amount_due
                                  )}
                                </span>
                              </li>
                              <li>
                                <label className="tool">
                                  Sum of principal unpaid{" "}
                                  <img src={information} alt="information" />
                                  <p className="tooltext">
                                    Sum of principal unpaid including arrears,
                                    interest and penalties from beginning of EMI
                                    schedule till date
                                  </p>
                                </label>
                                <span>
                                  ₹&ensp;{" "}
                                  {numberWithCommas(
                                    instruments[index].total_outstanding
                                  )}
                                </span>
                              </li>
                              <li>
                                <label className="tool">
                                  Total amount received{" "}
                                  <img src={information} alt="information" />
                                  <p className="tooltext">
                                    Total amount received from customer till
                                    date
                                  </p>
                                </label>
                                <span>
                                  ₹&ensp;{" "}
                                  {numberWithCommas(
                                    instruments[index].amount_paid
                                  )}
                                </span>
                              </li>
                              <li>
                                <label className="tool">
                                  Total amount raised{" "}
                                  <img src={information} alt="information" />
                                  <p className="tooltext">
                                    Total amount to be raised to customer in
                                    future from current date till end as per
                                    regular EMI schedule
                                  </p>
                                </label>
                                <span>
                                  ₹&ensp;{" "}
                                  {numberWithCommas(
                                    instruments[index].total_payable
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        )}
                        <div className="emi-timeline">
                          <div className="bloc-tabs">
                            <button
                              className={
                                toggleTab[index] === 1
                                  ? "btn active-tabs"
                                  : "btn"
                              }
                              onClick={() => toggleHandler(index, 1)}
                            >
                              Pending dues
                            </button>
                            <button
                              className={
                                toggleTab[index] === 2
                                  ? "btn active-tabs"
                                  : "btn"
                              }
                              onClick={() => toggleHandler(index, 2)}
                            >
                              Completed dues
                            </button>
                          </div>
                          <div className="content-tabs">
                            <div
                              className={
                                toggleTab[index] === 1
                                  ? "content  active-content"
                                  : "content"
                              }
                            >
                              <div
                                className={
                                  pending[index]
                                    ? "due-status-wrapper active"
                                    : "due-status-wrapper"
                                }
                              >
                                <div className="due active-due">
                                  <div className="due-content">
                                    <div className="due-header">
                                      <div className="due-wrapper">
                                        <div className="due-circle"></div>
                                      </div>
                                      <label>Upcoming Due</label>
                                      <span>05 Mar, 2022</span>
                                    </div>
                                    <p className="due-status">Yet to pay</p>
                                  </div>
                                </div>
                                <div className="due">
                                  <div className="due-content">
                                    <div className="due-header">
                                      <div className="due-wrapper">
                                        <div className="due-circle"></div>
                                      </div>
                                      <label>Next Due</label>
                                      <span>05 Apr, 2022</span>
                                    </div>
                                    <p className="due-status">Pending</p>
                                  </div>
                                </div>
                                <div className="due">
                                  <div className="due-content">
                                    <div className="due-header">
                                      <div className="due-wrapper">
                                        <div className="due-circle"></div>
                                      </div>
                                      <label>Next Due</label>
                                      <span>05 May, 2022</span>
                                    </div>
                                    <p className="due-status">Pending</p>
                                  </div>
                                </div>
                                <div className="due">
                                  <div className="due-content">
                                    <div className="due-header">
                                      <div className="due-wrapper">
                                        <div className="due-circle"></div>
                                      </div>
                                      <label>Next Due</label>
                                      <span>05 Jun, 2022</span>
                                    </div>
                                    <p className="due-status">Pending</p>
                                  </div>
                                </div>
                              </div>
                              <span
                                className="due-link"
                                onClick={() => pendingHandler(index)}
                              >
                                {pending[index] ? "View less" : "View more"}
                              </span>
                            </div>

                            <div
                              className={
                                toggleTab[index] === 2
                                  ? "content  active-content"
                                  : "content"
                              }
                            >
                              <div
                                className={
                                  completed[index]
                                    ? "due-status-wrapper active"
                                    : "due-status-wrapper"
                                }
                              >
                                <div className="due">
                                  <div className="due-content">
                                    <div className="due-header">
                                      <div className="due-wrapper">
                                        <div className="due-circle"></div>
                                      </div>
                                      <label>Completed Due</label>
                                      <span>05 Feb, 2022</span>
                                    </div>
                                    <p className="due-status">Paid</p>
                                  </div>
                                </div>
                                <div className="due">
                                  <div className="due-content">
                                    <div className="due-header">
                                      <div className="due-wrapper">
                                        <div className="due-circle"></div>
                                      </div>
                                      <label>Completed Due</label>
                                      <span>05 Jan, 2022</span>
                                    </div>
                                    <p className="due-status">Paid</p>
                                  </div>
                                </div>
                              </div>

                              <span
                                className="due-link"
                                onClick={() => completedHandler(index)}
                              >
                                {completed[index] ? "View less" : "View more"}
                              </span>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    )}
                    <div className="button-section">
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => {
                          handleDetail(index);
                        }}
                      >
                        {showDetail[index] ? "View less" : "View detail"}
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          handleRepay(index);
                        }}
                      >
                        Repay
                      </button>
                    </div>
                  </div>

                  {showRepay[index] && (
                    <div className="repay-modal">
                      <div className="backdrop">
                        <div className="preview-modal">
                          <div className="preview-body">
                            <h3>Repayment</h3>
                            <div className="repay-amount">
                              <label htmlFor={index}>
                                Enter amount to repay
                              </label>
                              <input
                                type="text"
                                id={index}
                                className="form-control"
                                onChange={(e) =>
                                  handleAmountChange(index, e.target.value)
                                }
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                value={amount[index]}
                              />
                            </div>

                            <div className="payment-method">
                              <label htmlFor="payment">
                                Choose payment method
                              </label>
                              <ul
                                className="identity_section"
                                onChange={(e) =>
                                  handlePaymentChange(index, e.target.value)
                                }
                              >
                                <li>
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id="Net banking"
                                    name="payment"
                                    value="netbanking"
                                    disabled
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="Net banking"
                                  >
                                    {" "}
                                    Net Banking{" "}
                                  </label>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id="Debit Card"
                                    name="payment"
                                    value="debitcard"
                                    disabled
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="Debit Card"
                                  >
                                    {" "}
                                    Debit Card{" "}
                                  </label>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id="Wallet"
                                    name="payment"
                                    value="wallet"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="Wallet"
                                  >
                                    {" "}
                                    Wallet{" "}
                                  </label>
                                </li>
                              </ul>
                              <div className="button-section">
                                <button
                                  className="btn btn-outline-primary"
                                  onClick={() => closeRepay(index)}
                                >
                                  Close
                                </button>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    repayment(index);
                                  }}
                                >
                                  Pay now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExistingLoans;
