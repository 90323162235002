import React from "react";
import "./loader.css";

const Loader = () => {
  return (
    <div className="loadingio-spinner-rolling-ml2oz7127h8">
      <div className="ldio-sv2tvpk7fd">
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
