import React from "react";

const CheckMark = ({
  fieldId,
  fieldName,
  display,
  mandatory,
  handleCheckMark,
  formValues,
}) => {
  return (
    <div className="check-section" key={fieldId}>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value={formValues[fieldName]}
          id={fieldName}
          onChange={() => handleCheckMark(fieldName)}
          checked={formValues[fieldName]}
        />
        <label className="form-check-label" htmlFor={fieldName}>
          {display}
          <span className="red">{mandatory ? "*" : ""}</span>
        </label>
      </div>
    </div>
  );
};

export default CheckMark;
