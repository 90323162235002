import React from "react";
import placeholder from "../../assets/images/image_placeholder.svg";
import ImageThumb from "../documentFile/imageThumb";

const AdditionalDocumentFile = ({
  fieldId,
  fieldName,
  display,
  mandatory,
  formValues,
  addShow,
  addShowView,
  showError,
  handleFileChange,
  sampleImageURL,
}) => {
  return (
    <>
      <div className="document-view-wrapper" key={fieldId}>
        <div className="document-options">
          <h3>
            {display}
            <span className="red">{mandatory ? "*" : ""}</span>
          </h3>
          {addShowView[fieldName] && (
            <div>
              <input
                type="file"
                id={fieldName}
                onChange={(e) => {
                  handleFileChange(e.target.files, fieldName);
                }}
                capture="environment"
                hidden
              />
              <label htmlFor={fieldName}>Change</label>
            </div>
          )}
        </div>

        {addShow[fieldName] && (
          <div className="image-label">
            <input
              type="file"
              id={fieldName}
              onChange={(e) => {
                handleFileChange(e.target.files, fieldName);
              }}
              capture="environment"
              hidden
            />
            <label htmlFor={fieldName}>
              <div className="before-upload-wrapper">
                <div
                  className={
                    showError[fieldName]
                      ? "before-upload-view border-red"
                      : "before-upload-view"
                  }
                >
                  <img src={placeholder} alt="placeholder" />
                  <h3>browse file</h3>
                  <p>Only .jpg and .png files. 500kb max file size.</p>
                </div>
              </div>
            </label>
          </div>
        )}

        {addShowView[fieldName] && (
          <div className="after-upload-view">
            <div className="upload-view">
              {formValues[fieldName] && (
                <ImageThumb image={formValues[fieldName]} />
              )}
            </div>
          </div>
        )}
      </div>

      <div className="document-view-wrapper">
        <div className="document-options">
          <h3>Sample Image</h3>
        </div>
        <div className="after-upload-view">
          <div className="upload-view">
            {sampleImageURL && <ImageThumb image={sampleImageURL} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdditionalDocumentFile;
