import React, { useState } from "react";
import Loader from "../../components/loader";
import Header from "../../components/header";
import dashProfile from "../../assets/images/dash-profile.png";
import "./dashboard.css";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  const handleChange = (e) => {
    setToggleState(Number(e.target.value));
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="dash-wrapper">
        <div className="row">
          <div className="col-md-4 order-md-2">
            <div className="dash-profile">
              <div className="dash-profile-header">
                <img src={dashProfile} alt="dash-profile" />
                <div>
                  <label>Welcome</label>
                  <h2>Suruchi Desai</h2>
                </div>
              </div>
              <div className="dash-profile-body">
                <div>
                  <label>Available Credit Limit</label>
                  <p>50,000</p>
                </div>
                <button className="btn btn-outline-primary">Get Fund</button>
              </div>
            </div>
            <div className="dash-profile">
              <h3>Upcoming EMI</h3>
              <div className="timeline-card">
                <div>
                  <label>Date</label>
                  <p>05/Apr/2022</p>
                </div>
                <div>
                  <label>Amount</label>
                  <p>₹&ensp;&ensp;7,917 </p>
                </div>
              </div>
              <button className="btn btn-outline-primary">Pay now</button>
            </div>
          </div>
          <div className="col-md-8 order-md-1">
            <div className="dashboard-container">
              <div className="dash-bloc-tabs d-none d-md-flex">
                <button
                  className={
                    toggleState === 1
                      ? "btn dash-tabs active-tabs"
                      : "btn dash-tabs"
                  }
                  onClick={() => toggleTab(1)}
                >
                  Summary
                </button>
                <button
                  className={
                    toggleState === 2
                      ? "btn dash-tabs active-tabs"
                      : "btn dash-tabs"
                  }
                  onClick={() => toggleTab(2)}
                >
                  Loan Account <br />
                  <span>1721********918</span>
                </button>
                <button
                  className={
                    toggleState === 3
                      ? "btn dash-tabs active-tabs"
                      : "btn dash-tabs"
                  }
                  onClick={() => toggleTab(3)}
                >
                  Loan Account <br />
                  <span>6021********110</span>
                </button>
              </div>

              <div className="d-block d-md-none">
                <select
                  className="form-select"
                  value={toggleState}
                  onChange={handleChange}
                >
                  <option value="1">Summary</option>
                  <option value="2">Loan Account 1721********918</option>
                  <option value="3">Loan Account 6021********110</option>
                </select>
              </div>

              <div className="dash-content-tabs">
                <div
                  className={
                    toggleState === 1
                      ? "dash-content  active-content"
                      : "dash-content"
                  }
                >
                  <div className="dash-loan-overview">
                    <h2>All Loan Overview</h2>
                    <div className="dash-card-row row">
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>No. of Active Loans</label>
                          <p>2</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>No. of loans closed</label>
                          <p>0</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>Total principal outstanding</label>
                          <p>₹&ensp;&ensp;1,25,000</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>Total Repayment </label>
                          <p>₹ &ensp;&ensp;1,45,000</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>Total amount paid</label>
                          <p>₹&ensp;&ensp;1,00,000</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>Completed EMIs</label>
                          <p>9/18</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dash-loan-overview dash-active-loans">
                    <h2>Explore Active Loans</h2>
                    <div className="dash-loan-card">
                      <div>
                        <label>Loan Amount</label>
                        <p>₹&ensp;&ensp;50,000</p>
                      </div>
                      <div>
                        <label>Tenure</label>
                        <p>6 Months</p>
                      </div>
                      <div>
                        <label>Total Fees</label>
                        <p>₹&ensp;&ensp;812</p>
                      </div>
                      <div>
                        <label>Repayment Amount</label>
                        <p>₹&ensp;&ensp;87,073</p>
                      </div>
                      <div>
                        <p>View Detail</p>
                      </div>
                    </div>
                    <div className="dash-loan-card">
                      <div>
                        <label>Loan Amount</label>
                        <p>₹&ensp;&ensp;50,000</p>
                      </div>
                      <div>
                        <label>Tenure</label>
                        <p>6 Months</p>
                      </div>
                      <div>
                        <label>Total Fees</label>
                        <p>₹&ensp;&ensp;812</p>
                      </div>
                      <div>
                        <label>Repayment Amount</label>
                        <p>₹&ensp;&ensp;87,073</p>
                      </div>
                      <div>
                        <p>View Detail</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    toggleState === 2
                      ? "dash-content  active-content"
                      : "dash-content"
                  }
                >
                  <div className="dash-loan-overview">
                    <h2>All Loan Overview</h2>
                    <div className="dash-card-row row">
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>No. of Active Loans</label>
                          <p>2</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>No. of loans closed</label>
                          <p>0</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>Total principal outstanding</label>
                          <p>₹&ensp;&ensp;1,25,000</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>Total Repayment </label>
                          <p>₹ &ensp;&ensp;1,45,000</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>Total amount paid</label>
                          <p>₹&ensp;&ensp;1,00,000</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>Completed EMIs</label>
                          <p>9/18</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dash-loan-overview dash-active-loans">
                    <h2>Explore Active Loans</h2>
                    <div className="dash-loan-card">
                      <div>
                        <label>Loan Amount</label>
                        <p>₹&ensp;&ensp;50,000</p>
                      </div>
                      <div>
                        <label>Tenure</label>
                        <p>6 Months</p>
                      </div>
                      <div>
                        <label>Total Fees</label>
                        <p>₹&ensp;&ensp;812</p>
                      </div>
                      <div>
                        <label>Repayment Amount</label>
                        <p>₹&ensp;&ensp;87,073</p>
                      </div>
                      <div>
                        <p>View Detail</p>
                      </div>
                    </div>
                    <div className="dash-loan-card">
                      <div>
                        <label>Loan Amount</label>
                        <p>₹&ensp;&ensp;50,000</p>
                      </div>
                      <div>
                        <label>Tenure</label>
                        <p>6 Months</p>
                      </div>
                      <div>
                        <label>Total Fees</label>
                        <p>₹&ensp;&ensp;812</p>
                      </div>
                      <div>
                        <label>Repayment Amount</label>
                        <p>₹&ensp;&ensp;87,073</p>
                      </div>
                      <div>
                        <p>View Detail</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    toggleState === 3
                      ? "dash-content  active-content"
                      : "dash-content"
                  }
                >
                  <div className="dash-loan-overview">
                    <h2>All Loan Overview</h2>
                    <div className="dash-card-row row">
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>No. of Active Loans</label>
                          <p>2</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>No. of loans closed</label>
                          <p>0</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>Total principal outstanding</label>
                          <p>₹&ensp;&ensp;1,25,000</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>Total Repayment </label>
                          <p>₹ &ensp;&ensp;1,45,000</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>Total amount paid</label>
                          <p>₹&ensp;&ensp;1,00,000</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card">
                          <label>Completed EMIs</label>
                          <p>9/18</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dash-loan-overview dash-active-loans">
                    <h2>Explore Active Loans</h2>
                    <div className="dash-loan-card">
                      <div>
                        <label>Loan Amount</label>
                        <p>₹&ensp;&ensp;50,000</p>
                      </div>
                      <div>
                        <label>Tenure</label>
                        <p>6 Months</p>
                      </div>
                      <div>
                        <label>Total Fees</label>
                        <p>₹&ensp;&ensp;812</p>
                      </div>
                      <div>
                        <label>Repayment Amount</label>
                        <p>₹&ensp;&ensp;87,073</p>
                      </div>
                      <div>
                        <p>View Detail</p>
                      </div>
                    </div>
                    <div className="dash-loan-card">
                      <div>
                        <label>Loan Amount</label>
                        <p>₹&ensp;&ensp;50,000</p>
                      </div>
                      <div>
                        <label>Tenure</label>
                        <p>6 Months</p>
                      </div>
                      <div>
                        <label>Total Fees</label>
                        <p>₹&ensp;&ensp;812</p>
                      </div>
                      <div>
                        <label>Repayment Amount</label>
                        <p>₹&ensp;&ensp;87,073</p>
                      </div>
                      <div>
                        <p>View Detail</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
