import React from "react";

const LoanSummary = ({
  loanPurpose,
  requestedLoanAmount,
  emi,
  tenureMonths,
  totalInterest,
  interestRate,
  applicationFee,
}) => {
  return (
    <div className="list-section-wrapper overall-summary-screen summary-screen">
      <div className="summary-title">
        <label>Loan Summary</label>
        <span>Upto {applicationFee}% application fee</span>
      </div>
      <ul>
        <li>
          <label>Loan Amount</label>
          <span>$ {requestedLoanAmount}</span>
        </li>
        <li>
          <label>EMI Amount</label>
          <span>$ {emi}</span>
        </li>

        <li>
          <label>Interest rate</label>
          <span>{interestRate}%</span>
        </li>
        <li>
          <label>Tenure in Months</label>
          <span>{tenureMonths}</span>
        </li>

        <li>
          <label>Total Interest</label>
          <span>$ {totalInterest}</span>
        </li>
      </ul>
      <div className="loan_reason">
        <label>Loan Purpose:</label>
        <span>{loanPurpose}</span>
      </div>
    </div>
  );
};

export default LoanSummary;
