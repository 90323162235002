import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import moment from "moment";

import Header from "../../components/header";
import Stepper from "../../components/stepper";
import Loader from "../../components/loader";

import "./previous-loan-details.css";

import edit from "../../assets/images/edit.svg";

import { getCookie } from "../../utils/cookie-helper";
import { getCustomerKyc } from "../../services/kyc-service";
import {
  getworkFlowInteractionOutcome,
  profileUpdate,
} from "../../services/workflow-service";
import { ACTIVE_KYC_ID } from "../../constants";
import {
  getNextActionPage,
  fetchAllWorkFlowActions,
  DecryptData,
} from "../../utils/application-helper";

const PreviousLoanDetails = ({ editHandler }) => {
  const navigate = useNavigate();
  const [workFlowActionId, setWorkFlowActionId] = useState("");
  const [slicedResponse, setSlicedResponse] = useState([]);
  const [packageTitle, setPackageTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitDefinitions, setSubmitDefinitions] = useState([]);
  const [customerResponse, setCustomerResponse] = useState();

  const mobileNumber = DecryptData(getCookie("phone"));

  useEffect(() => {
    fetchCustomerKyc();
    fetchAllWorkKyc();
  }, []);

  const fetchAllWorkKyc = () => {
    fetchAllWorkFlowActions("previous_loan_details").then((response) => {
      if (response.status === true) {
        setWorkFlowActionId(response.value);
      }
    });
  };

  const previousHandler = () => {
    navigate("/");
  };

  const parseData = (responseData) => {
    let responseValueCount = 0;
    let remarksValueCount = 0;
    let submitDefinitions = [];

    let customerResponse = {};

    const packageId = responseData.packagesDTOs.find(
      (packageDef) => packageDef.id === "622328a383ee70124c825fca"
    );
    let newData = packageId.children;
    newData.map((personDefinition) => {
      submitDefinitions.push({
        fieldName: personDefinition.fieldName,
      });
      customerResponse[personDefinition.fieldName] =
        personDefinition.responseValue;
    });
    setPackageTitle(packageId.packageTitle);
    newData.map((value) => {
      if (value.responseValue !== "") {
        responseValueCount = responseValueCount + 1;
      }
      if ("remarks" in value) {
        remarksValueCount = remarksValueCount + 1;
      }
    });

    if (responseValueCount === 0) {
      editHandler();
    } else if (remarksValueCount !== 0) {
      editHandler();
    } else {
      setSlicedResponse(newData);
      setSubmitDefinitions(submitDefinitions);
      setCustomerResponse(customerResponse);
      setLoading(false);
    }
  };

  const fetchCustomerKyc = async () => {
    const customerKycParams = {
      msisdn: mobileNumber,
      kycId: ACTIVE_KYC_ID,
    };

    const { data, status, error } = await getCustomerKyc(customerKycParams);
    if (error) {
      toast.error(error);
    } else if (status === 200 && data.statusCode === "404") {
      editHandler();
    } else if (status === 200 && data) {
      parseData(data.data);
    }
  };

  const nextHandler = async () => {
    setLoading(true);

    let bodyData = {
      msisdn: mobileNumber,
      profile_data: {},
    };
    submitDefinitions.map((field) => {
      const { fieldName } = field;
      bodyData["profile_data"][fieldName] =
        customerResponse[fieldName] === "true"
          ? "Yes"
          : customerResponse[fieldName] === "false"
          ? "No"
          : customerResponse[fieldName];
    });

    const { status, data, error } = await profileUpdate(bodyData);
    if (error) {
      toast.error(error.message);
    } else if (status === 200 && data) {
      updateHandler();
    }
  };

  const updateHandler = async () => {
    let bodyValues = {};
    submitDefinitions.map((field) => {
      const { fieldName } = field;
      bodyValues[fieldName] = customerResponse[fieldName];
    });

    let urlParameters = {
      uuid: workFlowActionId,
    };

    const { status, error } = await getworkFlowInteractionOutcome(
      urlParameters,
      bodyValues
    );
    if (error) {
      toast.error(error);
    } else if (status == 200) {
      getNextActionPage().then((response) => {
        if (response.status == true) {
          setLoading(false);
          toast.success("Info updated successfully");
          navigate(response.screen);
        }
      });
    }
  };

  console.log("sliced", slicedResponse);

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <div className="main-container">
        <Stepper currentStep={2} />
        <div className="content-container">
          <div className="common-heading">
            <h1>{packageTitle}</h1>
            <button
              type="button"
              onClick={editHandler}
              className="btn btn-outline-primary"
            >
              <img src={edit} alt="edit" />
              edit
            </button>
          </div>
          <div className="list-section-wrapper wrapper-section">
            <ul>
              {slicedResponse.map((field, index) => {
                if (field.fieldName !== "reason_for_default") {
                  return (
                    <li key={index}>
                      <label>{field.fieldDisplayName}</label>
                      <span>
                        {field.responseValue === "" ||
                        field.responseValue === undefined
                          ? "-"
                          : field.type === "CALENDAR"
                          ? moment.utc(field.responseValue).format("MM-DD-YYYY")
                          : field.responseValue === "true"
                          ? "Yes"
                          : field.responseValue === "false"
                          ? "No"
                          : field.responseValue}
                      </span>
                    </li>
                  );
                }
              })}
            </ul>
            {/* <ul>
                            {slicedResponse.map((field, index) => {
                                if (field.fieldName === 'reason_for_default') {
                                } else {
                                    return (
                                        <li key={index}>
                                            <label>
                                                {field.fieldDisplayName}
                                            </label>
                                            <span>
                                                {field.responseValue === '' ||
                                                field.responseValue ===
                                                    undefined
                                                    ? '-'
                                                    : field.type === 'CALENDAR'
                                                    ? moment
                                                          .utc(
                                                              field.responseValue,
                                                          )
                                                          .format('MM-DD-YYYY')
                                                    : field.responseValue ===
                                                      'true'
                                                    ? 'Yes'
                                                    : field.responseValue ===
                                                      'false'
                                                    ? 'No'
                                                    : field.responseValue}
                                            </span>
                                        </li>
                                    )
                                }
                            })}
                        </ul> */}
            {slicedResponse.map((field, index) => {
              if (field.fieldName === "reason_for_default") {
                return (
                  <ul className="reason-wrapper" key={index}>
                    <li>
                      <label>{field.fieldDisplayName}</label>
                    </li>
                    <span>
                      {field.responseValue === "" ||
                      field.responseValue === undefined
                        ? "-"
                        : field.type === "CALENDAR"
                        ? moment.utc(field.responseValue).format("MM-DD-YYYY")
                        : field.responseValue === "true"
                        ? "Yes"
                        : field.responseValue === "false"
                        ? "No"
                        : field.responseValue}
                    </span>
                  </ul>
                );
              }
            })}
          </div>
        </div>

        <div className="button-section">
          <div>
            <button
              type="button"
              onClick={previousHandler}
              className="btn btn-outline-primary"
            >
              Previous
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={nextHandler}
              className="btn btn-primary"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviousLoanDetails;
